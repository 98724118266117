import {
  Input,
  List,
  ListItemSuffix,
  Radio,
  Textarea,
} from "@material-tailwind/react";
import Headings from "../../components/Headings";
import HeroBanner from "../../components/HeroBanner";
import ImageContentBlock from "../../components/ImageContentBlock";

import Img from "../../components/Image";
import Btn from "../../components/Btn";
import { useState } from "react";

const JoinUs = () => {
  const [selected, setSelected] = useState(1);
  const setSelectedItem = (value) => setSelected(value);
  return (
    <div>
      <HeroBanner
        heroType="card"
        heroTitle={"Join us"}
        heroDesc={
          "We believe that great work comes from having the freedom to explore your ideas and unleash your potential - that’s what we’re all about. Helping you be the best you."
        }
        buttonText={"Apply Here"}
        buttonLinkUrl={"/apply-here"}
        heroImage={"joinus-banner.jpg"}
        heroImageAlt={"Join us"}
        heroImageBGColor={"bg-[#F09FFF]"}
      >
        <div className="text-center">
          <Headings
            headType="h2"
            titleText="Struggling to find a company that gets you?"
            className={"xl:text-6xl text-5xl font-medium mb-14 xl:mb-16"}
          />

          <div className="xl:grid-cols-2 gap-y-8 gap-x-28 grid grid-cols-1 text-left">
            <div>
              <Headings
                headType="h3"
                titleText="Un-tap your potential"
                className={"xl:text-3xl  text-2xl mb-0"}
              />
              <p>
                We'll constantly motivate and support you to learn, grow and
                build your legacy.
              </p>
            </div>
            <div>
              <Headings
                headType="h3"
                titleText="Make an Impact"
                className={"xl:text-3xl  text-2xl mb-0"}
              />
              <p>
                You’ll get to work on big brand company projects that you’ll
                want to brag about.
              </p>
            </div>
            <div>
              <Headings
                headType="h3"
                titleText="Interesting projects"
                className={"xl:text-3xl  text-2xl mb-0"}
              />
              <p>
                We’ll task you with new projects in your field of interest to
                keep things fresh. No more doing the same thing for the same
                client over and over.
              </p>
            </div>
            <div>
              <Headings
                headType="h3"
                titleText="Career growth"
                className={"xl:text-3xl  text-2xl mb-0"}
              />
              <p>
                Each stage of your professional growth is rewarded with a
                corresponding pay rise. Nice.
              </p>
            </div>
          </div>
        </div>
      </HeroBanner>
      <ImageContentBlock blockImage="we-are.jpg" imgLeft={true}>
        <div className="xl:px-32 px-8">
          <Headings
            headType={"h2"}
            titleText={
              "We’re looking for talented developers who eat, sleep & speak code. If you love what you do and love making clients happy, we want to hear from you."
            }
            className={"xl:text-3xl  text-2xl mb-5"}
          />

          <div className="space-y-5">
            <div>
              <Headings
                headType={"h3"}
                titleText={"Passionate"}
                className={"text-xl mb-5 font-bold"}
              />
              <p>
                Are you enthusiastic, driven, motivated, and energetic? Do you
                inspire people around you?
              </p>
            </div>
            <div>
              <Headings
                headType={"h3"}
                titleText={"Innovative"}
                className={"text-xl mb-5 font-bold"}
              />
              <p>
                Are you always coming up with ideas and constantly finding
                better ways to do things? Are you goal-focused and strategic,
                seeing opportunities where others just see can’t.
              </p>
            </div>
            <div>
              <Headings
                headType={"h3"}
                titleText={"Bold"}
                className={"text-xl mb-5 font-bold"}
              />
              <p>
                Were you born with an entrepreneurial spirit and can-do
                attitude? Do you lead by example, dare to push the boundaries
                and live to learn to change the game? Were you often sent to the
                principal's office because you kept debating with teachers?
              </p>
            </div>
            <div>
              <Headings
                headType={"h3"}
                titleText={"Professional"}
                className={"text-xl mb-5 font-bold"}
              />
              <p>
                Have you been described as trustworthy, courteous, open,
                authentic, and respectful? Are you all about ethics, taking the
                high road and treating others as you’d like to be treated? Do
                you own a collared shirt?
              </p>
            </div>
          </div>
        </div>
      </ImageContentBlock>
      <section className="page-section">
        <div className="container">
          <div className="xl:w-10/12 mx-auto mb-20 text-center">
            <Headings
              headType="h2"
              titleText="Our opportunities"
              className={"xl:text-6xl text-5xl font-medium mb-7"}
            />
            <div className={"xl:text-3xl  text-2xl"}>
              We’re always looking for great talent to join the team. Take a
              look at the opportunities, apply and we will be in touch.
            </div>
          </div>
          <div>
            <List>
              <a
                href="https://maven.simplify.hr/vacancy/sf5ecz"
                className="flex items-center xl:text-3xl text-2xl text-[#0D0C22] aria-selected:bg-light aria-selected:font-bold aria-selected:shadow-md hover:bg-light hover:font-bold hover:shadow-md focus:bg-light focus:font-bold focus:shadow-md active:bg-light active:font-bold active:shadow-md xl:p-10 p-5 rounded-3xl"
                aria-selected={selected === 1}
                onMouseEnter={() => setSelectedItem(1)}
              >
                DevOps Engineer - AWS
                <ListItemSuffix className="text-base">
                  Western Cape, Gauteng, Remote
                </ListItemSuffix>
              </a>
              <a
                href="https://maven.simplify.hr/vacancy/sf5ecz"
                className="flex items-center xl:text-3xl text-2xl text-[#0D0C22] aria-selected:bg-light aria-selected:font-bold aria-selected:shadow-md hover:bg-light hover:font-bold hover:shadow-md focus:bg-light focus:font-bold focus:shadow-md active:bg-light active:font-bold active:shadow-md xl:p-10 p-5 rounded-3xl"
                aria-selected={selected === 2}
                onMouseEnter={() => setSelectedItem(2)}
              >
                Full Stack developer - Net
                <ListItemSuffix className="text-base">
                  Western Cape, Gauteng, Remote
                </ListItemSuffix>
              </a>
              <a
                href="https://maven.simplify.hr/vacancy/sf5ecz"
                className="flex items-center xl:text-3xl text-2xl text-[#0D0C22] aria-selected:bg-light aria-selected:font-bold aria-selected:shadow-md hover:bg-light hover:font-bold hover:shadow-md focus:bg-light focus:font-bold focus:shadow-md active:bg-light active:font-bold active:shadow-md xl:p-10 p-5 rounded-3xl"
                aria-selected={selected === 3}
                onMouseEnter={() => setSelectedItem(3)}
              >
                Full Stack developer - Python
                <ListItemSuffix className="text-base">
                  Western Cape, Gauteng, Remote
                </ListItemSuffix>
              </a>
              <a
                href="https://maven.simplify.hr/vacancy/sf5ecz"
                className="flex items-center xl:text-3xl text-2xl text-[#0D0C22] aria-selected:bg-light aria-selected:font-bold aria-selected:shadow-md hover:bg-light hover:font-bold hover:shadow-md focus:bg-light focus:font-bold focus:shadow-md active:bg-light active:font-bold active:shadow-md xl:p-10 p-5 rounded-3xl"
                aria-selected={selected === 4}
                onMouseEnter={() => setSelectedItem(4)}
              >
                DevOps Engineer - AWS
                <ListItemSuffix className="text-base">
                  Western Cape, Gauteng, Remote
                </ListItemSuffix>
              </a>
              <a
                href="https://maven.simplify.hr/vacancy/sf5ecz"
                className="flex items-center xl:text-3xl text-2xl text-[#0D0C22] aria-selected:bg-light aria-selected:font-bold aria-selected:shadow-md hover:bg-light hover:font-bold hover:shadow-md focus:bg-light focus:font-bold focus:shadow-md active:bg-light active:font-bold active:shadow-md xl:p-10 p-5 rounded-3xl"
                aria-selected={selected === 5}
                onMouseEnter={() => setSelectedItem(5)}
              >
                Full Stack developer - ReactJS
                <ListItemSuffix className="text-base">
                  Western Cape, Gauteng, Remote
                </ListItemSuffix>
              </a>
              <a
                href="https://maven.simplify.hr/vacancy/sf5ecz"
                className="flex items-center xl:text-3xl text-2xl text-[#0D0C22] aria-selected:bg-light aria-selected:font-bold aria-selected:shadow-md hover:bg-light hover:font-bold hover:shadow-md focus:bg-light focus:font-bold focus:shadow-md active:bg-light active:font-bold active:shadow-md xl:p-10 p-5 rounded-3xl"
                aria-selected={selected === 6}
                onMouseEnter={() => setSelectedItem(6)}
              >
                Full Stack developer - NodeJS
                <ListItemSuffix className="text-base">
                  Western Cape, Gauteng, Remote
                </ListItemSuffix>
              </a>
            </List>
          </div>
        </div>
      </section>
      <section className="xl:grid-cols-4 grid grid-cols-2">
        <Img imgSrc="banner1.jpg" imgAlt="join us" />
        <Img imgSrc="banner2.jpg" imgAlt="join us" />
        <Img imgSrc="banner3.jpg" imgAlt="join us" />
        <Img imgSrc="banner4.jpg" imgAlt="join us" />
      </section>
      <section className="page-section">
        <div className="container">
          <div className="xl:w-8/12">
            <Headings
              headType={"h2"}
              titleText={"Send us your CV"}
              className={"xl:text-6xl text-5xl font-medium mb-5"}
            />
            <p className="xl:w-11/12">
              Fill out the form and upload your CV below.
            </p>
          </div>
          <div className="mt-7 xl:gap-28 xl:flex-nowrap flex flex-wrap gap-8">
            <div className="xl:order-2 xl:w-5/12 xl:text-right xl:pl-8 w-full text-center">
              <Img imgSrc="how-it-works.png" imgAlt="send cv" />
            </div>
            <div className="xl:w-7/12 mt-7 w-full">
              <form>
                <div className="grid grid-cols-2 gap-8">
                  <div className="col-span-2">
                    <Input
                      variant="standard"
                      label="First name"
                      defaultValue={"Ezekiel"}
                      crossOrigin={undefined}
                      required
                    />
                  </div>
                  <div className="col-span-2">
                    <Input
                      variant="standard"
                      label="Last name"
                      defaultValue={""}
                      crossOrigin={undefined}
                      required
                    />
                  </div>
                  <div className="col-span-1">
                    <Input
                      variant="standard"
                      label="Mail"
                      defaultValue={"name@emailaddress.com"}
                      crossOrigin={undefined}
                      required
                    />
                  </div>
                  <div className="col-span-1">
                    <Input
                      variant="standard"
                      label="Phone"
                      defaultValue={"+ 27 89 012 3456"}
                      crossOrigin={undefined}
                      required
                    />
                  </div>
                  <div className="col-span-2">
                    <Headings
                      headType="h4"
                      titleText="I am interested in a …"
                      className="text-lg font-medium"
                    />
                  </div>
                  <div className="col-span-2">
                    <div className="flex flex-wrap gap-6">
                      <Radio
                        name="type"
                        label="Front End Developer"
                        crossOrigin={undefined}
                      />
                      <Radio
                        name="type"
                        label="Back End Developer"
                        defaultChecked
                        crossOrigin={undefined}
                      />
                      <Radio
                        name="type"
                        label="Ui/Ux Designer"
                        crossOrigin={undefined}
                      />
                      <Radio
                        name="type"
                        label="Other"
                        crossOrigin={undefined}
                      />
                    </div>
                  </div>
                  <div className="col-span-2">
                    <Input
                      type="file"
                      variant="static"
                      label="Upload your CV"
                      className=" file:px-2 file:rounded-full file:border-0 file:text-sm file:bg-black file:text-white hover:file:bg-gray-900"
                      crossOrigin={undefined}
                      required
                    />
                    <div className="flex items-center gap-1 mt-2 text-sm font-normal text-gray-600">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                        className="w-4 h-4 -mt-px"
                      >
                        <path
                          fillRule="evenodd"
                          d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm8.706-1.442c1.146-.573 2.437.463 2.126 1.706l-.709 2.836.042-.02a.75.75 0 01.67 1.34l-.04.022c-1.147.573-2.438-.463-2.127-1.706l.71-2.836-.042.02a.75.75 0 11-.671-1.34l.041-.022zM12 9a.75.75 0 100-1.5.75.75 0 000 1.5z"
                          clipRule="evenodd"
                        />
                      </svg>
                      Upload Word doc or PDF
                    </div>
                  </div>
                  <div className="col-span-2">
                    <Textarea
                      variant="static"
                      label="Message"
                      placeholder="Write your message"
                    />
                  </div>
                  <div className="col-span-2 ml-auto">
                    <Btn
                      type="submit"
                      className="w-fit py-5 min-w-[144px] mt-0"
                    >
                      Contact us
                    </Btn>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default JoinUs;
