import { Spinner } from "@material-tailwind/react";
import Headings from "../../components/Headings";

import { handler } from "../../services";

import CONSTANT from "../../services/constant";
import { useEffect, useState } from "react";
import Img from "../../components/Image";

const CaseStudies = () => {
  const [caseStudyData, setCaseStudyData] = useState(null);
  useEffect(() => {
    async function getCaseStudy() {
      const url = CONSTANT.BASE_URL + CONSTANT.GET_CASE_STUDY;
      const result = await handler.GetNew(url);
      let data = null;
      let error = "";
      if (result.message && (result.message.indexOf("403") || result.message.indexOf("401"))) {
        error = result.message;
        console.log(error);
      } else {
        data = JSON.parse(JSON.stringify(result.data.data));
        setCaseStudyData(data);
      }
    }
    getCaseStudy();
  }, []);

  //console.log(caseStudyData);
  return (
    <div className="page-section">
      <div className="container">
        <div className="mb-16 text-center">
          <Headings
            headType={"h1"}
            titleText={"Case Studies"}
            className={"xl:text-6xl text-4xl  mb-3"}
          />
          <div className="mb-14">We are making magic happen on SA shores and beyond.</div>
          <div className="space-y-14 xl:w-10/12 flex flex-col mx-auto">
            {caseStudyData ? (
              <>
                {caseStudyData.map((ele, i) => {
                  return (
                    <>
                      {ele.Microsite_Visible == true && (
                        <a
                          key={i}
                          href={"/case-studies/" + ele.slug}
                          className="group rounded-xl relative overflow-hidden"
                        >
                          <Img
                            imgSrc={CONSTANT.IMAGE_BASE_PATH + ele.Display_Image}
                            imgAlt={ele.Case_Study_Of}
                            cssClass="group-hover:scale-105 transition-all w-full"
                          />
                          <div className="bg-gradient-to-b from-white/0 to-black/50 absolute top-0 left-0 w-full h-full"></div>
                          <Headings
                            headType="h3"
                            titleText={ele.Case_Study_Of}
                            className="bottom-8 left-8 absolute text-5xl font-medium text-left text-white"
                          />
                        </a>
                      )}
                    </>
                  );
                })}
              </>
            ) : (
              <div className="text-center text-indigo-700">
                <Spinner className=" text-indigo-700/20 w-12 h-12 mx-auto mb-4" color="indigo" />
                <p className="animate-pulse text-xs">Loading more Case Studies</p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CaseStudies;
