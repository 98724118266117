import cx from "classnames";

import Headings from "../Headings";
import styles from "./heroBanner.module.css";

import Img from "../Image";

import { ReactNode } from "react";
import Btn from "../Btn";
interface HeroBannerHalfWithCardProps {
  cssClass?: string;
  heroTitle?: string;
  heroDesc?: string;
  buttonText?: string;
  buttonLinkUrl?: string;
  heroImage?: string;
  heroImageAlt?: string;
  heroImageBGColor?: string;
  children?: ReactNode;
}

const HeroBannerHalfWithCard = ({
  cssClass,
  heroTitle,
  heroDesc,
  buttonText,
  buttonLinkUrl,
  heroImage,
  heroImageAlt,
  heroImageBGColor,
  children,
}: HeroBannerHalfWithCardProps) => {
  return (
    <div className="bg-light relative pb-16">
      <div
        className={cx(
          " xl:pb-40 py-14 pt-0 xl:pt-40 flex xl:flex-row flex-col",
          styles.heroBannerAltContainer,
          cssClass && cssClass
        )}
      >
        <div
          className={cx(
            "xl:absolute xl:top-0 xl:right-0 xl:w-[50vw] h-full ",
            heroImageBGColor && heroImageBGColor
          )}
        >
          {heroImage && <Img imgSrc={heroImage} imgAlt={heroImageAlt} cssClass=" " />}
        </div>
        <div className="container h-full mx-auto">
          <div className="xl:w-1/2 xl:pr-32 xl:pt-0 pt-14 xl:h-full flex items-center">
            <div>
              {heroTitle && (
                <Headings
                  headType={"h1"}
                  titleText={heroTitle}
                  className={"xl:text-6xl text-5xl font-medium mb-10"}
                />
              )}
              {heroDesc && <div>{heroDesc}</div>}
              {buttonText && (
                <a href={buttonLinkUrl}>
                  <Btn>{buttonText}</Btn>
                </a>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="container relative">
        <div className="xl:w-10/12 xl:mb-32 xl:py-28 xl:px-32 px-14 py-12 mx-auto bg-white">
          {children}
        </div>
      </div>
    </div>
  );
};

export default HeroBannerHalfWithCard;
