import {
  ChevronLeftIcon,
  PlusIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { IconButton, Input, Radio } from "@material-tailwind/react";
import { useNavigate } from "react-router-dom";
import Btn from "../../components/Btn";
import { Fragment, useState, useRef } from "react";
import Img from "../../components/Image";
import Headings from "../../components/Headings";

import skillOption from "../../services/skillOption";
import ReactSelect from "react-select";
import FormWizard from "react-form-wizard-component";

const SkillsSelector = () => {
  const popularSkills = [
    { id: 73, isDisable: false },
    { id: 45, isDisable: false },
    { id: 95, isDisable: false },
    { id: 51, isDisable: false },
    { id: 257, isDisable: false },
    { id: 8, isDisable: false },
    { id: 258, isDisable: false },
    { id: 264, isDisable: false },
    { id: 46, isDisable: false },
  ];
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [popSkills, setPopSkills] = useState(popularSkills);
  const [options, setOptions] = useState([]);
  const serchRef = useRef();

  const handleChange = (data) => {
    setSelectedOptions(data);
    popSkills.map((skill) => {
      if (data.some((obj) => skill.id == obj.id)) {
        skill.isDisable = true;
      } else {
        skill.isDisable = false;
      }
    });
  };

  const handlePopularSkills = (data) => {
    setOptions([skillOption[data], ...options]);
    setSelectedOptions([skillOption[data], ...selectedOptions]);
    skillOption.map((obj, index) => {
      if (index == data) {
        setPopSkills((prevState) => {
          const newState = prevState.map((skills) => {
            if (skills.id === obj.id) {
              return { ...skills, isDisable: true };
            }

            return skills;
          });
          return newState;
        });
      }
    });
  };

  return (
    <div>
      <ReactSelect
        onChange={handleChange}
        options={skillOption}
        isSearchable
        isMulti
        ref={serchRef}
        value={selectedOptions}
        placeholder="Type your desired expertise required here eg. AngularJs, ReactJs, Java, PHP, NodeJs, MongoDB etc"
      />

      <Headings
        headType="h3"
        titleText="Popular skills"
        className="xl:text-lg mt-10 mb-5 font-semibold"
      />

      <div className="flex flex-row flex-wrap gap-4 mb-10">
        {skillOption.map((ele, i) => {
          return (
            <Fragment key={i}>
              {popSkills.map((element, indx) => {
                {
                  if (ele.id == element.id) {
                    return (
                      <Btn
                        variant="outlined"
                        className="text-xs"
                        size="sm"
                        onClick={() => handlePopularSkills(i)}
                        key={indx}
                        disabled={element.isDisable}
                      >
                        <PlusIcon className="text-dark w-4 h-4" />
                        {ele.value}
                      </Btn>
                    );
                  }
                }
              })}
            </Fragment>
          );
        })}
      </div>
      {/* <div>
        Slected list:{" "}
        {selectedOptions &&
          selectedOptions.map((ele) => {
            return <span key={ele.id}>{ele.label} , </span>;
          })}
      </div> */}
    </div>
  );
};

const HireDevelopers = () => {
  const navigate = useNavigate();
  const [fName, setFName] = useState("");
  const [lName, setLName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [isSubmit, setIsSubmit] = useState(false);

  const handleComplete = () => {
    console.log("Form completed!");
    // Handle form completion logic here
  };
  // check validate tab

  const backTemplate = (handlePrevious: () => void) => {
    return (
      <Btn onClick={handlePrevious} variant="text" className="py-6 mt-0">
        <ChevronLeftIcon className="w-6 h-6" />
        Back
      </Btn>
    );
  };

  const nextTemplate = (handleNext: () => void) => {
    return (
      <Btn type="submit" onClick={handleNext} className="mt-0 ml-auto">
        Next
      </Btn>
    );
  };

  const finishTemplate = (handleComplete: () => void) => {
    const handleSubmit = () => {
      if (fName === "" || lName === "" || email === "" || phone === "") {
        setIsSubmit(false);
      }
      setIsSubmit(true);
      if (isSubmit == true) {
        handleComplete;
        navigate("/thank-you", { replace: true });
      }
    };
    return (
      <Btn type="submit" onClick={handleSubmit} className="mt-0 ml-auto">
        Submit
      </Btn>
    );
  };

  return (
    <div className="flex min-h-screen transition-all">
      <div className="lg:w-8/12 bg-light h-100 xl:p-32 flex w-full p-8">
        <div className="w-full my-auto">
          <a href="/">
            <Img
              cssClass="h-[88px] w-[88px] mb-14  rounded-full"
              imgSrc="logo.svg"
              imgAlt="+OneX"
            />
          </a>
          <FormWizard
            stepSize="xs"
            onComplete={handleComplete}
            backButtonTemplate={backTemplate}
            nextButtonTemplate={nextTemplate}
            finishButtonTemplate={finishTemplate}
            color="black"
          >
            <FormWizard.TabContent>
              <Headings
                headType="h1"
                titleText="What type of project are you hiring for?"
                className="xl:text-4xl mb-14 text-2xl font-medium"
              />
              <div className="flex flex-col">
                <Radio
                  name="type"
                  label="New idea or project"
                  defaultChecked
                  crossOrigin={undefined}
                />
                <Radio
                  name="type"
                  label="Existing project that needs more resources"
                  crossOrigin={undefined}
                />
                <Radio
                  name="type"
                  label="Ongoing assistance or consultation"
                  crossOrigin={undefined}
                />
              </div>
            </FormWizard.TabContent>
            <FormWizard.TabContent>
              <Headings
                headType="h2"
                titleText="How long do you need the developer?"
                className="xl:text-4xl mb-14 text-2xl font-medium"
              />
              <div className="flex flex-col">
                <Radio
                  name="duration"
                  label="Less than 1 week"
                  defaultChecked
                  crossOrigin={undefined}
                />
                <Radio
                  name="duration"
                  label="1 to 4 weeks"
                  crossOrigin={undefined}
                />
                <Radio
                  name="duration"
                  label="1 to 3 months"
                  crossOrigin={undefined}
                />
                <Radio
                  name="duration"
                  label="3 to 6 months"
                  crossOrigin={undefined}
                />
                <Radio
                  name="duration"
                  label="Longer than 6 months"
                  crossOrigin={undefined}
                />
              </div>
            </FormWizard.TabContent>
            <FormWizard.TabContent>
              <Headings
                headType="h2"
                titleText="How many developers do you need?"
                className="xl:text-4xl mb-14 text-2xl font-medium"
              />
              <div className="flex flex-col">
                <Radio
                  name="many"
                  label="One developer"
                  defaultChecked
                  crossOrigin={undefined}
                />
                <Radio
                  name="many"
                  label="Multiple developers"
                  crossOrigin={undefined}
                />
                <Radio
                  name="many"
                  label="A cross-functional team (developers, designers, project managers)"
                  crossOrigin={undefined}
                />
              </div>
            </FormWizard.TabContent>
            <FormWizard.TabContent>
              <Headings
                headType="h2"
                titleText="What skills would you like to see in your new hire?"
                className="xl:text-4xl mb-14 text-2xl font-medium"
              />
              <div className="flex flex-col">
                <SkillsSelector />
              </div>
            </FormWizard.TabContent>
            <FormWizard.TabContent>
              <Headings
                headType="h2"
                titleText="Your Details"
                className="xl:text-4xl mb-14 text-2xl font-medium"
              />
              <div className="flex flex-col gap-8 px-1">
                <div>
                  <Input
                    variant="standard"
                    label="First name"
                    type="text"
                    crossOrigin={undefined}
                    required
                    defaultValue={" "}
                    value={fName}
                    onChange={(e) => setFName(e.target.value)}
                    error={!fName && isSubmit}
                  />
                </div>
                <div>
                  <Input
                    variant="standard"
                    label="Last name"
                    type="text"
                    crossOrigin={undefined}
                    required
                    value={lName}
                    onChange={(e) => setLName(e.target.value)}
                    error={!lName && isSubmit}
                  />
                </div>
                <div>
                  <Input
                    variant="standard"
                    label="Email"
                    type="email"
                    crossOrigin={undefined}
                    required
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    error={!email && isSubmit}
                  />
                </div>
                <div>
                  <Input
                    variant="standard"
                    label="Phone"
                    type="phone"
                    crossOrigin={undefined}
                    required
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                    error={!phone && isSubmit}
                  />
                </div>
              </div>
            </FormWizard.TabContent>
          </FormWizard>
        </div>
      </div>
      <div className="lg:w-4/12 bg-primary h-100 flex flex-col w-full p-4 text-white">
        <div className="flex justify-end w-full">
          <IconButton className="rounded-full" onClick={() => navigate(-1)}>
            <XMarkIcon className="w-6 h-6" />
          </IconButton>
        </div>
        <div className="flex flex-col items-center my-auto">
          <Headings
            headType="h2"
            titleText={"Trusted by over 300 clients"}
            className="xl:text-2xl xl:mb-20 mb-14 text-xl"
          />
          <div className="grid items-center justify-between w-full grid-cols-2">
            <Img
              imgSrc="logo-ey.png"
              imgAlt="EY Logo"
              cssClass="w-[230px] h-[110px] invert mx-auto"
            />
            <Img
              imgSrc="logo-flash.png"
              imgAlt="Flash Logo"
              cssClass="w-[230px] h-[110px] invert mx-auto"
            />
            <Img
              imgSrc="logo-kia.png"
              imgAlt="Kia Logo"
              cssClass="w-[230px] h-[110px] invert mx-auto"
            />
            <Img
              imgSrc="logo-absa.png"
              imgAlt="absa Logo"
              cssClass="w-[230px] h-[110px] invert mx-auto"
            />
            <Img
              imgSrc="logo-motorhappy.png"
              imgAlt="MotorHappy Logo"
              cssClass="w-[230px] h-[110px] invert mx-auto"
            />
            <Img
              imgSrc="logo-pep.png"
              imgAlt="PEP Logo"
              cssClass="w-[230px] h-[110px] invert mx-auto"
            />
            <Img
              imgSrc="logo-nedbank.png"
              imgAlt="NedBank Logo"
              cssClass="w-[230px] h-[110px] invert mx-auto"
            />
            <Img
              imgSrc="logo-mtn.png"
              imgAlt="MTN Logo"
              cssClass="w-[230px] h-[110px] invert mx-auto"
            />
            <Img
              imgSrc="logo-fnb.png"
              imgAlt="FNB Logo"
              cssClass="w-[230px] h-[110px] invert mx-auto"
            />
            <Img
              imgSrc="logo-coricraft.png"
              imgAlt="Coricraft Logo"
              cssClass="w-[230px] h-[110px] invert mx-auto"
            />
            <Img
              imgSrc="logo-ackermans.png"
              imgAlt="Ackermans Logo"
              cssClass="w-[230px] h-[110px] invert mx-auto"
            />
            <Img
              imgSrc="logo-investec.png"
              imgAlt="Investec Logo"
              cssClass="w-[230px] h-[110px] invert mx-auto"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HireDevelopers;
