import Btn from "../../components/Btn";
import { Input, Radio, Textarea } from "@material-tailwind/react";
import Headings from "../../components/Headings";
import Img from "../../components/Image";
import { EnvelopeIcon, MapPinIcon, PhoneIcon } from "@heroicons/react/24/outline";

const ContactModal = () => {
  return (
    <div className="xl:flex-row flex flex-col">
      <div className="xl:w-5/12 bg-dark flex flex-col p-8 text-white rounded-tl-lg rounded-bl-lg">
        <div className="px-7 py-6">
          <Headings headType="h2" titleText="Contact Information" className="mb-2 text-3xl" />
          <p className="text-lg">Fill out the form and our team will get back to you shortly.</p>
          <div className="grid my-16 space-y-16">
            <div className="flex items-center gap-4 text-lg font-medium">
              <PhoneIcon className="flex-none w-5 h-5" />
              <a href="tel:+ (021) 461 68 17">+ (021) 461 68 17</a>
            </div>
            <div className="flex items-center gap-4 text-lg font-medium">
              <EnvelopeIcon className="flex-none w-5 h-5" />
              <a href="mailto:hello@plusonex.com">hello@plusonex.com</a>
            </div>
            <div className="flex gap-4 text-lg font-medium">
              <MapPinIcon className="flex-none w-5 h-5" />
              <address>
                1st Floor, Landsdowne Building, Office F01A, Brookside Office Park, 11 Imam Haron
                Rd, Claremont, Cape Town, 7708
              </address>
            </div>
          </div>
        </div>
        <Img cssClass="h-[88px] w-[88px] mt-auto rounded-full" imgSrc="logo.svg" imgAlt="+OneX" />
      </div>
      <div className="xl:w-7/12 p-11 bg-white rounded-tr-lg rounded-br-lg">
        <form>
          <div className="grid grid-cols-2 gap-8">
            <div className="col-span-2">
              <Input
                variant="standard"
                label="First name"
                defaultValue={"Ezekiel"}
                crossOrigin={undefined}
                required
              />
            </div>
            <div className="col-span-2">
              <Input
                variant="standard"
                label="Last name"
                defaultValue={""}
                crossOrigin={undefined}
                required
              />
            </div>
            <div className="col-span-1">
              <Input
                variant="standard"
                label="Mail"
                defaultValue={"name@emailaddress.com"}
                crossOrigin={undefined}
                required
              />
            </div>
            <div className="col-span-1">
              <Input
                variant="standard"
                label="Phone"
                defaultValue={"+ 27 89 012 3456"}
                crossOrigin={undefined}
                required
              />
            </div>
            <div className="col-span-2">
              <Headings
                headType="h4"
                titleText="I am interested in a …"
                className="text-lg font-medium"
              />
            </div>
            <div className="col-span-2">
              <div className="flex flex-wrap gap-6">
                <Radio name="type" label="Front End Developer" crossOrigin={undefined} />
                <Radio
                  name="type"
                  label="Back End Developer"
                  defaultChecked
                  crossOrigin={undefined}
                />
                <Radio name="type" label="Ui/Ux Designer" crossOrigin={undefined} />
                <Radio name="type" label="Other" crossOrigin={undefined} />
              </div>
            </div>
            <div className="col-span-2">
              <Input
                type="file"
                variant="static"
                label="Upload your CV"
                className=" file:px-2 file:rounded-full file:border-0 file:text-sm file:bg-primary file:text-white hover:file:bg-gray-900"
                crossOrigin={undefined}
                required
              />
              <div className="flex items-center gap-1 mt-2 text-sm font-normal text-gray-600">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  className="w-4 h-4 -mt-px"
                >
                  <path
                    fillRule="evenodd"
                    d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm8.706-1.442c1.146-.573 2.437.463 2.126 1.706l-.709 2.836.042-.02a.75.75 0 01.67 1.34l-.04.022c-1.147.573-2.438-.463-2.127-1.706l.71-2.836-.042.02a.75.75 0 11-.671-1.34l.041-.022zM12 9a.75.75 0 100-1.5.75.75 0 000 1.5z"
                    clipRule="evenodd"
                  />
                </svg>
                Upload Word doc or PDF
              </div>
            </div>
            <div className="col-span-2">
              <Textarea variant="static" label="Message" placeholder="Write your message" />
            </div>
            <div className="col-span-2 ml-auto">
              <Btn type="submit" className="py-5 min-w-[144px]">
                Contact us
              </Btn>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ContactModal;
