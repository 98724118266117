import {
  ChevronLeftIcon,
  PlusIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import {
  IconButton,
  Input,
  Option,
  Rating,
  Select,
  Textarea,
  Typography,
} from "@material-tailwind/react";

import { useNavigate } from "react-router-dom";

import Btn from "../../components/Btn";
import { cloneElement, useState } from "react";
import Img from "../../components/Image";
import Headings from "../../components/Headings";

import countryList from "../../services/countryList";
import { PencilIcon, TrashIcon } from "@heroicons/react/20/solid";

import FormWizard from "react-form-wizard-component";
import "react-form-wizard-component/dist/style.css";

const ApplyHere = () => {
  const navigate = useNavigate();
  const [cvUpload, setCvUpload] = useState("");
  const [fName, setFName] = useState("");
  const [lName, setLName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [currentCity, setCurrentCity] = useState("");
  const [currentTitle, setCurrentTitle] = useState("");
  const [totalExp, setTotalExp] = useState("");
  const [isSubmit, setIsSubmit] = useState(false);
  const [summary, setSummary] = useState("");

  const countries = countryList;
  const handleComplete = () => {
    console.log("Form completed!");
    // Handle form completion logic here
  };
  // check validate tab
  const checkValidateTab = () => {
    if (
      fName === "" ||
      lName === "" ||
      email === "" ||
      phone === "" ||
      currentCity === "" ||
      currentTitle === "" ||
      totalExp === ""
    ) {
      return false;
    }
  };

  const handleFileChange = (event) => {
    setCvUpload(event.target.value);
  };
  // error messages
  const errorMessages = () => {
    setIsSubmit(true);
  };

  const backTemplate = (handlePrevious: () => void) => {
    return (
      <Btn onClick={handlePrevious} variant="text" className="py-6 mt-0">
        <ChevronLeftIcon className="w-6 h-6" />
        Back
      </Btn>
    );
  };

  const nextTemplate = (handleNext: () => void) => {
    return (
      <Btn type="submit" onClick={handleNext} className="mt-0 ml-auto">
        Next
      </Btn>
    );
  };

  const finishTemplate = (handleComplete: () => void) => {
    const handleSubmit = () => {
      if (cvUpload.trim().length !== 0) {
        handleComplete;
        navigate("/thank-you", { replace: true });
      } else {
        console.log("Error");
      }
    };
    return (
      <Btn type="submit" onClick={handleSubmit} className="mt-0 ml-auto">
        Submit
      </Btn>
    );
  };

  return (
    <div className="flex min-h-screen transition-all">
      <div className="lg:w-8/12 bg-light h-100 xl:px-32 xl:py-12 flex w-full p-8">
        <div className="w-full my-auto">
          <a href="/">
            <Img
              cssClass="h-[88px] w-[88px] mb-14  rounded-full"
              imgSrc="logo.svg"
              imgAlt="+OneX"
            />
          </a>

          <FormWizard
            stepSize="xs"
            onComplete={handleComplete}
            backButtonTemplate={backTemplate}
            nextButtonTemplate={nextTemplate}
            finishButtonTemplate={finishTemplate}
            color="black"
          >
            <FormWizard.TabContent>
              <Headings
                headType="h1"
                titleText="Your Details"
                className="xl:text-4xl mb-14 text-2xl font-medium text-left"
              />
              <div className="xl:grid-cols-2 grid gap-8 text-left">
                <div>
                  <Input
                    variant="standard"
                    label="First name"
                    type="text"
                    crossOrigin={undefined}
                    required
                    defaultValue={" "}
                    value={fName}
                    onChange={(e) => setFName(e.target.value)}
                    error={!fName && isSubmit}
                  />
                </div>
                <div>
                  <Input
                    variant="standard"
                    label="Last name"
                    type="text"
                    crossOrigin={undefined}
                    required
                    value={lName}
                    onChange={(e) => setLName(e.target.value)}
                    error={!lName && isSubmit}
                  />
                </div>
                <div>
                  <Input
                    variant="standard"
                    label="Email"
                    type="email"
                    crossOrigin={undefined}
                    required
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    error={!email && isSubmit}
                  />
                </div>
                <div>
                  <Input
                    variant="standard"
                    label="Phone"
                    type="phone"
                    crossOrigin={undefined}
                    required
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                    error={!phone && isSubmit}
                  />
                </div>
                <div className="xl:col-span-2">
                  <Select
                    variant="standard"
                    label="Current Country"
                    selected={(element) =>
                      element &&
                      cloneElement(element, {
                        disabled: true,
                        className:
                          "flex items-center opacity-100 px-0 gap-2 pointer-events-none",
                      })
                    }
                  >
                    {countries.map((ele, i) => (
                      <Option
                        key={i}
                        value={ele.name}
                        className="flex items-center gap-2"
                      >
                        <img src={ele.flag} alt={ele.name} className=" w-5" />
                        {ele.name}
                      </Option>
                    ))}
                  </Select>
                </div>
                <div>
                  <Input
                    variant="standard"
                    label="Current City"
                    type="text"
                    crossOrigin={undefined}
                    required
                    value={currentCity}
                    onChange={(e) => setCurrentCity(e.target.value)}
                    error={!currentCity && isSubmit}
                  />
                </div>
                <div>
                  <Select variant="standard" label="Gender">
                    <Option>Male</Option>
                    <Option>Female</Option>
                    <Option>Other</Option>
                  </Select>
                </div>
                <div>
                  <Input
                    variant="standard"
                    label="Current Title"
                    type="text"
                    crossOrigin={undefined}
                    required
                    value={currentTitle}
                    onChange={(e) => setCurrentTitle(e.target.value)}
                    error={!currentTitle && isSubmit}
                  />
                </div>
                <div>
                  <Input
                    variant="standard"
                    type="text"
                    label="Total Yrs of Experience"
                    crossOrigin={undefined}
                    required
                    value={totalExp}
                    onChange={(e) => setTotalExp(e.target.value)}
                    error={!totalExp && isSubmit}
                  />
                </div>
                <div className="xl:col-span-2">
                  <Textarea
                    variant="static"
                    label="Summary"
                    placeholder="Write your message"
                    value={summary}
                    onChange={(e) => setSummary(e.target.value)}
                  />
                </div>
              </div>
            </FormWizard.TabContent>
            <FormWizard.TabContent
              isValid={checkValidateTab()}
              validationError={errorMessages}
            >
              <Headings
                headType="h2"
                titleText="Education and Qualification"
                className="xl:text-4xl mb-14 text-2xl font-medium text-left"
              />
              <div className="flex overflow-x-auto">
                <table className="min-w-max w-full text-left table-auto">
                  <thead>
                    <tr className="bg-primary border-b border-gray-200">
                      <th className=" p-2">
                        <Typography
                          variant="small"
                          color="white"
                          className="opacity-70 font-normal leading-none"
                        >
                          Institute
                        </Typography>
                      </th>

                      <th className=" p-2">
                        <Typography
                          variant="small"
                          color="white"
                          className="opacity-70 font-normal leading-none"
                        >
                          Qualification
                        </Typography>
                      </th>
                      <th className=" p-2">
                        <Typography
                          variant="small"
                          color="white"
                          className="opacity-70 font-normal leading-none"
                        >
                          Year
                        </Typography>
                      </th>
                      <th className=" p-2"> </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="bg-[#f4f4f4] border-b border-gray-100">
                      <td className="p-2">
                        <Input
                          variant="standard"
                          className="text-sm"
                          crossOrigin={undefined}
                        />
                      </td>
                      <td className="p-2">
                        <Input
                          variant="standard"
                          className="text-sm"
                          crossOrigin={undefined}
                        />
                      </td>
                      <td className="p-2">
                        <Input
                          variant="standard"
                          className="text-sm"
                          crossOrigin={undefined}
                        />
                      </td>
                      <td className="p-2">
                        <IconButton variant="gradient" size="sm">
                          <PlusIcon className="w-4 h-4" />
                        </IconButton>
                      </td>
                    </tr>
                    <tr className="border-b border-gray-200">
                      <td className="p-2">
                        <Typography
                          variant="small"
                          color="gray"
                          className="font-normal"
                        >
                          SGU
                        </Typography>
                      </td>
                      <td className="p-2">
                        <Typography
                          variant="small"
                          color="gray"
                          className="font-normal"
                        >
                          M.Sc.(I.T.)
                        </Typography>
                      </td>
                      <td className="p-2">
                        <Typography
                          variant="small"
                          color="gray"
                          className="font-normal"
                        >
                          2010
                        </Typography>
                      </td>
                      <td className="p-2">
                        <div className="flex gap-2">
                          <IconButton variant="gradient" size="sm">
                            <PencilIcon className="w-4 h-4" />
                          </IconButton>
                          <IconButton color="red" variant="gradient" size="sm">
                            <TrashIcon className="w-4 h-4" />
                          </IconButton>
                        </div>
                      </td>
                    </tr>
                    <tr className="border-b border-gray-200">
                      <td className="p-2">
                        <Typography
                          variant="small"
                          color="gray"
                          className="font-normal"
                        >
                          SGU
                        </Typography>
                      </td>
                      <td className="p-2">
                        <Typography
                          variant="small"
                          color="gray"
                          className="font-normal"
                        >
                          M.Sc.(I.T.)
                        </Typography>
                      </td>
                      <td className="p-2">
                        <Typography
                          variant="small"
                          color="gray"
                          className="font-normal"
                        >
                          2010
                        </Typography>
                      </td>
                      <td className="p-2">
                        <div className="flex gap-2">
                          <IconButton variant="gradient" size="sm">
                            <PencilIcon className="w-4 h-4" />
                          </IconButton>
                          <IconButton color="red" variant="gradient" size="sm">
                            <TrashIcon className="w-4 h-4" />
                          </IconButton>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <Headings
                headType="h2"
                titleText="Additional Courses Attended"
                className="xl:text-4xl my-14 text-2xl font-medium text-left"
              />
              <div className="flex overflow-x-auto">
                <table className="min-w-max w-full text-left table-auto">
                  <thead>
                    <tr className="bg-primary border-b border-gray-200">
                      <th className=" p-2">
                        <Typography
                          variant="small"
                          color="white"
                          className="opacity-70 font-normal leading-none"
                        >
                          Institute
                        </Typography>
                      </th>

                      <th className=" p-2">
                        <Typography
                          variant="small"
                          color="white"
                          className="opacity-70 font-normal leading-none"
                        >
                          Qualification
                        </Typography>
                      </th>
                      <th className=" p-2">
                        <Typography
                          variant="small"
                          color="white"
                          className="opacity-70 font-normal leading-none"
                        >
                          Year
                        </Typography>
                      </th>
                      <th className=" p-2"> </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="bg-[#f4f4f4] border-b border-gray-100">
                      <td className="p-2">
                        <Input
                          variant="standard"
                          className="text-sm"
                          crossOrigin={undefined}
                        />
                      </td>
                      <td className="p-2">
                        <Input
                          variant="standard"
                          className="text-sm"
                          crossOrigin={undefined}
                        />
                      </td>
                      <td className="p-2">
                        <Input
                          variant="standard"
                          className="text-sm"
                          crossOrigin={undefined}
                        />
                      </td>
                      <td className="p-2">
                        <IconButton variant="gradient" size="sm">
                          <PlusIcon className="w-4 h-4" />
                        </IconButton>
                      </td>
                    </tr>
                    <tr className="border-b border-gray-200">
                      <td className="p-2">
                        <Typography
                          variant="small"
                          color="gray"
                          className="font-normal"
                        >
                          SGU
                        </Typography>
                      </td>
                      <td className="p-2">
                        <Typography
                          variant="small"
                          color="gray"
                          className="font-normal"
                        >
                          M.Sc.(I.T.)
                        </Typography>
                      </td>
                      <td className="p-2">
                        <Typography
                          variant="small"
                          color="gray"
                          className="font-normal"
                        >
                          2010
                        </Typography>
                      </td>
                      <td className="p-2">
                        <div className="flex gap-2">
                          <IconButton variant="gradient" size="sm">
                            <PencilIcon className="w-4 h-4" />
                          </IconButton>
                          <IconButton color="red" variant="gradient" size="sm">
                            <TrashIcon className="w-4 h-4" />
                          </IconButton>
                        </div>
                      </td>
                    </tr>
                    <tr className="border-b border-gray-200">
                      <td className="p-2">
                        <Typography
                          variant="small"
                          color="gray"
                          className="font-normal"
                        >
                          SGU
                        </Typography>
                      </td>
                      <td className="p-2">
                        <Typography
                          variant="small"
                          color="gray"
                          className="font-normal"
                        >
                          M.Sc.(I.T.)
                        </Typography>
                      </td>
                      <td className="p-2">
                        <Typography
                          variant="small"
                          color="gray"
                          className="font-normal"
                        >
                          2010
                        </Typography>
                      </td>
                      <td className="p-2">
                        <div className="flex gap-2">
                          <IconButton variant="gradient" size="sm">
                            <PencilIcon className="w-4 h-4" />
                          </IconButton>
                          <IconButton color="red" variant="gradient" size="sm">
                            <TrashIcon className="w-4 h-4" />
                          </IconButton>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </FormWizard.TabContent>
            <FormWizard.TabContent>
              <Headings
                headType="h2"
                titleText="Employment History"
                className="xl:text-4xl mb-14 text-2xl font-medium text-left"
              />
              <div className="flex overflow-x-auto">
                <table className="min-w-max w-full text-left table-auto">
                  <thead>
                    <tr className="bg-primary border-b border-gray-200">
                      <th className=" p-2">
                        <Typography
                          variant="small"
                          color="white"
                          className="opacity-70 font-normal leading-none"
                        >
                          Company
                        </Typography>
                      </th>

                      <th className=" p-2">
                        <Typography
                          variant="small"
                          color="white"
                          className="opacity-70 font-normal leading-none"
                        >
                          Role
                        </Typography>
                      </th>
                      <th className=" p-2">
                        <Typography
                          variant="small"
                          color="white"
                          className="opacity-70 font-normal leading-none"
                        >
                          Period
                        </Typography>
                      </th>
                      <th className=" p-2">
                        <Typography
                          variant="small"
                          color="white"
                          className="opacity-70 font-normal leading-none"
                        >
                          Responsibilities
                        </Typography>
                      </th>
                      <th className=" p-2"> </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="bg-[#f4f4f4] border-b border-gray-100 align-bottom ">
                      <td className="p-2">
                        <Input
                          variant="standard"
                          crossOrigin={undefined}
                          className="text-sm"
                        />
                      </td>
                      <td className="p-2">
                        <Input
                          variant="standard"
                          crossOrigin={undefined}
                          className="text-sm"
                        />
                      </td>
                      <td className="p-2">
                        <div className="columns-2 grid gap-4">
                          <Input
                            variant="standard"
                            placeholder="From"
                            type="date"
                            label="From"
                            crossOrigin={undefined}
                            className="text-sm"
                          />
                          <Input
                            variant="standard"
                            placeholder="To"
                            type="date"
                            label="To"
                            crossOrigin={undefined}
                            className="text-sm"
                          />
                        </div>
                      </td>
                      <td className="p-2">
                        <Textarea
                          variant="standard"
                          className="!pb-0 text-sm"
                        />
                      </td>
                      <td className="p-2">
                        <IconButton variant="gradient" size="sm">
                          <PlusIcon className="w-4 h-4" />
                        </IconButton>
                      </td>
                    </tr>
                    <tr className="border-b border-gray-200">
                      <td className="p-2">
                        <Typography
                          variant="small"
                          color="gray"
                          className="font-normal"
                        >
                          Maven
                        </Typography>
                      </td>
                      <td className="p-2">
                        <Typography
                          variant="small"
                          color="gray"
                          className="font-normal"
                        >
                          Front End Developer
                        </Typography>
                      </td>
                      <td className="p-2">
                        <Typography
                          variant="small"
                          color="gray"
                          className="font-normal"
                        >
                          2019 - Present
                        </Typography>
                      </td>
                      <td className="p-2">
                        <Typography
                          variant="small"
                          color="gray"
                          className="font-normal"
                        >
                          Design frontends
                        </Typography>
                      </td>
                      <td className="p-2">
                        <div className="flex gap-2">
                          <IconButton variant="gradient" size="sm">
                            <PencilIcon className="w-4 h-4" />
                          </IconButton>
                          <IconButton color="red" variant="gradient" size="sm">
                            <TrashIcon className="w-4 h-4" />
                          </IconButton>
                        </div>
                      </td>
                    </tr>
                    <tr className="border-b border-gray-200">
                      <td className="p-2">
                        <Typography
                          variant="small"
                          color="gray"
                          className="font-normal"
                        >
                          Maven
                        </Typography>
                      </td>
                      <td className="p-2">
                        <Typography
                          variant="small"
                          color="gray"
                          className="font-normal"
                        >
                          Front End Developer
                        </Typography>
                      </td>
                      <td className="p-2">
                        <Typography
                          variant="small"
                          color="gray"
                          className="font-normal"
                        >
                          2019 - Present
                        </Typography>
                      </td>
                      <td className="p-2">
                        <Typography
                          variant="small"
                          color="gray"
                          className="font-normal"
                        >
                          Design frontends
                        </Typography>
                      </td>
                      <td className="p-2">
                        <div className="flex gap-2">
                          <IconButton variant="gradient" size="sm">
                            <PencilIcon className="w-4 h-4" />
                          </IconButton>
                          <IconButton color="red" variant="gradient" size="sm">
                            <TrashIcon className="w-4 h-4" />
                          </IconButton>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </FormWizard.TabContent>
            <FormWizard.TabContent>
              <Headings
                headType="h2"
                titleText="Projects"
                className="xl:text-4xl mb-14 text-2xl font-medium text-left"
              />
              <div className="flex overflow-x-auto">
                <table className="min-w-max w-full text-left table-auto">
                  <thead>
                    <tr className="bg-primary border-b border-gray-200">
                      <th className=" p-2">
                        <Typography
                          variant="small"
                          color="white"
                          className="opacity-70 font-normal leading-none"
                        >
                          Title
                        </Typography>
                      </th>

                      <th className=" p-2">
                        <Typography
                          variant="small"
                          color="white"
                          className="opacity-70 font-normal leading-none"
                        >
                          Description
                        </Typography>
                      </th>
                      <th className=" p-2">
                        <Typography
                          variant="small"
                          color="white"
                          className="opacity-70 font-normal leading-none"
                        >
                          Company
                        </Typography>
                      </th>
                      <th className=" p-2"> </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="bg-[#f4f4f4] border-b border-gray-100">
                      <td className="p-2">
                        <Input
                          variant="standard"
                          className="text-sm"
                          crossOrigin={undefined}
                        />
                      </td>
                      <td className="p-2">
                        <Input
                          variant="standard"
                          className="text-sm"
                          crossOrigin={undefined}
                        />
                      </td>
                      <td className="p-2">
                        <Input
                          variant="standard"
                          className="text-sm"
                          crossOrigin={undefined}
                        />
                      </td>
                      <td className="p-2">
                        <IconButton variant="gradient" size="sm">
                          <PlusIcon className="w-4 h-4" />
                        </IconButton>
                      </td>
                    </tr>
                    <tr className="border-b border-gray-200">
                      <td className="p-2">
                        <Typography
                          variant="small"
                          color="gray"
                          className="font-normal"
                        >
                          Box Crick
                        </Typography>
                      </td>
                      <td className="p-2">
                        <Typography
                          variant="small"
                          color="gray"
                          className="font-normal"
                        >
                          Box cricket score management app developed in flutter
                        </Typography>
                      </td>
                      <td className="p-2">
                        <Typography
                          variant="small"
                          color="gray"
                          className="font-normal"
                        >
                          PlusOneX
                        </Typography>
                      </td>
                      <td className="p-2">
                        <div className="flex gap-2">
                          <IconButton variant="gradient" size="sm">
                            <PencilIcon className="w-4 h-4" />
                          </IconButton>
                          <IconButton color="red" variant="gradient" size="sm">
                            <TrashIcon className="w-4 h-4" />
                          </IconButton>
                        </div>
                      </td>
                    </tr>
                    <tr className="border-b border-gray-200">
                      <td className="p-2">
                        <Typography
                          variant="small"
                          color="gray"
                          className="font-normal"
                        >
                          Box Crick
                        </Typography>
                      </td>
                      <td className="p-2">
                        <Typography
                          variant="small"
                          color="gray"
                          className="font-normal"
                        >
                          Box cricket score management app developed in flutter
                        </Typography>
                      </td>
                      <td className="p-2">
                        <Typography
                          variant="small"
                          color="gray"
                          className="font-normal"
                        >
                          PlusOneX
                        </Typography>
                      </td>
                      <td className="p-2">
                        <div className="flex gap-2">
                          <IconButton variant="gradient" size="sm">
                            <PencilIcon className="w-4 h-4" />
                          </IconButton>
                          <IconButton color="red" variant="gradient" size="sm">
                            <TrashIcon className="w-4 h-4" />
                          </IconButton>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </FormWizard.TabContent>
            <FormWizard.TabContent>
              <Headings
                headType="h2"
                titleText="Skills"
                className="xl:text-4xl mb-14 text-2xl font-medium text-left"
              />
              <div className="flex flex-col gap-8">
                <div className="overflow-x-auto">
                  <table className="min-w-max w-full text-left table-auto">
                    <thead>
                      <tr className="bg-primary border-b border-gray-200">
                        <th className=" p-2">
                          <Typography
                            variant="small"
                            color="white"
                            className="opacity-70 font-normal leading-none"
                          >
                            Skill
                          </Typography>
                        </th>

                        <th className=" p-2">
                          <Typography
                            variant="small"
                            color="white"
                            className="opacity-70 font-normal leading-none"
                          >
                            Experience
                          </Typography>
                        </th>
                        <th className=" p-2">
                          <Typography
                            variant="small"
                            color="white"
                            className="opacity-70 font-normal leading-none"
                          >
                            Last used
                          </Typography>
                        </th>
                        <th className=" p-2">
                          <Typography
                            variant="small"
                            color="white"
                            className="opacity-70 font-normal leading-none"
                          >
                            Rating
                          </Typography>
                        </th>
                        <th className=" p-2"> </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className="bg-[#f4f4f4] border-b border-gray-100">
                        <td className="p-2">
                          <Input
                            variant="standard"
                            className="text-sm"
                            crossOrigin={undefined}
                          />
                        </td>
                        <td className="p-2">
                          <Input
                            variant="standard"
                            className="text-sm"
                            crossOrigin={undefined}
                          />
                        </td>
                        <td className="p-2">
                          <Input
                            variant="standard"
                            className="text-sm"
                            crossOrigin={undefined}
                          />
                        </td>
                        <td className="p-2">
                          <Rating color="primary" />
                        </td>
                        <td className="p-2">
                          <IconButton variant="gradient" size="sm">
                            <PlusIcon className="w-4 h-4" />
                          </IconButton>
                        </td>
                      </tr>
                      <tr className="border-b border-gray-200">
                        <td className="p-2">
                          <Typography
                            variant="small"
                            color="gray"
                            className="font-normal"
                          >
                            React
                          </Typography>
                        </td>
                        <td className="p-2">
                          <Typography
                            variant="small"
                            color="gray"
                            className="font-normal"
                          >
                            5 yrs.
                          </Typography>
                        </td>
                        <td className="p-2">
                          <Typography
                            variant="small"
                            color="gray"
                            className="font-normal"
                          >
                            1 months ago
                          </Typography>
                        </td>
                        <td className="p-2">
                          <Rating value={4} readonly />
                        </td>
                        <td className="p-2">
                          <div className="flex gap-2">
                            <IconButton variant="gradient" size="sm">
                              <PencilIcon className="w-4 h-4" />
                            </IconButton>
                            <IconButton
                              color="red"
                              variant="gradient"
                              size="sm"
                            >
                              <TrashIcon className="w-4 h-4" />
                            </IconButton>
                          </div>
                        </td>
                      </tr>
                      <tr className="border-b border-gray-200">
                        <td className="p-2">
                          <Typography
                            variant="small"
                            color="gray"
                            className="font-normal"
                          >
                            Angular
                          </Typography>
                        </td>
                        <td className="p-2">
                          <Typography
                            variant="small"
                            color="gray"
                            className="font-normal"
                          >
                            5 yrs.
                          </Typography>
                        </td>
                        <td className="p-2">
                          <Typography
                            variant="small"
                            color="gray"
                            className="font-normal"
                          >
                            1 months ago
                          </Typography>
                        </td>
                        <td className="p-2">
                          <Rating value={3} readonly />
                        </td>
                        <td className="p-2">
                          <div className="flex gap-2">
                            <IconButton variant="gradient" size="sm">
                              <PencilIcon className="w-4 h-4" />
                            </IconButton>
                            <IconButton
                              color="red"
                              variant="gradient"
                              size="sm"
                            >
                              <TrashIcon className="w-4 h-4" />
                            </IconButton>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </FormWizard.TabContent>
            <FormWizard.TabContent>
              <Headings
                headType="h2"
                titleText="Upload your CV"
                className="xl:text-4xl mb-14 text-2xl font-medium text-left"
              />
              <div className="">
                <Input
                  type="file"
                  variant="static"
                  label="Upload your CV"
                  className="file:px-2 file:rounded-full file:border-0 file:text-sm file:bg-black file:text-white hover:file:bg-gray-900"
                  crossOrigin={undefined}
                  required
                  id="cvUpload"
                  onChange={handleFileChange}
                />
                <div className="flex items-center gap-1 mt-2 text-sm font-normal text-gray-600">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    className="w-4 h-4 -mt-px"
                  >
                    <path
                      fillRule="evenodd"
                      d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm8.706-1.442c1.146-.573 2.437.463 2.126 1.706l-.709 2.836.042-.02a.75.75 0 01.67 1.34l-.04.022c-1.147.573-2.438-.463-2.127-1.706l.71-2.836-.042.02a.75.75 0 11-.671-1.34l.041-.022zM12 9a.75.75 0 100-1.5.75.75 0 000 1.5z"
                      clipRule="evenodd"
                    />
                  </svg>
                  Upload Word doc or PDF
                </div>
              </div>
            </FormWizard.TabContent>
          </FormWizard>
        </div>
      </div>
      <div className="lg:w-4/12 bg-primary h-100 flex flex-col w-full p-4 text-white">
        <div className="flex justify-end w-full">
          <IconButton className="rounded-full" onClick={() => navigate(-1)}>
            <XMarkIcon className="w-6 h-6" />
          </IconButton>
        </div>
        <div className="flex flex-col items-center xl:my-40">
          <Headings
            headType="h2"
            titleText={"Join us"}
            className="xl:text-6xl text-5xl font-medium  mb-14 "
          />
          <div className="text-lg text-center">
            <p>
              We believe that great work comes from having the freedom to
              explore your ideas and unleash your potential - that’s what we’re
              all about. Helping you be the best you.
            </p>
            <p>Fill up the form and we will get back to you.</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ApplyHere;
