import { useRouteError } from "react-router-dom";

export default function ErrorPage() {
  const error = useRouteError();
  console.error(error);

  return (
    <div id="error-page" className="flex flex-col items-center justify-center h-screen">
      <h1 className="mb-5 text-5xl font-semibold leading-normal">Oops!</h1>
      <p>Sorry, an unexpected error has occurred.</p>
    </div>
  );
}
