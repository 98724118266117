const skillOption = [
  {
    id: 227,
    value: "account-management",
    label: "Account Management",
  },
  {
    id: 213,
    value: "activecollab",
    label: "ActiveCollab",
  },
  {
    id: 288,
    value: "add-new-q",
    label: "Add New Q",
  },
  {
    id: 209,
    value: "adobe-fireworks",
    label: "Adobe Fireworks",
  },
  {
    id: 40,
    value: "adobe-illustrator",
    label: "Adobe Illustrator",
  },
  {
    id: 43,
    value: "adobe-indesign",
    label: "Adobe InDesign",
  },
  {
    id: 39,
    value: "adobe-premier",
    label: "Adobe Premier",
  },
  {
    id: 208,
    value: "adobe-xd",
    label: "Adobe XD",
  },
  {
    id: 225,
    value: "agile-project-management",
    label: "Agile Project Management",
  },
  {
    id: 204,
    value: "algolia",
    label: "Algolia",
  },
  {
    id: 156,
    value: "amazon-rds",
    label: "Amazon RDS",
  },
  {
    id: 25,
    value: "amp-html",
    label: "AMP HTML",
  },
  {
    id: 157,
    value: "androvalue",
    label: "Androvalue",
  },
  {
    id: 162,
    value: "androvalue-java",
    label: "Androvalue Java",
  },
  {
    id: 165,
    value: "androvalue-native",
    label: "Androvalue Native",
  },
  {
    id: 163,
    value: "androvalue-xamarin-(c#)",
    label: "Androvalue Xamarin (C#)",
  },
  {
    id: 2,
    value: "angular",
    label: "Angular",
  },
  {
    id: 218,
    value: "angular-cli",
    label: "Angular CLI",
  },
  {
    id: 184,
    value: "angular-testing",
    label: "Angular Testing",
  },
  {
    id: 272,
    value: "animate",
    label: "Animate",
  },
  {
    id: 273,
    value: "animate1",
    label: "Animate1",
  },
  {
    id: 126,
    value: "apache",
    label: "Apache",
  },
  {
    id: 32,
    value: "apollo",
    label: "Apollo",
  },
  {
    id: 147,
    value: "appdynamics",
    label: "AppDynamics",
  },
  {
    id: 255,
    value: "apprun",
    label: "AppRun",
  },
  {
    id: 240,
    value: "architecture-&-design",
    label: "Architecture & Design",
  },
  {
    id: 215,
    value: "asana",
    label: "Asana",
  },
  {
    id: 13,
    value: "asp.net",
    label: "ASP.NET",
  },
  {
    id: 69,
    value: "asp.net-mvc(c#)",
    label: "ASP.NET MVC(C#)",
  },
  {
    id: 70,
    value: "asp.net-web-api",
    label: "ASP.NET Web API",
  },
  {
    id: 68,
    value: "asp.net/c#",
    label: "ASP.NET/C#",
  },
  {
    id: 220,
    value: "assembler-80x86",
    label: "Assembler 80x86",
  },
  {
    id: 200,
    value: "assert-(ruby)",
    label: "Assert (Ruby)",
  },
  {
    id: 114,
    value: "aws",
    label: "AWS",
  },
  {
    id: 119,
    value: "aws---ec2",
    label: "AWS - EC2",
  },
  {
    id: 120,
    value: "aws---ecs",
    label: "AWS - ECS",
  },
  {
    id: 121,
    value: "aws---eks",
    label: "AWS - EKS",
  },
  {
    id: 253,
    value: "axios",
    label: "Axios",
  },
  {
    id: 100,
    value: "azure",
    label: "Azure",
  },
  {
    id: 293,
    value: "azurwww",
    label: "Azurwww",
  },
  {
    id: 292,
    value: "azurwx",
    label: "Azurwx",
  },
  {
    id: 26,
    value: "backbone.js",
    label: "Backbone.js",
  },
  {
    id: 42,
    value: "balsamiq",
    label: "Balsamiq",
  },
  {
    id: 88,
    value: "bash-scripting",
    label: "Bash Scripting",
  },
  {
    id: 179,
    value: "basic-testing",
    label: "Basic Testing",
  },
  {
    id: 271,
    value: "bbed",
    label: "Bbed",
  },
  {
    id: 130,
    value: "bitbucket",
    label: "Bitbucket",
  },
  {
    id: 221,
    value: "blender",
    label: "Blender",
  },
  {
    id: 14,
    value: "bootstrap",
    label: "Bootstrap",
  },
  {
    id: 212,
    value: "bugherd",
    label: "BugHerd",
  },
  {
    id: 16,
    value: "bulma",
    label: "Bulma",
  },
  {
    id: 178,
    value: "burp-suite",
    label: "Burp Suite",
  },
  {
    id: 230,
    value: "business-analysis",
    label: "Business Analysis",
  },
  {
    id: 80,
    value: "c#-wpf-.net-framework-and-core",
    label: "C# WPF .NET Framework And Core",
  },
  {
    id: 223,
    value: "c/c++",
    label: "C/C++",
  },
  {
    id: 222,
    value: "c/c++-(atmega,-esp8266,-esp32)",
    label: "C/C++ (ATmega, ESP8266, ESP32)",
  },
  {
    id: 202,
    value: "capacitor",
    label: "Capacitor",
  },
  {
    id: 97,
    value: "cassandra",
    label: "Cassandra",
  },
  {
    id: 194,
    value: "chai",
    label: "Chai",
  },
  {
    id: 139,
    value: "chef",
    label: "Chef",
  },
  {
    id: 146,
    value: "ci/cd-infrastructure",
    label: "CI/CD Infrastructure",
  },
  {
    id: 141,
    value: "circleci",
    label: "CircleCI",
  },
  {
    id: 219,
    value: "clickup",
    label: "ClickUp",
  },
  {
    id: 135,
    value: "cloudfront",
    label: "CloudFront",
  },
  {
    id: 60,
    value: "codeigniter",
    label: "CodeIgniter",
  },
  {
    id: 266,
    value: "communication",
    label: "Communication",
  },
  {
    id: 168,
    value: "construct-2.0",
    label: "Construct 2.0",
  },
  {
    id: 172,
    value: "cordova",
    label: "Cordova",
  },
  {
    id: 158,
    value: "cordova-/-phonegap",
    label: "Cordova / PhoneGap",
  },
  {
    id: 59,
    value: "core-php",
    label: "Core PHP",
  },
  {
    id: 238,
    value: "corporate-it-support-and-governance",
    label: "Corporate IT Support And Governance",
  },
  {
    id: 196,
    value: "crontab",
    label: "Crontab",
  },
  {
    id: 93,
    value: "crystal",
    label: "Crystal",
  },
  {
    id: 11,
    value: "css",
    label: "CSS",
  },
  {
    id: 20,
    value: "css-(sass,-less)",
    label: "CSS (Sass, Less)",
  },
  {
    id: 245,
    value: "data-studio",
    label: "Data Studio",
  },
  {
    id: 294,
    value: "dddd",
    label: "Dddd",
  },
  {
    id: 211,
    value: "delphi",
    label: "Delphi",
  },
  {
    id: 252,
    value: "df",
    label: "Df",
  },
  {
    id: 269,
    value: "dfsw",
    label: "Dfsw",
  },
  {
    id: 154,
    value: "digital-ocean",
    label: "Digital Ocean",
  },
  {
    id: 123,
    value: "digitalocean",
    label: "DigitalOcean",
  },
  {
    id: 235,
    value: "discord",
    label: "Discord",
  },
  {
    id: 285,
    value: "display-new-added-skill-below",
    label: "Display New Added Skill Below",
  },
  {
    id: 115,
    value: "docker",
    label: "Docker",
  },
  {
    id: 276,
    value: "docker-1",
    label: "Docker 1",
  },
  {
    id: 143,
    value: "docker-compose",
    label: "Docker Compose",
  },
  {
    id: 144,
    value: "docker-swarm",
    label: "Docker Swarm",
  },
  {
    id: 142,
    value: "docker/kubernetes",
    label: "Docker/Kubernetes",
  },
  {
    id: 297,
    value: "dockerww",
    label: "Dockerww",
  },
  {
    id: 63,
    value: "drupal",
    label: "Drupal",
  },
  {
    id: 102,
    value: "dynamodb",
    label: "DynamoDB",
  },
  {
    id: 103,
    value: "elasticsearch",
    label: "Elasticsearch",
  },
  {
    id: 92,
    value: "elixir",
    label: "Elixir",
  },
  {
    id: 117,
    value: "elk-stack",
    label: "ELK Stack",
  },
  {
    id: 22,
    value: "ember.js",
    label: "Ember.js",
  },
  {
    id: 75,
    value: "entity-framework",
    label: "Entity Framework",
  },
  {
    id: 187,
    value: "enzyme",
    label: "Enzyme",
  },
  {
    id: 261,
    value: "ertg",
    label: "Ertg",
  },
  {
    id: 73,
    value: "express-(node.js)",
    label: "Express (Node.js)",
  },
  {
    id: 71,
    value: "expressjs",
    label: "ExpressJS",
  },
  {
    id: 246,
    value: "facebook-marketing",
    label: "Facebook Marketing",
  },
  {
    id: 295,
    value: "fdddfs",
    label: "Fdddfs",
  },
  {
    id: 52,
    value: "firebase",
    label: "Firebase",
  },
  {
    id: 36,
    value: "flutter",
    label: "Flutter",
  },
  {
    id: 170,
    value: "flutter/dart",
    label: "Flutter/Dart",
  },
  {
    id: 15,
    value: "foundation",
    label: "Foundation",
  },
  {
    id: 159,
    value: "framework7",
    label: "Framework7",
  },
  {
    id: 125,
    value: "gae",
    label: "GAE",
  },
  {
    id: 30,
    value: "gatsby",
    label: "Gatsby",
  },
  {
    id: 113,
    value: "git",
    label: "Git",
  },
  {
    id: 122,
    value: "gitlab-ci/cd",
    label: "GitLab CI/CD",
  },
  {
    id: 90,
    value: "go",
    label: "Go",
  },
  {
    id: 241,
    value: "google-analytics",
    label: "Google Analytics",
  },
  {
    id: 133,
    value: "google-cloud",
    label: "Google Cloud",
  },
  {
    id: 244,
    value: "google-display",
    label: "Google Display",
  },
  {
    id: 243,
    value: "google-search",
    label: "Google Search",
  },
  {
    id: 128,
    value: "google-web-server",
    label: "Google Web Server",
  },
  {
    id: 118,
    value: "grafana",
    label: "Grafana",
  },
  {
    id: 193,
    value: "gremlin",
    label: "Gremlin",
  },
  {
    id: 150,
    value: "ha-replication-server",
    label: "HA Replication Server",
  },
  {
    id: 138,
    value: "heroku",
    label: "Heroku",
  },
  {
    id: 50,
    value: "hibernate",
    label: "Hibernate",
  },
  {
    id: 10,
    value: "html",
    label: "HTML",
  },
  {
    id: 169,
    value: "html5-canvas",
    label: "HTML5 Canvas",
  },
  {
    id: 84,
    value: "http",
    label: "HTTP",
  },
  {
    id: 129,
    value: "ibm",
    label: "IBM",
  },
  {
    id: 81,
    value: "valueentity-framework",
    label: "valueentity Framework",
  },
  {
    id: 82,
    value: "valueentity-server",
    label: "valueentity Server",
  },
  {
    id: 109,
    value: "influx",
    label: "Influx",
  },
  {
    id: 249,
    value: "instagram",
    label: "Instagram",
  },
  {
    id: 180,
    value: "integration-testing",
    label: "Integration Testing",
  },
  {
    id: 9,
    value: "ionic",
    label: "Ionic",
  },
  {
    id: 45,
    value: "java",
    label: "Java",
  },
  {
    id: 7,
    value: "javascript",
    label: "JavaScript",
  },
  {
    id: 49,
    value: "jbpm",
    label: "JBPM",
  },
  {
    id: 47,
    value: "jee",
    label: "JEE",
  },
  {
    id: 110,
    value: "jenkins",
    label: "Jenkins",
  },
  {
    id: 145,
    value: "jenkins-ci/cd",
    label: "Jenkins CI/CD",
  },
  {
    id: 177,
    value: "jest",
    label: "Jest",
  },
  {
    id: 214,
    value: "jira",
    label: "JIRA",
  },
  {
    id: 66,
    value: "joomla",
    label: "Joomla",
  },
  {
    id: 56,
    value: "jpa-/-hibernate",
    label: "JPA / Hibernate",
  },
  {
    id: 6,
    value: "jquery",
    label: "JQuery",
  },
  {
    id: 160,
    value: "jquery-mobile",
    label: "Jquery Mobile",
  },
  {
    id: 18,
    value: "jsf",
    label: "JSF",
  },
  {
    id: 216,
    value: "json",
    label: "JSON",
  },
  {
    id: 3,
    value: "jsp",
    label: "JSP",
  },
  {
    id: 173,
    value: "junit",
    label: "JUnit",
  },
  {
    id: 41,
    value: "keynote",
    label: "Keynote",
  },
  {
    id: 76,
    value: "koa",
    label: "Koa",
  },
  {
    id: 116,
    value: "kubernetes",
    label: "Kubernetes",
  },
  {
    id: 61,
    value: "laravel",
    label: "Laravel",
  },
  {
    id: 250,
    value: "linkedin",
    label: "LinkedIn",
  },
  {
    id: 197,
    value: "linting",
    label: "Linting",
  },
  {
    id: 91,
    value: "lua",
    label: "Lua",
  },
  {
    id: 65,
    value: "magento",
    label: "Magento",
  },
  {
    id: 148,
    value: "mail-server",
    label: "Mail Server",
  },
  {
    id: 251,
    value: "mailchimp",
    label: "MailChimp",
  },
  {
    id: 83,
    value: "mariadb",
    label: "MariaDb",
  },
  {
    id: 24,
    value: "material-design",
    label: "Material Design",
  },
  {
    id: 166,
    value: "material-ui",
    label: "Material UI",
  },
  {
    id: 29,
    value: "material-ui",
    label: "Material-UI",
  },
  {
    id: 57,
    value: "microservices-architecture",
    label: "Microservices Architecture",
  },
  {
    id: 199,
    value: "minitest",
    label: "Minitest",
  },
  {
    id: 35,
    value: "mobx",
    label: "Mobx",
  },
  {
    id: 175,
    value: "mocha",
    label: "Mocha",
  },
  {
    id: 99,
    value: "mongo-atlas-(mlab)",
    label: "Mongo Atlas (MLab)",
  },
  {
    id: 95,
    value: "mongodb",
    label: "MongoDB",
  },
  {
    id: 107,
    value: "ms-access",
    label: "MS Access",
  },
  {
    id: 94,
    value: "mysql",
    label: "MySql",
  },
  {
    id: 53,
    value: "nestjs",
    label: "NestJS",
  },
  {
    id: 291,
    value: "new",
    label: "New",
  },
  {
    id: 270,
    value: "new-backend",
    label: "New Backend",
  },
  {
    id: 299,
    value: "new-communications-skill",
    label: "New Communications Skill",
  },
  {
    id: 286,
    value: "new-skill",
    label: "New Skill",
  },
  {
    id: 298,
    value: "new-technology",
    label: "New Technology",
  },
  {
    id: 296,
    value: "new1",
    label: "New1",
  },
  {
    id: 127,
    value: "nginx",
    label: "Nginx",
  },
  {
    id: 254,
    value: "ngrx",
    label: "NgRx",
  },
  {
    id: 51,
    value: "node",
    label: "Node",
  },
  {
    id: 108,
    value: "nosql",
    label: "NoSQL",
  },
  {
    id: 191,
    value: "nunit",
    label: "NUnit",
  },
  {
    id: 67,
    value: "opencart/cs-cart",
    label: "Opencart/CS Cart",
  },
  {
    id: 164,
    value: "opengl-es-2.0",
    label: "OpenGL ES 2.0",
  },
  {
    id: 55,
    value: "oracle",
    label: "Oracle",
  },
  {
    id: 104,
    value: "oracle-12g",
    label: "Oracle 12g",
  },
  {
    id: 38,
    value: "photoshop",
    label: "Photoshop",
  },
  {
    id: 257,
    value: "php",
    label: "PHP",
  },
  {
    id: 182,
    value: "php-unit-tests",
    label: "PHP Unit Tests",
  },
  {
    id: 189,
    value: "phpunit",
    label: "PHPUnit",
  },
  {
    id: 101,
    value: "postgresql",
    label: "PostgreSQL",
  },
  {
    id: 237,
    value: "postman",
    label: "Postman",
  },
  {
    id: 239,
    value: "powerpoint",
    label: "Powerpoint",
  },
  {
    id: 19,
    value: "primeface",
    label: "Primeface",
  },
  {
    id: 267,
    value: "problem-solving",
    label: "Problem Solving",
  },
  {
    id: 226,
    value: "product-management",
    label: "Product Management",
  },
  {
    id: 268,
    value: "professional-attitude",
    label: "Professional Attitude",
  },
  {
    id: 228,
    value: "project-planning",
    label: "Project Planning",
  },
  {
    id: 140,
    value: "puppet",
    label: "Puppet",
  },
  {
    id: 188,
    value: "puppeteer",
    label: "Puppeteer",
  },
  {
    id: 78,
    value: "python",
    label: "Python",
  },
  {
    id: 229,
    value: "qa-management",
    label: "Qa Management",
  },
  {
    id: 131,
    value: "rabbitmq",
    label: "RabbitMQ",
  },
  {
    id: 8,
    value: "react",
    label: "React",
  },
  {
    id: 171,
    value: "react-mobile",
    label: "React Mobile",
  },
  {
    id: 258,
    value: "react-native",
    label: "React Native",
  },
  {
    id: 183,
    value: "react-testing-library",
    label: "React Testing Library",
  },
  {
    id: 98,
    value: "redis",
    label: "Redis",
  },
  {
    id: 27,
    value: "redux",
    label: "Redux",
  },
  {
    id: 33,
    value: "redux-saga",
    label: "Redux-Saga",
  },
  {
    id: 242,
    value: "reporting",
    label: "Reporting",
  },
  {
    id: 231,
    value: "requirements-analysis",
    label: "Requirements Analysis",
  },
  {
    id: 167,
    value: "responsive-design",
    label: "Responsive Design",
  },
  {
    id: 207,
    value: "rfvalue(active/passive),barcode",
    label: "RFvalue(Active/Passive),Barcode",
  },
  {
    id: 77,
    value: "routing-controllers",
    label: "Routing Controllers",
  },
  {
    id: 198,
    value: "rspec",
    label: "Rspec",
  },
  {
    id: 79,
    value: "ruby",
    label: "Ruby",
  },
  {
    id: 64,
    value: "ruby-on-rails",
    label: "Ruby On Rails",
  },
  {
    id: 28,
    value: "rxjs",
    label: "RxJS",
  },
  {
    id: 224,
    value: "scrum-certified",
    label: "Scrum Certified",
  },
  {
    id: 201,
    value: "selenium",
    label: "Selenium",
  },
  {
    id: 190,
    value: "selenium-webdriver",
    label: "Selenium-Webdriver",
  },
  {
    id: 161,
    value: "sencha",
    label: "Sencha",
  },
  {
    id: 259,
    value: "seo-advanced",
    label: "SEO Advanced",
  },
  {
    id: 236,
    value: "seo-optimization",
    label: "SEO Optimization",
  },
  {
    id: 206,
    value: "signalr",
    label: "SignalR",
  },
  {
    id: 37,
    value: "sketch",
    label: "Sketch",
  },
  {
    id: 264,
    value: "skill-nw",
    label: "Skill Nw",
  },
  {
    id: 274,
    value: "skills",
    label: "Skills",
  },
  {
    id: 234,
    value: "slack",
    label: "Slack",
  },
  {
    id: 72,
    value: "smarty",
    label: "Smarty",
  },
  {
    id: 96,
    value: "solr",
    label: "Solr",
  },
  {
    id: 46,
    value: "spring",
    label: "Spring",
  },
  {
    id: 54,
    value: "spring-/-spring-boot",
    label: "Spring / Spring Boot",
  },
  {
    id: 86,
    value: "spring-boot",
    label: "Spring Boot",
  },
  {
    id: 89,
    value: "spring-framework",
    label: "Spring Framework",
  },
  {
    id: 87,
    value: "spring-mvc",
    label: "Spring Mvc",
  },
  {
    id: 105,
    value: "sql-server",
    label: "SQL SERVER",
  },
  {
    id: 106,
    value: "sqlite",
    label: "SQLite",
  },
  {
    id: 287,
    value: "ssa",
    label: "Ssa",
  },
  {
    id: 256,
    value: "ssr---server-svaluee-rendering",
    label: "SSR - Server Svaluee Rendering",
  },
  {
    id: 149,
    value: "storage-server",
    label: "Storage Server",
  },
  {
    id: 34,
    value: "storybook",
    label: "Storybook",
  },
  {
    id: 48,
    value: "struts-2",
    label: "Struts 2",
  },
  {
    id: 152,
    value: "subversion",
    label: "Subversion",
  },
  {
    id: 137,
    value: "svn",
    label: "SVN",
  },
  {
    id: 192,
    value: "swagger.io",
    label: "Swagger.io",
  },
  {
    id: 44,
    value: "swift",
    label: "Swift",
  },
  {
    id: 74,
    value: "symfony",
    label: "Symfony",
  },
  {
    id: 136,
    value: "taiga.io",
    label: "Taiga.io",
  },
  {
    id: 85,
    value: "tcp/udp",
    label: "TCP/UDP",
  },
  {
    id: 151,
    value: "teamcity",
    label: "TeamCity",
  },
  {
    id: 263,
    value: "tech",
    label: "Tech",
  },
  {
    id: 124,
    value: "terraform",
    label: "Terraform",
  },
  {
    id: 265,
    value: "test-skill",
    label: "Test Skill",
  },
  {
    id: 186,
    value: "testcomplete",
    label: "TestComplete",
  },
  {
    id: 233,
    value: "testing",
    label: "Testing",
  },
  {
    id: 153,
    value: "tfs",
    label: "TFS",
  },
  {
    id: 5,
    value: "thymeleaf",
    label: "Thymeleaf",
  },
  {
    id: 111,
    value: "tomcat",
    label: "Tomcat",
  },
  {
    id: 203,
    value: "trello",
    label: "Trello",
  },
  {
    id: 174,
    value: "ttd",
    label: "TTD",
  },
  {
    id: 248,
    value: "twitter",
    label: "Twitter",
  },
  {
    id: 12,
    value: "typescript",
    label: "TypeScript",
  },
  {
    id: 17,
    value: "uikit",
    label: "UIKit",
  },
  {
    id: 195,
    value: "unit-test-scripting",
    label: "Unit Test Scripting",
  },
  {
    id: 176,
    value: "unit-testing",
    label: "Unit Testing",
  },
  {
    id: 155,
    value: "vagrant",
    label: "Vagrant",
  },
  {
    id: 132,
    value: "vercel",
    label: "Vercel",
  },
  {
    id: 210,
    value: "visual-studio",
    label: "Visual Studio",
  },
  {
    id: 185,
    value: "visual-studio-unit-testing",
    label: "Visual Studio Unit Testing",
  },
  {
    id: 181,
    value: "vue-test-utils",
    label: "Vue Test Utils",
  },
  {
    id: 23,
    value: "vuejs",
    label: "VueJs",
  },
  {
    id: 260,
    value: "wdww",
    label: "Wdww",
  },
  {
    id: 112,
    value: "weblogic",
    label: "Weblogic",
  },
  {
    id: 31,
    value: "webpack",
    label: "Webpack",
  },
  {
    id: 205,
    value: "webrtc",
    label: "WebRTC",
  },
  {
    id: 232,
    value: "wireframing",
    label: "Wireframing",
  },
  {
    id: 58,
    value: "wordpress",
    label: "Wordpress",
  },
  {
    id: 134,
    value: "wp-engine",
    label: "WP Engine",
  },
  {
    id: 217,
    value: "xml",
    label: "XML",
  },
  {
    id: 21,
    value: "xml/xsl",
    label: "XML/XSL",
  },
  {
    id: 62,
    value: "yii2",
    label: "Yii2",
  },
  {
    id: 247,
    value: "youtube-marketing",
    label: "YouTube Marketing",
  },
];

export default skillOption;
