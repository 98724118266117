import { Button } from "@material-tailwind/react";
import cx from "classnames";
import { ReactNode } from "react";
interface BtnProps {
  className?: string;
  children?: ReactNode;
  onClick?: () => void;
  size?: "md" | "sm" | "lg";
  type?: "button" | "reset" | "submit";
  fullWidth?: boolean;
  variant?: "text" | "filled" | "outlined" | "gradient";
  disabled?: boolean;
}
const Btn = ({
  children,
  className,
  onClick,
  size = "md",
  type = "button",
  fullWidth = false,
  variant = "filled",
  disabled = false,
}: BtnProps) => {
  return (
    <Button
      className={cx(
        "rounded-full w-fit border-primary normal-case flex items-center gap-2 justify-center",
        variant == "filled" && "min-w-[228px]  mt-14  py-6 bg-primary",
        variant == "outlined" && " bg-transparent",
        size == "sm" && "py-2 mt-0 min-w-fit",
        className
      )}
      variant={variant}
      size={size}
      type={type}
      onClick={(e) => {
        if (onClick) {
          e.preventDefault();
          onClick();
        }
      }}
      fullWidth={fullWidth}
      disabled={disabled}
    >
      {children}
    </Button>
  );
};

export default Btn;
