import HeroBannerCaseStudy from "./HeroBannerCaseStudy";
import HeroBannerFull from "./HeroBannerFull";
import HeroBannerHalf from "./HeroBannerHalf";
import HeroBannerHalfWithCard from "./HeroBannerHalfWithCard";
import { ReactNode } from "react";
interface HeroBannerProps {
  heroType?: "full" | "half" | "card" | "casestudy";
  heroImage?: string;
  heroImageAlt?: string;
  heroImageBGColor?: string;
  heroTitle?: string;
  heroDesc?: string;
  buttonText?: string;
  buttonLinkUrl?: string;
  cssClass?: string;
  children?: ReactNode;
  textColor?: string;
}
const HeroBanner = ({
  heroType,
  heroImage,
  heroImageAlt,
  heroImageBGColor,
  heroTitle,
  heroDesc,
  buttonText,
  buttonLinkUrl,
  cssClass,
  children,
  textColor,
}: HeroBannerProps) => {
  switch (heroType) {
    case "full":
      return (
        <HeroBannerFull
          heroTitle={heroTitle ? heroTitle : ""}
          heroDesc={heroDesc ? heroDesc : ""}
          buttonText={buttonText ? buttonText : ""}
          buttonLinkUrl={buttonLinkUrl ? buttonLinkUrl : ""}
          cssClass={cssClass ? cssClass : ""}
        />
      );
    case "half":
      return (
        <HeroBannerHalf
          heroImage={heroImage ? heroImage : ""}
          heroImageAlt={heroImageAlt ? heroImageAlt : ""}
          heroTitle={heroTitle ? heroTitle : ""}
          heroDesc={heroDesc ? heroDesc : ""}
          buttonText={buttonText ? buttonText : ""}
          buttonLinkUrl={buttonLinkUrl ? buttonLinkUrl : ""}
          cssClass={cssClass ? cssClass : ""}
          heroImageBGColor={heroImageBGColor ? heroImageBGColor : ""}
        />
      );
    case "card":
      return (
        <HeroBannerHalfWithCard
          heroImage={heroImage ? heroImage : ""}
          heroImageAlt={heroImageAlt ? heroImageAlt : ""}
          heroTitle={heroTitle ? heroTitle : ""}
          heroDesc={heroDesc ? heroDesc : ""}
          buttonText={buttonText ? buttonText : ""}
          buttonLinkUrl={buttonLinkUrl ? buttonLinkUrl : ""}
          cssClass={cssClass ? cssClass : ""}
          heroImageBGColor={heroImageBGColor ? heroImageBGColor : ""}
          children={children ? children : ""}
        />
      );
    case "casestudy":
      return (
        <HeroBannerCaseStudy
          heroImage={heroImage ? heroImage : ""}
          heroImageAlt={heroImageAlt ? heroImageAlt : ""}
          heroTitle={heroTitle ? heroTitle : ""}
          heroDesc={heroDesc ? heroDesc : ""}
          buttonText={buttonText ? buttonText : ""}
          buttonLinkUrl={buttonLinkUrl ? buttonLinkUrl : ""}
          cssClass={cssClass ? cssClass : ""}
          heroImageBGColor={heroImageBGColor ? heroImageBGColor : ""}
          textColor={textColor ? textColor : "#ffffff"}
        />
      );
    default:
      return null;
  }
};

export default HeroBanner;
