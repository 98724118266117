import { EnvelopeIcon, MapPinIcon, PhoneIcon } from "@heroicons/react/24/outline";
import Btn from "../../components/Btn";
import Headings from "../../components/Headings";
import Img from "../../components/Image";

const ThankYou = () => {
  return (
    <div className="flex min-h-screen transition-all">
      <div className="lg:w-8/12 bg-light h-100 xl:p-32 flex w-full p-8">
        <div className="w-full my-auto">
          <a href="/">
            <Img
              cssClass="h-[88px] w-[88px] mb-14  rounded-full"
              imgSrc="logo.svg"
              imgAlt="+OneX"
            />
          </a>
          <Headings
            headType="h1"
            titleText="Thank you"
            className="xl:text-4xl mb-5 text-2xl font-medium"
          />
          <p>
            One of our consultants will be in touch shortly with profiles that match your needs.
          </p>
          <a href="/">
            <Btn>Back to Home</Btn>
          </a>
        </div>
      </div>
      <div className="lg:w-4/12 bg-primary h-100 flex flex-col w-full p-10 text-white">
        <div className="flex flex-col my-auto">
          <Headings
            headType="h2"
            titleText={"Contact Information"}
            className="xl:text-2xl  mb-5 text-xl"
          />
          <p>Fill out the form and our team will get back to you shortly.</p>
          <div className="grid my-10 space-y-10">
            <div className="flex items-center gap-4 text-lg font-medium">
              <PhoneIcon className="flex-none w-5 h-5" />
              <a href="tel:+ (021) 461 68 17">+ (021) 461 68 17</a>
            </div>
            <div className="flex items-center gap-4 text-lg font-medium">
              <EnvelopeIcon className="flex-none w-5 h-5" />
              <a href="mailto:hello@plusonex.com">hello@plusonex.com</a>
            </div>
            <div className="flex gap-4 text-lg font-medium">
              <MapPinIcon className="flex-none w-5 h-5" />
              <address>
                1st Floor, Landsdowne Building, Office F01A, Brookside Office Park, 11 Imam Haron
                Rd, Claremont, Cape Town, 7708
              </address>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ThankYou;
