import Headings from "../../components/Headings";
import HeroBanner from "../../components/HeroBanner";
import Img from "../../components/Image";

const servicesData = [
  {
    id: 1,
    title: "Mobile apps",
    desc: "Be in your customers’ pocket, iOS or Android. A mobile app is a valuable tool and a 1-on-1 channel to build a relationship with customers.",
    slug: "/mobile-app-development",
    imgSrc: "mobile-app-thumb.jpg",
  },
  {
    id: 2,
    title: "Web apps",
    desc: "We build entire backend and frontend systems from scratch, all the while keeping in mind scalability and performance.",
    slug: "/web-app-development",
    imgSrc: "web-app-thumb.jpg",
  },
  {
    id: 3,
    title: "Product Design",
    desc: "It’s not perfect until we break it a few times. Ensuring the highest standards, we track quality control metrics and conduct extensive usability reviews and tests.",
    slug: "/product-desing",
    imgSrc: "product-design-thumb.jpg",
  },
  {
    id: 4,
    title: "Bespoke Software",
    desc: "It’s not perfect until we break it a few times. Ensuring the highest standards, we track quality control metrics and conduct extensive usability reviews and tests.",
    slug: "/bespoke-software-development",
    imgSrc: "bespoke-software-dev-thumb.jpg",
  },
  {
    id: 5,
    title: "eCommerce",
    desc: "Choosing the appropriate infrastructure for a digital project is equally important as design and engineering. We offer solutions that are scalable and secure.",
    slug: "/e-commerce",
    imgSrc: "ecommerce-thumb.jpg",
  },
  {
    id: 6,
    title: "Software Support",
    desc: "It’s not perfect until we break it a few times. Ensuring the highest standards, we track quality control metrics and conduct extensive usability reviews and tests.",
    slug: "/software-support",
    imgSrc: "software-development-thumb.jpg",
  },
  {
    id: 7,
    title: "Quality Assurance",
    desc: "It’s not perfect until we break it a few times. Ensuring the highest standards, we track quality control metrics and conduct extensive usability reviews and tests.",
    slug: "/quality-assurance",
    imgSrc: "qa-thumb.jpg",
  },
  {
    id: 8,
    title: "Cloud development ",
    desc: "It’s not perfect until we break it a few times. Ensuring the highest standards, we track quality control metrics and conduct extensive usability reviews and tests.",
    slug: "/cloud-software-development",
    imgSrc: "cloud-service-thumb.jpg",
  },
];

const BuildService = () => {
  return (
    <div>
      <HeroBanner
        heroType="full"
        heroTitle={"Building secure, scalable, and fast digital products"}
        heroDesc={
          "Our team's expertise spans across diverse industries, enabling us to create mobile apps finely tuned to your sector's unique demands. From concept to execution, we work closely with you to understand your business goals, challenges, and opportunities. This collaborative approach ensures that the solutions we develop are not just technology-driven but aligned with your strategic objectives."
        }
      />
      <div className="page-section">
        <div className="container">
          <div className="flex xl:flex-nowrap flex-wrap gap-14">
            <div className="w-full xl:w-3/12">
              <Headings
                headType="h2"
                titleText="Software, people, Platforms & more"
                className="text-3xl font-bold mb-6"
              />
              <div className="text-xl">
                <p>Digital products need to be robust and able to handle high traffic scenarios.</p>

                <p>We’ve accomplished that in 2500+ products shipped–here’s how.</p>
              </div>
            </div>
            <div className="w-full xl:w-9/12">
              <div className="grid grid-cols-1 xl:grid-cols-2 gap-x-12 gap-y-16">
                {servicesData.map((ele, i) => {
                  return (
                    <a href={ele.slug} key={i}>
                      <Img imgSrc={ele.imgSrc} imgAlt={ele.title} />
                      <div className="p-6">
                        <Headings
                          headType="h3"
                          titleText={ele.title}
                          className="text-3xl font-bold mb-4"
                        />
                        <div>{ele.desc}</div>
                      </div>
                    </a>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BuildService;
