import { Card, CardBody } from "@material-tailwind/react";
import FeaturesBlock from "../../components/FeaturesBlock";
import Headings from "../../components/Headings";
import HeroBanner from "../../components/HeroBanner";
import Img from "../../components/Image";
import ServiceRepeater from "../../components/ServiceRepeater";
import SliderBox from "../../components/SliderBox";
import AccordionBox from "../../components/AccordionBox";

const featureData = [
  {
    id: 1,
    imgSrc: "icon-design.svg",
    title: "Brand Strategy",
    desc: "Let us lead you through the branding journey and assist you in etching your identity indelibly into your customers' minds. Whether you're beginning from the ground up or seeking a rejuvenated image, we collaborate with you to uncover your company's core values and pinpoint what sets your business apart. Our aim is to effectively convey these unique qualities to your valued customers.",
  },
  {
    id: 2,
    imgSrc: "icon-webapp.svg",
    title: "Shopify UX & UI Design",
    desc: "The essence of exceptional user experience design (UX) lies in intuitively fulfilling the customer's precise needs without necessitating conscious thought on their part. At Dewsign, our extensive UX expertise underscores our profound understanding of the paramount significance of a superior user experience. This understanding serves as the cornerstone for all our functional specifications and design procedures.",
  },
  {
    id: 3,
    imgSrc: "icon-mobileapp.svg",
    title: "Shopify Development",
    desc: "Allow us to help your business swiftly and seamlessly achieve its eCommerce goals. Our team of skilled Shopify developers can seamlessly integrate custom features into your Shopify platform. Whether you require tailored Shopify themes or personalized Shopify apps, we have the expertise to deliver. Our services encompass comprehensive Shopify installation, configuration, and integrations designed for sustainable growth, enabling you and your store to thrive in the long run.",
  },
  {
    id: 4,
    imgSrc: "icon-ecommerce.svg",
    title: "Custom Shopify Apps",
    desc: "Shopify offers an array of pre-built apps readily available for your use. However, when your requirements extend beyond the capabilities of standard Shopify apps, Dewsign is here to provide tailored Shopify apps or add-ons that precisely address your needs. Our specialization lies in crafting, installing, and seamlessly integrating apps for Shopify online stores, ensuring your e-commerce platform aligns perfectly with your unique business demands.",
  },
  {
    id: 5,
    imgSrc: "icon-qa.svg",
    title: "Shopify Sales Funnel Automation",
    desc: "Crafting Shopify sales funnel automations through cutting-edge technologies empowers you to drive sales efficiently, minimize expenditure, and maximize revenue potential from both new and returning customers. Our approach involves skillfully merging various platforms, Shopify apps, and occasionally custom apps to engineer automated funnels spanning multiple channels. The result? Heightened sales figures and an improved bottom line for your business.",
  },
  {
    id: 6,
    imgSrc: "icon-devops.svg",
    title: "Your Shopify Agency for support & growth every month.",
    desc: "Our expertise extends beyond the mere launch of Shopify and Shopify Plus themes, features, apps, and integrations. We're committed to supporting merchants and clients through our memberships, offering invaluable assistance, support, insights, and fostering growth within your business.",
  },
];
const faqsData = [
  {
    id: 1,
    title: "Ease of Use",
    desc: "Shopify is renowned for its user-friendly interface. You don't need extensive technical expertise to set up and manage your online store.",
  },
  {
    id: 2,
    title: "More points",
    desc: "Shopify is renowned for its user-friendly interface. You don't need extensive technical expertise to set up and manage your online store.",
  },
  {
    id: 3,
    title: "More points",
    desc: "Shopify is renowned for its user-friendly interface. You don't need extensive technical expertise to set up and manage your online store.",
  },
  {
    id: 4,
    title: "More points",
    desc: "Shopify is renowned for its user-friendly interface. You don't need extensive technical expertise to set up and manage your online store.",
  },
];
const Landing = () => {
  return (
    <div>
      <section className="page-section">
        <div className="container">
          <h2 className="xl:text-6xl xl:w-2/3 flex flex-wrap items-center justify-center gap-2 mx-auto mb-16 text-5xl font-medium text-center">
            Trusted eCommerce &
            <Img imgSrc="shopify-logo.png" imgAlt="shopify" cssClass="h-16" />
            Agency.
          </h2>
          <div className="xl:w-4/5 mx-auto text-center">
            Located in the vibrant city of Cape Town, South Africa,{" "}
            <strong>+OneX is a well-established and trusted agency specializing in Shopify.</strong>{" "}
            We collaborate with brands to conceive, create, launch, and fine-tune Shopify websites
            with a singular mission—spurring growth. Whether you're making the leap to Shopify or{" "}
            <strong>Shepify Plus</strong> our seasoned expertise is here to guide merchants like you
            in harnessing its boundless potential.
          </div>
        </div>
      </section>
      <HeroBanner
        heroType="card"
        heroTitle={"Award winning Shopify experts"}
        heroDesc={
          "We craft Shopify and Shopify Plus stores that offer eCommerce experiences uniquely captivating and thoughtfully tailored for commercial success."
        }
        buttonText={"Find out more"}
        buttonLinkUrl={"/join-us#apply"}
        heroImage={"shopify-banner.jpg"}
        heroImageAlt={"Join us"}
        heroImageBGColor={"bg-[#5C5D73]"}
      >
        <div className="text-center">
          <Headings
            headType="h2"
            titleText="Some of the brands we have worked with"
            className={"xl:text-6xl text-5xl font-medium mb-14 xl:mb-16"}
          />

          <div className="xl:grid-cols-4 gap-y-8 gap-x-28 grid grid-cols-1">
            <Img imgSrc="logo-flash.png" imgAlt="EY" />
            <Img imgSrc="logo-motorhappy.png" imgAlt="EY" />
            <Img imgSrc="logo-nedbank.png" imgAlt="EY" />
            <Img imgSrc="logo-coricraft.png" imgAlt="EY" />
          </div>
        </div>
      </HeroBanner>
      <FeaturesBlock
        brandLogo="../shopify-logo.png"
        featureTitle={"eCommerce services"}
        buttonText={"Let’s Get Started"}
        buttonLinkUrl="/bespoke-software-development"
      >
        <ServiceRepeater dataList={featureData} itemPerRow={1} />
      </FeaturesBlock>
      <section className="page-section bg-light">
        <div className="container">
          <Headings
            headType={"h2"}
            titleText={"Recent work "}
            className={"xl:text-6xl text-5xl font-medium"}
          />

          <SliderBox />
        </div>
      </section>
      <section className="page-section">
        <div className="container">
          <div className="xl:flex-nowrap gap-14 xl:w-10/12 flex flex-wrap mx-auto">
            <div className="xl:w-3/12 w-full">
              <Img imgSrc="craig.jpg" imgAlt="Craig Schneeberger" cssClass="rounded-full" />
            </div>
            <div className="xl:w-9/12 w-full">
              <div className="xl:text-5xl mb-6 text-3xl leading-tight">
                "Working with +Onex was an absolute dream, we really felt they had the time for us
                and the whole project was seamless start to finish.”
              </div>
              <div className="xl:text-xl text-lg font-bold">Craig Schneeberger</div>
              <div className="xl:text-xl text-lg">Coricraft</div>
            </div>
          </div>
        </div>
      </section>
      <section className="page-section bg-light">
        <div className="container">
          <div className="xl:text-6xl xl:w-2/3 flex flex-wrap items-center justify-center gap-2 mx-auto mb-5 text-5xl font-medium text-center">
            Our
            <Img imgSrc="shopify-logo.png" imgAlt="shopify" cssClass="h-16" />
            whitepapers.
          </div>
          <div className="xl:w-4/5 mx-auto mb-16 text-lg text-center">
            Our team possesses a wealth of expertise in developing software across diverse
            industries.
          </div>
          <div className="xl:grid-cols-3 lg:grid-cols-2 xl:gap-20 grid grid-cols-1 gap-10">
            <Card className="shadow-none">
              <CardBody>
                <a href="/" className="hover:underline block p-2">
                  <Img imgSrc="shopify1.jpg" imgAlt="shopify" />
                  <div className="mt-4 text-3xl font-medium">
                    Why choose headless ecommerce on Shopify?
                  </div>
                </a>
              </CardBody>
            </Card>
            <Card className="shadow-none">
              <CardBody>
                <a href="/" className="hover:underline block p-2">
                  <Img imgSrc="shopify2.jpg" imgAlt="shopify" />
                  <div className="mt-4 text-3xl font-medium">
                    Looking to expand your Shopify store internationally?
                  </div>
                </a>
              </CardBody>
            </Card>
            <Card className="shadow-none">
              <CardBody>
                <a href="/" className="hover:underline block p-2">
                  <Img imgSrc="shopify3.jpg" imgAlt="shopify" />
                  <div className="mt-4 text-3xl font-medium">
                    We design shopify stores to engage & conver customers.
                  </div>
                </a>
              </CardBody>
            </Card>
            <Card className="shadow-none">
              <CardBody>
                <a href="/" className="hover:underline block p-2">
                  <Img imgSrc="shopify4.jpg" imgAlt="shopify" />
                  <div className="mt-4 text-3xl font-medium">
                    Custom Shopify Theme Development & Tweaks
                  </div>
                </a>
              </CardBody>
            </Card>
            <Card className="shadow-none">
              <CardBody>
                <a href="/" className="hover:underline block p-2">
                  <Img imgSrc="shopify5.jpg" imgAlt="shopify" />
                  <div className="mt-4 text-3xl font-medium">
                    Looking to expand your Shopify store internationally?
                  </div>
                </a>
              </CardBody>
            </Card>
            <Card className="shadow-none">
              <CardBody>
                <a href="/" className="hover:underline block p-2">
                  <Img imgSrc="shopify6.jpg" imgAlt="shopify" />
                  <div className="mt-4 text-3xl font-medium">
                    Data-driven strategies for Shopify & Shopify Plus.
                  </div>
                </a>
              </CardBody>
            </Card>
          </div>
        </div>
      </section>
      <section className="page-section">
        <div className="container">
          <div className="mt-7 xl:gap-28 xl:flex-nowrap flex flex-wrap gap-8">
            <div className="xl:order-2 xl:w-1/2 xl:text-right xl:pl-8 w-full text-center">
              <Img imgSrc="faq-shopify.png" imgAlt="faq" />
            </div>
            <div className="xl:w-1/2 mt-7 w-full">
              <Headings
                headType={"h2"}
                titleText={"Why shopify for my business?"}
                className={"xl:text-6xl text-5xl font-medium mb-5"}
              />
              <div className="mb-5">
                Shopify stands as a robust e-commerce platform that seamlessly blends user-
                friendliness with formidable capabilities. It emerges as a standout choice for
                businesses of all scales seeking to establish a thriving online presence. Shopify
                not only saves you valuable time but also optimizes your operational efficiency,
                ultimately enabling you to provide an exceptional shopping experience to your
                cherished customers.
              </div>
              <AccordionBox dataList={faqsData} />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Landing;
