import {
  Card,
  CardBody,
  Dialog,
  DialogBody,
  IconButton,
  Input,
  Textarea,
} from "@material-tailwind/react";
import Img from "../Image";
import { XMarkIcon } from "@heroicons/react/24/outline";
import Headings from "../Headings";
import { useEffect, useState } from "react";
import Btn from "../Btn";
import cx from "classnames";
import ContactModal from "../../pages/ContactModal";
interface FooterProps {
  landingFooter?: boolean;
}

const footerLinks = [
  {
    id: 1,
    heading: "For Businesses",
    slug: null,
    children: [
      {
        id: 1,
        title: "Agile teams for hire",
        slug: "/scale-your-team",
      },
      {
        id: 2,
        title: "Find talent",
        slug: "/find-talent",
      },
    ],
  },
  {
    id: 2,
    heading: "Company",
    slug: null,
    children: [
      {
        id: 1,
        title: "About us",
        slug: "/company",
      },
      {
        id: 2,
        title: "Case studies",
        slug: "/case-studies",
      },
    ],
  },
  {
    id: 3,
    heading: "Talent",
    slug: null,
    children: [
      {
        id: 1,
        title: "Careers",
        slug: "/join-us",
      },
    ],
  },
  {
    id: 4,
    heading: "Solutions",
    slug: null,
    children: [
      {
        id: 1,
        title: "Mobile Apps",
        slug: "/mobile-app-development",
      },
      {
        id: 2,
        title: "Web Apps",
        slug: "/web-app-development",
      },
      {
        id: 3,
        title: "Product Design (Ux, Ui)",
        slug: "/product-design",
      },
      {
        id: 4,
        title: "eCommerce",
        slug: "/e-commerce",
      },
      {
        id: 5,
        title: "Bespoke Software development",
        slug: "/bespoke-software-development",
      },
      {
        id: 6,
        title: "Software support",
        slug: "/software-support",
      },
      {
        id: 7,
        title: "Qaulity Assurance",
        slug: "/quality-assurance",
      },
      {
        id: 8,
        title: "Cloud Development",
        slug: "/cloud-software-development",
      },
    ],
  },
  {
    id: 4,
    heading: "Contact",
    slug: null,
    children: [
      {
        id: 1,
        title: "Start a project",
        slug: "/hire-developers",
      },
      // {
      //   id: 2,
      //   title: "Contact us",
      //   slug: "/join-us",
      // },
    ],
  },
];

const Footer = ({ landingFooter }: FooterProps) => {
  const [isOpenPopup, setIsOpenPopup] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const landingFooterState = landingFooter;
  const togglePopup = () => {
    setIsOpenPopup((isOpenPopup) => !isOpenPopup);
  };
  const handleOpenModal = () => {
    setIsOpenPopup(false);
    setOpenModal(!openModal);
  };
  useEffect(() => {
    const timer = setTimeout(() => {
      console.log("This will run after 1 second!");
      setIsOpenPopup(true);
    }, 90000);
    return () => clearTimeout(timer);
  }, []);
  return (
    <>
      {!landingFooterState && (
        <div className="bottom-4 right-6 fixed">
          {/* {!isOpenPopup && (
            <IconButton className="rounded-full" onClick={togglePopup}>
              <EnvelopeIcon className="w-6 h-6" />
            </IconButton>
          )} */}

          <Card
            className={cx(
              "w-72 transition-all duration-500 shadow-lg ",
              isOpenPopup ? "right-0" : "-right-[calc(100%+2rem)]"
            )}
          >
            <CardBody>
              <div className="relative mb-5 -mt-10 -mr-10 text-right">
                <IconButton className="rounded-full" size="sm" onClick={togglePopup}>
                  <XMarkIcon className="w-4 h-4" />
                </IconButton>
              </div>

              <Headings
                headType="h3"
                titleText="Please call me"
                className="mb-3 text-lg font-semibold"
              />
              <div className="mb-4 text-sm">
                Share Your Information Below, and We'll Reach Out Without Delay
              </div>
              <div>
                <Btn onClick={handleOpenModal} className="py-3  min-w-[180px] mx-auto" size="sm">
                  Contact us
                </Btn>
              </div>
            </CardBody>
          </Card>
        </div>
      )}
      <footer className="bg-light">
        <div className="page-section ">
          {landingFooterState == false ? (
            <div className="xl:px-10 container">
              <div className="xl:gap-28 xl:flex-nowrap flex flex-wrap gap-10">
                <div className="xl:w-5/12">
                  <Img imgSrc="logo.svg" imgAlt="+OneX" cssClass="mb-10 h-32 w-32 rounded-full" />
                  <p>
                    We specialize in crafting personalized digital experiences that tangibly enhance
                    your business's value proposition. With our guidance, you'll confidently
                    navigate the path to your next phase of growth while simultaneously nurturing
                    your team's skills and potential.
                  </p>
                </div>
                <div className="xl:w-7/12 xl:gap-y-24 xl:gap-x-12 xl:grid-cols-3 grid gap-10">
                  {footerLinks.map((ele, i) => {
                    return (
                      <ul key={i} className="leading-7">
                        <li>
                          {ele.slug ? (
                            <a href={ele.slug} className="font-bold">
                              {ele.heading}
                            </a>
                          ) : (
                            <div className="font-bold">{ele.heading}</div>
                          )}
                          {ele.children.length > 0 && (
                            <ul>
                              {ele.children.map((child, i) => {
                                return (
                                  <li key={i}>
                                    <a href={child.slug} className="hover:text-gray-700">
                                      {child.title}
                                    </a>
                                  </li>
                                );
                              })}
                            </ul>
                          )}
                        </li>
                      </ul>
                    );
                  })}
                </div>
              </div>
            </div>
          ) : (
            <div className="xl:px-10 container">
              <div className="xl:gap-28 xl:flex-nowrap flex flex-wrap items-center justify-between gap-8">
                <div className="xl:w-4/12 w-full">
                  <Img imgSrc="logo.svg" imgAlt="+OneX" cssClass="rounded-full h-32 w-32 mb-16" />
                  <Headings
                    headType="h2"
                    titleText="Let's talk ecommerce, Shopify & Shopify Plus solutions."
                    className="mb-8 text-4xl"
                  />
                  <p>
                    We are an accredited expert Shopify agency we partner with brands to design,
                    develop, launch, support and grow Shopify & Shopify Plus stores. We are a
                    Shopify agency, ready when you are. Talk to our team about your project.
                  </p>
                </div>
                <div className="xl:w-6/12 w-full">
                  <Card className="shadow-lg">
                    <CardBody className="p-10">
                      <Headings
                        headType="h3"
                        titleText="lets go"
                        className="mb-5 text-4xl font-bold"
                      />
                      <p>Share Your Information Below, and We'll Reach Out Without Delay</p>
                      <form>
                        <div className="grid grid-cols-3 gap-5">
                          <div>
                            <label>Name</label>
                          </div>
                          <div className="col-span-2">
                            <Input
                              type="text"
                              className="!border rounded-full !border-gray-300 bg-white text-gray-900 ring-4 ring-transparent placeholder:text-gray-500 focus:!border-gray-900 focus:!border-t-gray-900 focus:ring-gray-900/10"
                              labelProps={{
                                className: "hidden",
                              }}
                              containerProps={{ className: "min-w-[100px]" }}
                              required
                              crossOrigin={undefined}
                            />
                          </div>
                          <div>
                            <label>Company Name</label>
                          </div>
                          <div className="col-span-2">
                            <Input
                              type="text"
                              className="!border rounded-full !border-gray-300 bg-white text-gray-900 ring-4 ring-transparent placeholder:text-gray-500 focus:!border-gray-900 focus:!border-t-gray-900 focus:ring-gray-900/10"
                              labelProps={{
                                className: "hidden",
                              }}
                              containerProps={{ className: "min-w-[100px]" }}
                              required
                              crossOrigin={undefined}
                            />
                          </div>
                          <div>
                            <label>Tel Number</label>
                          </div>
                          <div className="col-span-2">
                            <Input
                              type="text"
                              className="!border rounded-full !border-gray-300 bg-white text-gray-900 ring-4 ring-transparent placeholder:text-gray-500 focus:!border-gray-900 focus:!border-t-gray-900 focus:ring-gray-900/10"
                              labelProps={{
                                className: "hidden",
                              }}
                              containerProps={{ className: "min-w-[100px]" }}
                              required
                              crossOrigin={undefined}
                            />
                          </div>
                          <div>
                            <label>Email address</label>
                          </div>
                          <div className="col-span-2">
                            <Input
                              type="email"
                              className="!border rounded-full !border-gray-300 bg-white text-gray-900 ring-4 ring-transparent placeholder:text-gray-500 focus:!border-gray-900 focus:!border-t-gray-900 focus:ring-gray-900/10"
                              labelProps={{
                                className: "hidden",
                              }}
                              containerProps={{ className: "min-w-[100px]" }}
                              required
                              crossOrigin={undefined}
                            />
                          </div>
                          <div>
                            <label>Message</label>
                          </div>
                          <div className="col-span-2">
                            <Textarea
                              className="!border rounded-xl !border-gray-300 bg-white text-gray-900 ring-4 ring-transparent placeholder:text-gray-500 focus:!border-gray-900 focus:!border-t-gray-900 focus:ring-gray-900/10"
                              labelProps={{
                                className: "hidden",
                              }}
                              containerProps={{ className: "min-w-[100px]" }}
                            />
                          </div>
                          <div>&nbsp;</div>
                          <div className="col-span-2">
                            <Btn type="submit" className="py-4">
                              Contact us
                            </Btn>
                          </div>
                        </div>
                      </form>
                    </CardBody>
                  </Card>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="py-8 text-white bg-black">
          <div className="container">
            <div className="xl:px-10 font-bold">2020 © All rights reserved. +Onex Pty Ltd.</div>
          </div>
        </div>
      </footer>

      <Dialog open={openModal} handler={handleOpenModal} size="xl">
        <DialogBody className="max-h-[94vh] p-0 overflow-y-auto">
          <ContactModal />
        </DialogBody>
      </Dialog>
    </>
  );
};

export default Footer;
