import { useMediaQuery } from "react-responsive";

const MOBILE_BREAKPOINT = 767;
const TABLET_BREAKPOINT = 1023;
const SMALL_BREAKPOINT = 1399;
const LARGE_BREAKPOINT = 1536;

export default function useResponsive() {
  const isLarge = useMediaQuery({ minWidth: LARGE_BREAKPOINT });
  const isSmallDesktop = useMediaQuery({ minWidth: SMALL_BREAKPOINT });
  const isTablet = useMediaQuery({ maxWidth: TABLET_BREAKPOINT });
  const isMobile = useMediaQuery({ maxWidth: MOBILE_BREAKPOINT });

  return { isMobile, isTablet, isSmallDesktop, isLarge };
}
