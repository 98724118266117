import cx from "classnames";

interface HeadingsProps {
  headType?: string;
  titleText?: string;
  className?: string;
}
const Headings = ({ headType, titleText, className }: HeadingsProps) => {
  // console.log(headType);
  switch (headType) {
    case "h1":
      return (
        <h1
          className={cx("!leading-[1.15]", className)}
          dangerouslySetInnerHTML={{ __html: titleText || "" }}
        ></h1>
      );
    case "h2":
      return (
        <h2
          className={cx("!leading-[1.15]", className)}
          dangerouslySetInnerHTML={{ __html: titleText || "" }}
        ></h2>
      );
    case "h3":
      return (
        <h3
          className={cx("!leading-[1.15]", className)}
          dangerouslySetInnerHTML={{ __html: titleText || "" }}
        ></h3>
      );
    case "h4":
      return (
        <h4
          className={cx("!leading-[1.15]", className)}
          dangerouslySetInnerHTML={{ __html: titleText || "" }}
        ></h4>
      );
    case "h5":
      return (
        <h5
          className={cx("!leading-[1.15]", className)}
          dangerouslySetInnerHTML={{ __html: titleText || "" }}
        ></h5>
      );
    case "h6":
      return (
        <h6
          className={cx("!leading-[1.15]", className)}
          dangerouslySetInnerHTML={{ __html: titleText || "" }}
        ></h6>
      );
    default:
      return null;
  }
};

export default Headings;
