import { Button, Carousel } from "@material-tailwind/react";
import Img from "../Image";

import { useEffect, useState } from "react";
import CONSTANT from "../../services/constant";
import { handler } from "../../services";
import Headings from "../Headings";

const SliderBox = () => {
  const [caseStudyData, setCaseStudyData] = useState(null);
  useEffect(() => {
    async function getCaseStudy() {
      const url = CONSTANT.BASE_URL + CONSTANT.GET_CASE_STUDY;
      const result = await handler.GetNew(url);
      let data = null;
      let error = "";
      if (result.message && (result.message.indexOf("403") || result.message.indexOf("401"))) {
        error = result.message;
        console.log(error);
      } else {
        data = JSON.parse(JSON.stringify(result.data.data));
        setCaseStudyData(data);
      }
      return {
        props: {
          error: error || null,
          data: data || null,
        },
      };
    }
    getCaseStudy();
  }, []);

  return (
    <div className="xl:mt-0 mt-8">
      {caseStudyData && caseStudyData.length > 0 && (
        <Carousel
          className="relative"
          prevArrow={({ handlePrev }) => (
            <Button
              variant="outlined"
              size="lg"
              onClick={handlePrev}
              className="rounded-full !absolute top-2/4 left-4 xl:top-auto xl:left-auto xl:right-0 xl:bottom-20 -translate-y-2/4 h-16 w-16 flex items-center justify-center p-4 hover:bg-black hover:text-white focus:bg-black focus:text-white"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M15.75 19.5L8.25 12l7.5-7.5"
                />
              </svg>
            </Button>
          )}
          nextArrow={({ handleNext }) => (
            <Button
              variant="outlined"
              size="lg"
              onClick={handleNext}
              className="rounded-full !absolute top-2/4 !right-4 xl:top-auto xl:!right-0 xl:!bottom-0 -translate-y-2/4  h-16 w-16 flex items-center justify-center p-4 hover:bg-black hover:text-white focus:bg-black focus:text-white"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                className="w-6 h-6"
                stroke="currentColor"
              >
                <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
              </svg>
            </Button>
          )}
          navigation={({ setActiveIndex, activeIndex, length }) => (
            <div className="hidden">
              {new Array(length).fill("").map((_, i) => (
                <span
                  key={i}
                  className={`block h-3 w-3 cursor-pointer rounded-full transition-colors content-[''] ${
                    activeIndex === i ? "bg-white" : "bg-white/50"
                  }`}
                  onClick={() => setActiveIndex(i)}
                />
              ))}
            </div>
          )}
        >
          {caseStudyData.map((ele, i) => {
            return (
              <a
                key={i}
                href={"/case-studies/" + ele.slug}
                className="group rounded-xl xl:my-16 xl:mx-24 relative block overflow-hidden"
              >
                <Img
                  imgSrc={CONSTANT.IMAGE_BASE_PATH + ele.Display_Image}
                  imgAlt={ele.Case_Study_Of}
                  cssClass="group-hover:scale-105 transition-all w-full"
                />
                <div className="bg-gradient-to-b from-white/0 to-black/50 absolute top-0 left-0 w-full h-full"></div>
                <Headings
                  headType="h3"
                  titleText={ele.Case_Study_Of}
                  className="bottom-8 left-8 absolute text-5xl font-medium text-left text-white"
                />
              </a>
            );
          })}
        </Carousel>
      )}
    </div>
  );
};

export default SliderBox;
