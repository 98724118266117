import Headings from "../../components/Headings";
import HeroBanner from "../../components/HeroBanner";
import ImageContentBlock from "../../components/ImageContentBlock";
import CardBox from "../../components/CardBox";
import FeaturesBlock from "../../components/FeaturesBlock";
import ServiceRepeater from "../../components/ServiceRepeater";
import Img from "../../components/Image";
import AccordionBox from "../../components/AccordionBox";

import SliderBox from "../../components/SliderBox";
import Btn from "../../components/Btn";
const softwareData = [
  {
    id: 1,
    imgSrc: "fintech.jpg",
    title: "Fin<strong>Tech</strong>",
    desc: "A mobile app that assists with graduate recruitment programmes to organise and deliver information from candidates and allows for mentorship opportunities.",
    buttonText: "Case Study",
    buttonLinkUrl: "/fintech",
  },
  {
    id: 2,
    imgSrc: "agritech.jpg",
    title: "Agri<strong>Tech</strong>",
    desc: "A mobile application that enables farmers to find, rent and pay for specialised equipment with a payment solution to ease cash flow challenges.",
    buttonText: "Case Study",
    buttonLinkUrl: "/fintech",
  },
  {
    id: 2,
    imgSrc: "retailtech.jpg",
    title: "Retail<strong>Tech</strong>",
    desc: "A mobile application that enables farmers to find, rent and pay for specialised equipment with a payment solution to ease cash flow challenges.",
    buttonText: "Case Study",
    buttonLinkUrl: "/fintech",
  },
];
const featureData = [
  {
    id: 1,
    imgSrc: "icon-design.svg",

    desc: "With +OneX Staff Augmentation service, you can scale your team according to your business needs.",
  },
  {
    id: 2,
    imgSrc: "icon-webapp.svg",

    desc: "You can test out the candidate before hiring them permanently - assess their skills, expertise and cultural fit within your team.",
  },
  {
    id: 3,
    imgSrc: "icon-mobileapp.svg",

    desc: "Our extensive network of leading African talent ensures that you have access to the best candidates in the market.",
  },
  {
    id: 4,
    imgSrc: "icon-ecommerce.svg",

    desc: "Ensure that you get the right candidate for the long-term, saving costs on the wrong hire.",
  },
  {
    id: 5,
    imgSrc: "icon-qa.svg",

    desc: "We also ensure that our candidates have a high NPS score, which guarantees their expertise and proficiency in their respective fields.",
  },
  {
    id: 6,
    imgSrc: "icon-devops.svg",

    desc: "Our on-demand service ensures your candidate will kick off 3x faster than hiring anywhere else.",
  },
];
const faqsData = [
  {
    id: 1,
    title: "Find talent fast ",
    desc: "We already have the perfect person in our pool of proven techies, saving you time and effort.",
  },
  {
    id: 2,
    title: "No recruitment fees ",
    desc: "The timeline for developing web applications can vary depending on the complexity of the project, the size of the development team, and other factors. We work closely with our clients to establish realistic timelines and milestones, and we communicate regularly throughout the development process to ensure that the project stays on track.",
  },
  {
    id: 3,
    title: "Flexible contracts ",
    desc: "The timeline for developing web applications can vary depending on the complexity of the project, the size of the development team, and other factors. We work closely with our clients to establish realistic timelines and milestones, and we communicate regularly throughout the development process to ensure that the project stays on track.",
  },
  {
    id: 4,
    title: "Vetted and tested ",
    desc: "The timeline for developing web applications can vary depending on the complexity of the project, the size of the development team, and other factors. We work closely with our clients to establish realistic timelines and milestones, and we communicate regularly throughout the development process to ensure that the project stays on track.",
  },
];
const QualityAssurance = () => {
  return (
    <div>
      <HeroBanner
        heroType="full"
        heroTitle={"Quality Assurance "}
        heroDesc={
          "Encompassing various domains, our team's diverse expertise empowers us to excel in quality assurance services tailored to your unique requirements. From testing to validation, we collaborate closely with you to comprehend your objectives, challenges, and growth potential. This cooperative approach ensures that our quality assurance efforts go beyond routine practices, seamlessly aligning with your strategic aspirations."
        }
      />
      <ImageContentBlock blockImage="qa.jpg" cssClass="bg-white" imgLeft={false}>
        <div className="xl:px-32 px-8">
          <Headings
            headType={"h2"}
            titleText={"This is a catchy title for the header"}
            className={"xl:text-6xl text-5xl font-medium mb-10"}
          />

          <div>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
              incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
              exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure
              dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
            </p>
          </div>

          <a href="/find-talent">
            <Btn>Find out more</Btn>
          </a>
        </div>
      </ImageContentBlock>
      <section className="page-section bg-light">
        <div className="container">
          <Headings
            headType={"h2"}
            titleText={"Expert web apps across any industry"}
            className={"xl:text-6xl text-5xl font-medium mb-5"}
          />
          <p>
            Our team possesses a wealth of expertise in developing software across diverse
            industries.
          </p>
          <div className="xl:grid-cols-3 xl:mt-16 grid gap-20 mt-8">
            {softwareData.map((ele, i) => {
              return (
                <CardBox
                  key={i}
                  imgSrc={ele.imgSrc}
                  imgAlt={ele.title}
                  cardTitle={ele.title}
                  cardDesc={ele.desc}
                  buttonText={ele.buttonText}
                  buttonLinkUrl={ele.buttonLinkUrl}
                />
              );
            })}
          </div>
        </div>
      </section>
      <FeaturesBlock
        featureTitle={"Our expert web app development process"}
        buttonText={"Let’s Get Started"}
        buttonLinkUrl="/bespoke-software-development"
      >
        <ServiceRepeater dataList={featureData} />
      </FeaturesBlock>
      <section className="page-section bg-light">
        <div className="container">
          <Headings
            headType={"h2"}
            titleText={"Recent work "}
            className={"xl:text-6xl text-5xl font-medium"}
          />

          <SliderBox />
        </div>
      </section>
      <section className="page-section">
        <div className="container">
          <div className="xl:w-8/12">
            <Headings
              headType={"h2"}
              titleText={"How it works"}
              className={"xl:text-6xl text-5xl font-medium mb-5"}
            />
            <p className="xl:w-11/12">
              We specialize in crafting personalized digital experiences that tangibly enhance your
              business's value proposition. With our guidance, you'll confidently navigate the path
              to your next phase of growth while simultaneously nurturing your team's skills and
              potential.
            </p>
          </div>
          <div className="mt-7 xl:gap-28 xl:flex-nowrap flex flex-wrap gap-8">
            <div className="xl:order-2 xl:w-1/2 xl:text-right xl:pl-8 w-full text-center">
              <Img imgSrc="how-it-works-alt.png" imgAlt="faq" />
            </div>
            <div className="xl:w-1/2 mt-7 w-full">
              <AccordionBox dataList={faqsData} />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default QualityAssurance;
