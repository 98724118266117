import cx from "classnames";
import Img from "../Image";
import Headings from "../Headings";

import { Card, CardBody, CardFooter } from "@material-tailwind/react";
import Btn from "../Btn";

interface CardProps {
  cssClass?: string;
  imgSrc?: string;
  imgAlt?: string;
  cardTitle?: string;
  cardDesc?: string;
  buttonText?: string;
  buttonLinkUrl?: string;
}

const CardBox = ({
  cssClass,
  imgSrc,
  imgAlt,
  cardTitle,
  cardDesc,
  buttonText,
  buttonLinkUrl,
}: CardProps) => {
  return (
    <Card className={cx("", cssClass)}>
      <CardBody>
        {imgSrc && (
          <div className="h-96 w-full mb-6">
            <Img
              imgSrc={imgSrc}
              imgAlt={imgAlt}
              cssClass="h-full w-full object-cover"
              width="345"
              height="384"
            />
          </div>
        )}
        {cardTitle && <Headings headType="h3" titleText={cardTitle} className="mb-4 text-3xl" />}
        {cardDesc && <div className="" dangerouslySetInnerHTML={{ __html: cardDesc }}></div>}
      </CardBody>
      {buttonText && (
        <CardFooter>
          <a href={buttonLinkUrl}>
            <Btn className="w-full mt-auto">{buttonText}</Btn>
          </a>
        </CardFooter>
      )}
    </Card>
  );
};

export default CardBox;
