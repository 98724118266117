import cx from "classnames";

import Headings from "../Headings";
import styles from "./heroBanner.module.css";

import Img from "../Image";
import Btn from "../Btn";
interface HeroBannerHalfProps {
  cssClass?: string;
  heroTitle?: string;
  heroDesc?: string;
  buttonText?: string;
  buttonLinkUrl?: string;
  heroImage?: string;
  heroImageAlt?: string;
  heroImageBGColor?: string;
}

const HeroBannerHalf = ({
  cssClass,
  heroTitle,
  heroDesc,
  buttonText,
  buttonLinkUrl,
  heroImage,
  heroImageAlt,
  heroImageBGColor,
}: HeroBannerHalfProps) => {
  return (
    <div
      className={cx(
        "bg-light  xl:pb-40 py-14 pt-0 xl:pt-40  relative flex xl:flex-row flex-col",
        styles.heroBannerContainer,
        cssClass && cssClass
      )}
    >
      <div
        className={cx("xl:absolute xl:top-0 xl:right-0 xl:w-[50vw] h-full")}
        style={{ backgroundColor: heroImageBGColor }}
      >
        {heroImage && (
          <Img
            imgSrc={heroImage}
            imgAlt={heroImageAlt}
            cssClass="w-full h-full object-cover object-top"
          />
        )}
      </div>
      <div className=" container h-full mx-auto">
        <div className="xl:w-1/2 xl:pr-32 xl:pt-0 pt-14 xl:h-full flex items-center">
          <div>
            {heroTitle && (
              <Headings
                headType={"h1"}
                titleText={heroTitle}
                className={"xl:text-6xl text-5xl font-medium mb-10"}
              />
            )}
            {heroDesc && <div>{heroDesc}</div>}
            {buttonText && (
              <a href={buttonLinkUrl}>
                <Btn>{buttonText}</Btn>
              </a>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroBannerHalf;
