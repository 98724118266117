import cx from "classnames";

interface IconAgileTeamsProps {
  className?: string;
}

export default function IconAgileTeams({ className = "w-6 h-6" }: IconAgileTeamsProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      className={cx("fill-inherit transition-all", className)}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.6,16.1c0.4-0.7,0.8-1.5,1-2.3c0.1-0.5,0.5-0.8,1-0.8H22v-2h-1.5c-0.5,0-0.9-0.3-1-0.8
	c-0.2-0.8-0.5-1.6-1-2.3c-0.2-0.4-0.2-0.9,0.1-1.2l1-1l-1.4-1.4l-1,1c-0.2,0.2-0.4,0.3-0.7,0.3c-0.2,0-0.4-0.1-0.5-0.2
	c-0.7-0.4-1.5-0.8-2.3-1c-0.5-0.1-0.8-0.5-0.8-1V2h-2v1.5c0,0.5-0.3,0.9-0.8,1c-0.8,0.2-1.6,0.5-2.3,1C7.7,5.5,7.6,5.5,7.4,5.5
	c-0.3,0-0.5-0.1-0.7-0.3l-1-1L4.2,5.6l1,1C5.6,7,5.6,7.5,5.4,7.9c-0.4,0.7-0.8,1.5-1,2.3c-0.1,0.5-0.5,0.8-1,0.8H2v2h1.4
	c0.5,0,0.9,0.3,1,0.8c0.2,0.8,0.5,1.6,1,2.3c0.2,0.4,0.2,0.9-0.1,1.2l-1,1l1.4,1.4l1-1c0.2-0.2,0.4-0.3,0.7-0.3
	c0.2,0,0.4,0.1,0.5,0.1c0.7,0.4,1.5,0.8,2.3,1c0.5,0.1,0.8,0.5,0.8,1V22h2v-1.4c0-0.5,0.3-0.9,0.8-1c0.8-0.2,1.6-0.5,2.3-1
	c0.2-0.1,0.3-0.2,0.5-0.2c0.3,0,0.5,0.1,0.7,0.3l1,1l1.4-1.4l-1-1C18.4,17,18.4,16.5,18.6,16.1z M23,9c0.3,0,0.5,0.1,0.7,0.3
	C23.9,9.5,24,9.7,24,10v4c0,0.6-0.4,1-1,1h-1.8c-0.2,0.5-0.3,0.9-0.6,1.4l1.3,1.3c0.4,0.4,0.4,1,0,1.4l-2.8,2.8
	c-0.2,0.2-0.4,0.3-0.7,0.3c-0.3,0-0.5-0.1-0.7-0.3l-1.3-1.3c-0.5,0.2-0.9,0.4-1.4,0.6V23c0,0.5-0.4,1-1,1h-4c-0.5,0-1-0.4-1-1v-1.8
	c-0.5-0.2-0.9-0.4-1.4-0.6l-1.3,1.3c-0.2,0.2-0.4,0.3-0.7,0.3c-0.3,0-0.5-0.1-0.7-0.3l-2.8-2.8c-0.4-0.4-0.4-1,0-1.4l1.3-1.3
	c-0.2-0.5-0.4-0.9-0.6-1.4H1c-0.3,0-0.5-0.1-0.7-0.3C0.1,14.5,0,14.3,0,14v-4c0-0.3,0.1-0.5,0.3-0.7C0.5,9.1,0.7,9,1,9h1.8
	c0.2-0.5,0.3-0.9,0.6-1.4L2.1,6.3c-0.4-0.4-0.4-1,0-1.4L5,2.1c0.2-0.2,0.4-0.3,0.7-0.3c0.3,0,0.5,0.1,0.7,0.3l1.2,1.2
	C8,3.1,8.5,2.9,9,2.8V1c0-0.3,0.1-0.5,0.3-0.7C9.5,0.1,9.7,0,10,0h4c0.6,0,1,0.5,1,1v1.8c0.5,0.2,0.9,0.4,1.4,0.6l1.3-1.3l0,0
	c0.2-0.2,0.4-0.3,0.7-0.3c0.3,0,0.5,0.1,0.7,0.3l2.8,2.8c0.4,0.4,0.4,1,0,1.4l-1.3,1.3C20.9,8,21.1,8.5,21.2,9H23z M14.6,15.2
	c0-1.3-1-2.3-2.2-2.3c-1.2,0-2.2,1-2.2,2.3v0.2h4.4V15.2z M11.3,9.8c0,0.6,0.5,1.2,1.1,1.2c0.6,0,1.1-0.5,1.1-1.2
	c0-0.7-0.5-1.2-1.1-1.2C11.8,8.6,11.3,9.1,11.3,9.8z M15.4,9.8c0,0.7-0.2,1.4-0.7,1.9c1.1,0.8,1.8,2.1,1.8,3.5v1.2c0,0.5-0.4,1-1,1
	H9.2c-0.5,0-1-0.4-1-1v-1.2c0-1.4,0.7-2.7,1.8-3.5c-0.4-0.6-0.7-1.2-0.7-1.9c0-1.7,1.4-3.2,3-3.2C14.1,6.6,15.4,8,15.4,9.8z"
      />
    </svg>
  );
}
