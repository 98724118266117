import cx from "classnames";
import Headings from "../Headings";

import Img from "../Image";
import Btn from "../Btn";

interface FeaturesBlockProps {
  brandLogo?: string;
  cssClass?: string;
  children?: React.ReactNode;
  featureTitle?: string;
  featureDesc?: string;
  buttonText?: string;
  buttonLinkUrl?: string;
}

const FeaturesBlock = ({
  brandLogo,
  cssClass,
  children,
  featureTitle,
  featureDesc,
  buttonText,
  buttonLinkUrl,
}: FeaturesBlockProps) => {
  return (
    <div className={cx("page-section", cssClass)}>
      <div className="container">
        <div className="xl:gap-16 xl:flex-nowrap flex flex-wrap gap-12">
          <div className="xl:w-5/12 w-full">
            {brandLogo && <Img imgSrc={brandLogo} cssClass="max-h-24" imgAlt={featureTitle} />}
            {featureTitle && (
              <Headings
                headType={"h2"}
                titleText={featureTitle}
                className={"xl:text-6xl text-4xl font-medium mb-10"}
              />
            )}
            {featureDesc && <div>{featureDesc}</div>}
            {buttonText && (
              <a href={buttonLinkUrl}>
                <Btn>{buttonText}</Btn>
              </a>
            )}
          </div>
          <div className="xl:w-7/12 w-full">{children}</div>
        </div>
      </div>
    </div>
  );
};

export default FeaturesBlock;
