import Headings from "../../components/Headings";
import HeroBanner from "../../components/HeroBanner";
import TestimonialBlock from "../../components/TestimonialBlock";

const testiData = [
  {
    id: 1,
    testiImg: "../fnb-banner.jpg",
    testiLogo: "./fnb-logo.jpg",
    customer: "First National Bank",
    industry: "Banking",
    size: "Large (4180 employees)",
    country: "South Africa",
    prodService: "Software Development",
    quotes:
      "Our cost per sale has decreases dramatically (by 127%) Their data led development have helped us see significant improvements",
    author: "Anton van Metzinger",
    designation: "FNB Commercial",
    situation:
      "First National Bank has been facing significant pressure to meet the demands of their business banking clients by delivering new and innovative products. In response to this challenge, the bank has undertaken several initiatives to enhance their development capacity and ensure the successful delivery of these innovative products.",
    solution:
      "We were brought in to support their efforts by augmenting their teams with a group of highly skilled technical specialists, including software developers, scrum masters, business analysts, and user interface designers. The addition of our 18 technical specialists has proven to be a major success for First National Bank. Our team seamlessly integrated into their existing development teams, bringing with them a wealth of expertise and experience in their respective fields. ",
    impact:
      "By bringing in our team of software developers, scrum masters, business analysts, and user interface designers, we have provided the bank with the burst capacity needed to deliver new and innovative products for their business banking clients. The collaborative efforts have yielded exceptional results, enabling the bank to meet client demands, drive innovation, and establish a strong foothold in the market.",
  },
  {
    id: 2,
    testiImg: "../sbs-banner.jpg",
    testiLogo: "./fnb-logo.jpg",
    customer: "Swaziland Building Society",
    industry: "Banking",
    size: "Large (4180 employees)",
    country: "South Africa",
    prodService: "Product deisgn<br/>Mobile AppDevelopment<br/>Software developement",
    quotes:
      "We have entered the era of the customers. Today, providing customers with outstanding customer service is essential to building loyal customers and a long-lasting brand.",
    author: "Melusi Malinga",
    designation: "CIO",
    situation:
      "SBS has been facing significant pressure to meet the demands of their banking clients by delivering new and innovative products. In response to this challenge, the bank has undertaken several initiatives to enhance their development capacity and ensure the successful delivery of these innovative products.",
    solution:
      "We were brought in to support their efforts by augmenting their teams with a group of highly skilled technical specialists, including software developers, scrum masters, business analysts, and user interface designers. The addition of our 18 technical specialists has proven to be a major success for SBS. Our team seamlessly integrated into their existing development teams, bringing with them a wealth of expertise and experience in their respective fields.",
    impact:
      "By bringing in our team of software developers, scrum masters, business analysts, and user interface designers, we have provided the bank with the burst capacity needed to deliver new and innovative products for their business banking clients. The collaborative efforts have yielded exceptional results, enabling the bank to meet client demands, drive innovation, and establish a strong foothold in the market.",
  },
  {
    id: 3,
    testiImg: "../investec-banner.jpg",
    testiLogo: "./investec-logo.jpg",
    customer: "Investec",
    industry: "Banking",
    size: "Large (4180 employees)",
    country: "South Africa",
    prodService: "Software Development<br/>Cloud Development",
    quotes:
      "+OneX not only provides us with muscle in our teams, but also offer a full managed service on IT outsource projects. We love the passion and drive of +OneX which matches our culture of getting things done",
    author: "Shabhana Thaver",
    designation: "Group Chief Information Officer",
    situation:
      "To keep pace with “hyper growth,” deliver reliable financial services, and ensure projects executed on time and within budget Investec required additional resources to help their teams across numerous different BU’s including Pvt Bank, central Tech, client experience and Data.      ",
    solution:
      "Due to +OneX’s capability to understand, meet and deliver on client needs,  we have managed to add immense support on existing projects as well has new project,.  We have done so utilizing teams across SD, Cloud and Data to help Investec achieve their desired outcomes and continue to do so.      ",
    impact:
      "Projects delivered on time, in scope and on budget with teams that collaborate, get involved and focus on delivery.  Execution with pride, attitude and aptitude across all areas within the bank.",
  },
];

const Fintech = () => {
  return (
    <div>
      <HeroBanner
        heroType="card"
        heroTitle={"Crafting Banking and Financial Software"}
        heroDesc={
          "With our fintech software development company, you can focus on strategy for your business while we handle all stages of fintech software development."
        }
        buttonText={"Contact us "}
        buttonLinkUrl={"/contact-us"}
        heroImage={"fintech-banner.jpg"}
        heroImageAlt={"Crafting Banking and Financial Software"}
        heroImageBGColor={"bg-[#E0DFF7]"}
      >
        <div className="text-center">
          <Headings
            headType="h2"
            titleText="Why us ?"
            className={"xl:text-6xl text-5xl font-medium mb-7"}
          />
          <div className="xl:text-3xl mb-12">
            For a decade, we've been dedicated to crafting banking and financial software solutions
            that empower enterprise-level companies and forward-thinking startups.
          </div>
          <div>
            <p>Our mission? To ignite cost efficiency and fuel sustainable growth.</p>
            <p>
              With our fintech software development company, you're empowered to direct your focus
              on shaping your business strategy, while we seamlessly navigate every facet of fintech
              software development.
            </p>
          </div>
        </div>
      </HeroBanner>
      {testiData.map((ele, i) => {
        return (
          <TestimonialBlock
            key={i}
            testiImg={ele.testiImg}
            testiLogo={ele.testiLogo}
            customer={ele.customer}
            industry={ele.industry}
            size={ele.size}
            country={ele.country}
            prodService={ele.prodService}
            quotes={ele.quotes}
            author={ele.author}
            designation={ele.designation}
            situation={ele.situation}
            solution={ele.solution}
            impact={ele.impact}
          />
        );
      })}
    </div>
  );
};

export default Fintech;
