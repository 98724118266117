import cx from "classnames";
import Headings from "../Headings";

import Btn from "../Btn";

interface HeroBannerFullProps {
  cssClass?: string;
  heroTitle?: string;
  heroDesc?: string;
  buttonText?: string;
  buttonLinkUrl?: string;
}
const HeroBannerFull = ({
  cssClass,
  heroTitle,
  heroDesc,
  buttonText,
  buttonLinkUrl,
}: HeroBannerFullProps) => {
  return (
    <div className={cx("bg-light text-center pt-20 pb-14 xl:pt-32 xl:pb-24", cssClass && cssClass)}>
      <div className="container mx-auto">
        {heroTitle && (
          <Headings
            headType={"h1"}
            titleText={heroTitle}
            className={"xl:text-6xl text-4xl font-medium mb-10"}
          />
        )}
        {heroDesc && <div>{heroDesc}</div>}
        {buttonText && (
          <a href={buttonLinkUrl}>
            <Btn>{buttonText}</Btn>
          </a>
        )}
      </div>
    </div>
  );
};

export default HeroBannerFull;
