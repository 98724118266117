import Headings from "../Headings";
import Img from "../Image";
interface TestimonialBlockProps {
  testiImg?: string;
  testiLogo?: string;
  customer?: string;
  industry?: string;
  size?: string;
  country?: string;
  prodService?: string;
  quotes?: string;
  author?: string;
  designation?: string;
  situation?: string;
  solution?: string;
  impact?: string;
}
const TestimonialBlock = ({
  testiImg,
  testiLogo,
  customer,
  industry,
  size,
  country,
  prodService,
  quotes,
  author,
  designation,
  situation,
  solution,
  impact,
}: TestimonialBlockProps) => {
  return (
    <div>
      <Img
        imgSrc={testiImg}
        imgAlt={customer}
        cssClass="w-full object-cover h-[428px] xl:h-[460px]"
      />
      <div className="xl:mt-14 xl:mb-28 container mt-10 mb-20">
        <div className="xl:flex-nowrap gap-14 flex flex-wrap">
          <div className="xl:w-3/12 w-full">
            <Img imgSrc={testiLogo} imgAlt={customer} cssClass="max-h-36 max-w-[200px] mb-16" />
            <div className="space-y-4">
              <p>
                <span className="font-bold">Customer:</span> {customer}
              </p>
              <p>
                <span className="font-bold">Industry:</span> {industry}
              </p>
              <p>
                <span className="font-bold">Size:</span> {size}
              </p>
              <p>
                <span className="font-bold">Country:</span> {country}
              </p>
              <p>
                <span className="font-bold">Products and services:</span> {prodService}
              </p>
            </div>
          </div>
          <div className="xl:w-9/12 w-full">
            <div className="mb-16">
              <div className="mb-5 text-3xl">{`“${quotes}”`}</div>
              <div>
                <strong>{author}</strong> <strong>-</strong> {designation}
              </div>
            </div>
            <div className="xl:grid-cols-3 gap-14 grid grid-cols-1">
              <div>
                <Headings headType="h3" titleText="Situation" className="mb-4 text-xl" />
                <div dangerouslySetInnerHTML={{ __html: situation || "" }}></div>
              </div>
              <div>
                <Headings headType="h3" titleText="Solution" className="mb-4 text-xl" />
                <div dangerouslySetInnerHTML={{ __html: solution || "" }}></div>
              </div>
              <div>
                <Headings headType="h3" titleText="Impact" className="mb-4 text-xl" />
                <div dangerouslySetInnerHTML={{ __html: impact || "" }}></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TestimonialBlock;
