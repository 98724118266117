import Btn from "../../components/Btn";
import { Input, Radio, Textarea } from "@material-tailwind/react";
import Headings from "../../components/Headings";
import Img from "../../components/Image";
import { MapPinIcon } from "@heroicons/react/24/outline";

const CheckAvailabilityModal = () => {
  return (
    <div className="xl:flex-row flex flex-col">
      <div className="xl:w-4/12 bg-dark flex flex-col p-8 text-white rounded-tl-lg rounded-bl-lg">
        <div className="px-7 py-6">
          <div className="grid space-y-4">
            <Img imgSrc="developer.jpg" cssClass="rounded mb-4" />
            <Headings
              headType="h2"
              titleText="Name Surname"
              className="mb-2 text-xl font-semibold"
            />
            <div className="mb-2 text-sm font-semibold">8+ yrs experience</div>
            <div className="text-md flex items-center gap-4 font-medium">
              <MapPinIcon className="flex-none w-5 h-5" />
              <div>South Africa</div>
            </div>
          </div>
        </div>
      </div>
      <div className="xl:w-8/12 p-11 bg-white rounded-tr-lg rounded-br-lg">
        <Headings headType="h2" titleText="Request availability" className="mb-2 text-3xl" />
        <p className="text-lg">
          Please fill in the form below to request the avaialbity of the developer
        </p>
        <form className="mt-5">
          <div className="grid grid-cols-2 gap-8">
            <div className="col-span-1">
              <Input
                variant="standard"
                label="First name"
                defaultValue={""}
                crossOrigin={undefined}
                required
              />
            </div>
            <div className="col-span-1">
              <Input
                variant="standard"
                label="Last name"
                defaultValue={""}
                crossOrigin={undefined}
                required
              />
            </div>
            <div className="col-span-1">
              <Input
                variant="standard"
                label="Mail"
                defaultValue={""}
                crossOrigin={undefined}
                required
              />
            </div>
            <div className="col-span-1">
              <Input
                variant="standard"
                label="Phone"
                defaultValue={""}
                crossOrigin={undefined}
                required
              />
            </div>
            <div className="col-span-1">
              <Input
                variant="standard"
                label="Company Name"
                defaultValue={""}
                crossOrigin={undefined}
                required
              />
            </div>
            <div className="col-span-1">
              <Input
                variant="standard"
                label="Where you are located?"
                defaultValue={""}
                crossOrigin={undefined}
                required
              />
            </div>
            <div className="col-span-2">
              <Headings
                headType="h4"
                titleText="Prefered start date"
                className="text-lg font-medium"
              />
            </div>
            <div className="col-span-2">
              <div className="flex flex-wrap gap-6">
                <Radio name="type" label="Within 1 weeks" crossOrigin={undefined} />
                <Radio name="type" label="Within 2 weeks" defaultChecked crossOrigin={undefined} />
                <Radio name="type" label="1 Month" crossOrigin={undefined} />
                <Radio name="type" label="2 Month" crossOrigin={undefined} />
              </div>
            </div>

            <div className="col-span-2">
              <Textarea variant="static" label="Brief project description" />
            </div>
            <div className="col-span-2 ml-auto">
              <Btn type="submit" className="py-5 min-w-[144px]">
                Enquire now
              </Btn>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CheckAvailabilityModal;
