import { Route, Routes, useLocation } from "react-router-dom";
import Home from "./pages";
import ErrorPage from "./pages/error";
import ScaleYourTeam from "./pages/ScaleYourTeam";
import MobileAppDev from "./pages/MobileAppDev";
import WebAppDev from "./pages/WebAppDev";
import ProductDesign from "./pages/ProductDesign";
import FindTalent from "./pages/FindTalent";
import BespokeSoftwareDev from "./pages/BespokeSoftwareDev";
import ECommerce from "./pages/ECommerce";
import SoftwareSupport from "./pages/SoftwareSupport";
import QualityAssurance from "./pages/QualityAssurance";
import CloudSoftwareDev from "./pages/CloudSoftwareDev";
import BuildService from "./pages/BuildService";
import Company from "./pages/Company";
import CaseStudies from "./pages/CaseStudies";
import Fintech from "./pages/Fintech";
import JoinUs from "./pages/JoinUs";
import CaseStudyDetails from "./pages/CaseStudies/[slug]";
import Landing from "./pages/Landing/[slug]";

import Header from "./components/Header";
import Footer from "./components/Footer";
import { useEffect, useState } from "react";
import HireDevelopers from "./pages/HireDevelopers";
import ThankYou from "./pages/ThankYou";
import ApplyHere from "./pages/ApplyHere";

const MainRouter = () => {
  const { pathname } = useLocation();
  const [landingPageState, setLandingPageState] = useState(Boolean);
  const [hideHeaderFooter, setHideHeaderFooter] = useState(false);

  useEffect(() => {
    const excludedRoutes = ["/landing/shopify", "/landing/umbarco"];
    const hideRoutes = ["/hire-developers", "/thank-you", "/apply-here"];
    if (excludedRoutes.includes(pathname)) {
      setLandingPageState(true);
    }
    if (hideRoutes.includes(pathname)) {
      setHideHeaderFooter(true);
    }
  }, [pathname]);
  // console.log(landingPageState);
  return (
    <>
      {!hideHeaderFooter && <Header landingHeader={landingPageState} />}
      <Routes>
        <Route path="/" element={<Home />} errorElement={<ErrorPage />} />
        <Route
          path="/scale-your-team"
          element={<ScaleYourTeam />}
          errorElement={<ErrorPage />}
        />
        <Route
          path="/mobile-app-development"
          element={<MobileAppDev />}
          errorElement={<ErrorPage />}
        />
        <Route
          path="/web-app-development"
          element={<WebAppDev />}
          errorElement={<ErrorPage />}
        />
        <Route
          path="/product-design"
          element={<ProductDesign />}
          errorElement={<ErrorPage />}
        />
        <Route
          path="/find-talent"
          element={<FindTalent />}
          errorElement={<ErrorPage />}
        />
        <Route
          path="/bespoke-software-development"
          element={<BespokeSoftwareDev />}
          errorElement={<ErrorPage />}
        />
        <Route
          path="/e-commerce"
          element={<ECommerce />}
          errorElement={<ErrorPage />}
        />
        <Route
          path="/software-support"
          element={<SoftwareSupport />}
          errorElement={<ErrorPage />}
        />
        <Route
          path="/quality-assurance"
          element={<QualityAssurance />}
          errorElement={<ErrorPage />}
        />
        <Route
          path="/cloud-software-development"
          element={<CloudSoftwareDev />}
          errorElement={<ErrorPage />}
        />
        <Route
          path="/build"
          element={<BuildService />}
          errorElement={<ErrorPage />}
        />
        <Route
          path="/company"
          element={<Company />}
          errorElement={<ErrorPage />}
        />
        <Route
          path="/case-studies"
          element={<CaseStudies />}
          errorElement={<ErrorPage />}
        />
        <Route
          path="/case-studies/:id"
          element={<CaseStudyDetails />}
          errorElement={<ErrorPage />}
        />
        <Route
          path="/fintech"
          element={<Fintech />}
          errorElement={<ErrorPage />}
        />
        <Route
          path="/join-us"
          element={<JoinUs />}
          errorElement={<ErrorPage />}
        />
        <Route
          path="/landing/:id"
          element={<Landing />}
          errorElement={<ErrorPage />}
        />
        <Route
          path="/hire-developers"
          element={<HireDevelopers />}
          errorElement={<ErrorPage />}
        />
        <Route
          path="/apply-here"
          element={<ApplyHere />}
          errorElement={<ErrorPage />}
        />

        <Route
          path="/thank-you"
          element={<ThankYou />}
          errorElement={<ErrorPage />}
        />
      </Routes>
      {!hideHeaderFooter && <Footer landingFooter={landingPageState} />}
    </>
  );
};

export default MainRouter;
