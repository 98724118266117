import { Checkbox, Chip, Dialog, DialogBody, Option, Select } from "@material-tailwind/react";
import Headings from "../../components/Headings";
import cx from "classnames";
import { Accordion, AccordionHeader, AccordionBody } from "@material-tailwind/react";
import { useState } from "react";
import { ChevronDownIcon } from "@heroicons/react/24/outline";
import Img from "../../components/Image";
import Btn from "../../components/Btn";
import CheckAvailabilityModal from "../CheckAvailabilityModal";
const Icon = ({ id, open }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={2}
      stroke="currentColor"
      className={`${id === open ? "rotate-180" : ""} h-3 w-3 transition-transform`}
    >
      <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
    </svg>
  );
};
const FindTalent = () => {
  const [open, setOpen] = useState(0);
  const [openMore, setOpenMore] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const handleOpen = (value) => setOpen(open === value ? 0 : value);
  const handleOpenModal = () => {
    setOpenModal(!openModal);
  };
  const toggleOpenMore = () => setOpenMore((openMore) => !openMore);
  return (
    <div>
      <div className="bg-light pt-20 pb-10">
        <div className="container">
          <Headings
            headType={"h1"}
            titleText={"Find talent"}
            className={"xl:text-6xl text-4xl font-medium mb-10"}
          />
        </div>
      </div>
      <section className="page-section">
        <div className="container">
          <div className="gap-y-10 flex flex-wrap">
            <div className="lg:w-3/12 lg:pr-10 w-full">
              <Headings headType="h2" titleText="Filter by" className="text-xl font-semibold" />
            </div>
            <div className="lg:w-9/12 w-full">
              <div className="flex flex-wrap items-center justify-between">
                <div className="flex flex-wrap items-center gap-3">
                  <div className="text-sm font-semibold">Sort results</div>
                  <Btn className="text-xs" size="sm">
                    Recommended
                  </Btn>
                  <Btn variant="outlined" className="text-xs" size="sm">
                    Price (Low to high)
                  </Btn>
                  <Btn variant="outlined" className="text-xs" size="sm">
                    Price (High to low)
                  </Btn>
                </div>
                <div className="text-xs">
                  <span className="font-semibold">50</span> candidates
                </div>
              </div>
            </div>
            <div className="lg:w-3/12 lg:pr-10 w-full">
              <div className="mb-7">
                <label className="block mb-4 text-sm font-semibold">Years of experience</label>
                <Select label="Select Experience">
                  <Option>Material Tailwind HTML</Option>
                  <Option>Material Tailwind React</Option>
                  <Option>Material Tailwind Vue</Option>
                  <Option>Material Tailwind Angular</Option>
                  <Option>Material Tailwind Svelte</Option>
                </Select>
              </div>
              <div className="mb-7">
                <label className="block mb-4 text-sm font-semibold">Locaction</label>
                <Select label="Select Locaction">
                  <Option>Material Tailwind HTML</Option>
                  <Option>Material Tailwind React</Option>
                  <Option>Material Tailwind Vue</Option>
                  <Option>Material Tailwind Angular</Option>
                  <Option>Material Tailwind Svelte</Option>
                </Select>
              </div>
              <hr />
              <div>
                <Accordion open={open === 1} icon={<Icon id={1} open={open} />}>
                  <AccordionHeader
                    onClick={() => handleOpen(1)}
                    className={cx(open === 1 && "border-b-white", "transition-all text-sm")}
                  >
                    Speciality
                  </AccordionHeader>
                  <AccordionBody>
                    <div className="flex flex-col pb-8 border-b">
                      <Checkbox label="Front End Development" checked crossOrigin={undefined} />
                      <Checkbox label="Backend Development" crossOrigin={undefined} />
                      <Checkbox label="Database Development" crossOrigin={undefined} />
                      <Checkbox label="Dev ops" crossOrigin={undefined} />
                      <Checkbox label="Cloud" crossOrigin={undefined} />
                      <Checkbox label="Mobile Development" crossOrigin={undefined} />
                    </div>
                  </AccordionBody>
                </Accordion>
                <Accordion open={open === 2} icon={<Icon id={2} open={open} />}>
                  <AccordionHeader
                    onClick={() => handleOpen(2)}
                    className={cx(open === 2 && "border-b-white", "transition-all text-sm")}
                  >
                    Front End Development
                  </AccordionHeader>
                  <AccordionBody>
                    <div className="flex flex-col pb-8 border-b">
                      <Checkbox label="Html 5" checked crossOrigin={undefined} />
                      <Checkbox label="Css 3" crossOrigin={undefined} />
                      <Checkbox label="AngularJs" crossOrigin={undefined} />
                      <Checkbox label="ReactJS" crossOrigin={undefined} />
                      <Checkbox label="MeteorJs" crossOrigin={undefined} />
                      <Checkbox label="VueJs" crossOrigin={undefined} />

                      {openMore && (
                        <div className="flex flex-col transition-all">
                          <Checkbox label="Html" crossOrigin={undefined} />
                          <Checkbox label="Css" crossOrigin={undefined} />
                          <Checkbox label="Angular" crossOrigin={undefined} />
                          <Checkbox label="React" crossOrigin={undefined} />
                        </div>
                      )}

                      <Btn
                        variant="text"
                        size="sm"
                        className="justify-start w-full"
                        onClick={toggleOpenMore}
                      >
                        <ChevronDownIcon
                          className={cx("w-4 h-4 transition-all", openMore && "rotate-180")}
                        />
                        {openMore ? "See Less" : "See More"}
                      </Btn>
                    </div>
                  </AccordionBody>
                </Accordion>
              </div>
            </div>
            <div className="lg:w-9/12 w-full">
              <div className="gap-14 flex flex-col">
                <div className="rounded-2xl lg:p-10 p-6 border">
                  <div className="lg:flex-nowrap lg:gap-10 flex flex-wrap gap-6">
                    <div className="lg:w-3/12 w-full">
                      <Img imgSrc="developer.jpg" cssClass="rounded" />
                    </div>
                    <div className="lg:w-9/12 w-full">
                      <div className="flex items-start justify-between mb-5">
                        <div>
                          <div className="mb-2 text-xl font-semibold">Name Surname</div>
                          <div className="mb-3">ReactJS Developer</div>
                          <div className="mb-2 text-sm font-semibold">8+ yrs experience</div>
                        </div>
                        <Btn className="min-w-fit mt-0" onClick={handleOpenModal}>
                          Check Availability
                        </Btn>
                      </div>
                      <div className="mb-5">
                        <p>
                          Senior Mean stack Develeloper with a BSc in Computer Science from the
                          University of the Western Cape. Experienced with Java, Spring and all of
                          its tech suites.
                        </p>
                      </div>
                      <div>
                        <div className="mb-3 text-lg font-semibold">Skills</div>
                        <div className="flex flex-wrap gap-2">
                          <Chip
                            variant="outlined"
                            value="AnagularJS"
                            className="text-xs text-gray-400 normal-case border-gray-400 rounded-full"
                          />
                          <Chip
                            variant="outlined"
                            value="HTML5"
                            className="text-xs text-gray-400 normal-case border-gray-400 rounded-full"
                          />
                          <Chip
                            variant="outlined"
                            value="CSS3"
                            className="text-xs text-gray-400 normal-case border-gray-400 rounded-full"
                          />
                          <Chip
                            variant="outlined"
                            value="Jquery"
                            className="text-xs text-gray-400 normal-case border-gray-400 rounded-full"
                          />
                          <Chip
                            variant="outlined"
                            value="NodeJS"
                            className="text-xs text-gray-400 normal-case border-gray-400 rounded-full"
                          />
                          <Chip
                            variant="outlined"
                            value="MongoDB"
                            className="text-xs text-gray-400 normal-case border-gray-400 rounded-full"
                          />
                          <Chip
                            variant="outlined"
                            value="ExpressJS"
                            className="text-xs text-gray-400 normal-case border-gray-400 rounded-full"
                          />
                          <Chip
                            variant="outlined"
                            value="GIT"
                            className="text-xs text-gray-400 normal-case border-gray-400 rounded-full"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="rounded-2xl lg:p-10 p-6 border">
                  <div className="lg:flex-nowrap lg:gap-10 flex flex-wrap gap-6">
                    <div className="lg:w-3/12 w-full">
                      <Img imgSrc="developer.jpg" cssClass="rounded" />
                    </div>
                    <div className="lg:w-9/12 w-full">
                      <div className="flex items-start justify-between mb-5">
                        <div>
                          <div className="mb-2 text-xl font-semibold">Name Surname</div>
                          <div className="mb-3">ReactJS Developer</div>
                          <div className="mb-2 text-sm font-semibold">8+ yrs experience</div>
                        </div>
                        <Btn className="min-w-fit mt-0" onClick={handleOpenModal}>
                          Check Availability
                        </Btn>
                      </div>
                      <div className="mb-5">
                        <p>
                          Senior Mean stack Develeloper with a BSc in Computer Science from the
                          University of the Western Cape. Experienced with Java, Spring and all of
                          its tech suites.
                        </p>
                      </div>
                      <div>
                        <div className="mb-3 text-lg font-semibold">Skills</div>
                        <div className="flex flex-wrap gap-2">
                          <Chip
                            variant="outlined"
                            value="AnagularJS"
                            className="text-xs text-gray-400 normal-case border-gray-400 rounded-full"
                          />
                          <Chip
                            variant="outlined"
                            value="HTML5"
                            className="text-xs text-gray-400 normal-case border-gray-400 rounded-full"
                          />
                          <Chip
                            variant="outlined"
                            value="CSS3"
                            className="text-xs text-gray-400 normal-case border-gray-400 rounded-full"
                          />
                          <Chip
                            variant="outlined"
                            value="Jquery"
                            className="text-xs text-gray-400 normal-case border-gray-400 rounded-full"
                          />
                          <Chip
                            variant="outlined"
                            value="NodeJS"
                            className="text-xs text-gray-400 normal-case border-gray-400 rounded-full"
                          />
                          <Chip
                            variant="outlined"
                            value="MongoDB"
                            className="text-xs text-gray-400 normal-case border-gray-400 rounded-full"
                          />
                          <Chip
                            variant="outlined"
                            value="ExpressJS"
                            className="text-xs text-gray-400 normal-case border-gray-400 rounded-full"
                          />
                          <Chip
                            variant="outlined"
                            value="GIT"
                            className="text-xs text-gray-400 normal-case border-gray-400 rounded-full"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="rounded-2xl lg:p-10 p-6 border">
                  <div className="lg:flex-nowrap lg:gap-10 flex flex-wrap gap-6">
                    <div className="lg:w-3/12 w-full">
                      <Img imgSrc="developer.jpg" cssClass="rounded" />
                    </div>
                    <div className="lg:w-9/12 w-full">
                      <div className="flex items-start justify-between mb-5">
                        <div>
                          <div className="mb-2 text-xl font-semibold">Name Surname</div>
                          <div className="mb-3">ReactJS Developer</div>
                          <div className="mb-2 text-sm font-semibold">8+ yrs experience</div>
                        </div>
                        <Btn className="min-w-fit mt-0" onClick={handleOpenModal}>
                          Check Availability
                        </Btn>
                      </div>
                      <div className="mb-5">
                        <p>
                          Senior Mean stack Develeloper with a BSc in Computer Science from the
                          University of the Western Cape. Experienced with Java, Spring and all of
                          its tech suites.
                        </p>
                      </div>
                      <div>
                        <div className="mb-3 text-lg font-semibold">Skills</div>
                        <div className="flex flex-wrap gap-2">
                          <Chip
                            variant="outlined"
                            value="AnagularJS"
                            className="text-xs text-gray-400 normal-case border-gray-400 rounded-full"
                          />
                          <Chip
                            variant="outlined"
                            value="HTML5"
                            className="text-xs text-gray-400 normal-case border-gray-400 rounded-full"
                          />
                          <Chip
                            variant="outlined"
                            value="CSS3"
                            className="text-xs text-gray-400 normal-case border-gray-400 rounded-full"
                          />
                          <Chip
                            variant="outlined"
                            value="Jquery"
                            className="text-xs text-gray-400 normal-case border-gray-400 rounded-full"
                          />
                          <Chip
                            variant="outlined"
                            value="NodeJS"
                            className="text-xs text-gray-400 normal-case border-gray-400 rounded-full"
                          />
                          <Chip
                            variant="outlined"
                            value="MongoDB"
                            className="text-xs text-gray-400 normal-case border-gray-400 rounded-full"
                          />
                          <Chip
                            variant="outlined"
                            value="ExpressJS"
                            className="text-xs text-gray-400 normal-case border-gray-400 rounded-full"
                          />
                          <Chip
                            variant="outlined"
                            value="GIT"
                            className="text-xs text-gray-400 normal-case border-gray-400 rounded-full"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Dialog open={openModal} handler={handleOpenModal} size="xl">
        <DialogBody className="max-h-[94vh] p-0 overflow-y-auto">
          <CheckAvailabilityModal />
        </DialogBody>
      </Dialog>
    </div>
  );
};

export default FindTalent;
