import AccordionBox from "../components/AccordionBox";
import CardBox from "../components/CardBox";
import FeaturesBlock from "../components/FeaturesBlock";
import Headings from "../components/Headings";
import HeroBanner from "../components/HeroBanner";
import Img from "../components/Image";
import ImageContentBlock from "../components/ImageContentBlock";
import ServiceRepeater from "../components/ServiceRepeater";
import Btn from "../components/Btn";

const featureData = [
  {
    id: 1,
    imgSrc: "icon-design.svg",
    title: "Design (Ux /Ui)",
    desc: "We help companies reinvent or modernise existing digital platforms, products and experiences to move businesses forward. It's time to step into the future.",
  },
  {
    id: 2,
    imgSrc: "icon-webapp.svg",
    title: "Web Apps",
    desc: "Build powerful and flexible web applications to help your users achieve their goals and drive more conversions and sales on your website. Whatever your user objectives we can help you build a customisable solution.",
  },
  {
    id: 3,
    imgSrc: "icon-mobileapp.svg",
    title: "Mobile Apps",
    desc: "We have all the expertise you need to develop apps that are optimised for any mobile platform, fast and responsive.",
  },
  {
    id: 4,
    imgSrc: "icon-ecommerce.svg",
    title: "eCommerce",
    desc: "Increase growth, conversions, and long-term customer loyalty with custom built scalable and reliable ecommerce platforms designed with your users in mind. ",
  },
  {
    id: 5,
    imgSrc: "icon-qa.svg",
    title: "Quality Assurance",
    desc: "We use consistent QA testing throughout the development project to ensure that we deliver the best quality solution possible.",
  },
  {
    id: 6,
    imgSrc: "icon-devops.svg",
    title: "Dev Ops Engineering ",
    desc: "We use sophisticated dev ops to shorten the systems development life cycle and provide continuous delivery with high software quality.",
  },
];

const softwareData = [
  {
    id: 1,
    imgSrc: "fintech.jpg",
    title: "Fin<strong>Tech</strong>",
    desc: "A mobile app that assists with graduate recruitment programmes to organise and deliver information from candidates and allows for mentorship opportunities.",
    buttonText: "Case Study",
    buttonLinkUrl: "/fintech",
  },
  {
    id: 2,
    imgSrc: "agritech.jpg",
    title: "Agri<strong>Tech</strong>",
    desc: "A mobile application that enables farmers to find, rent and pay for specialised equipment with a payment solution to ease cash flow challenges.",
    buttonText: "Case Study",
    buttonLinkUrl: "/fintech",
  },
  {
    id: 2,
    imgSrc: "retailtech.jpg",
    title: "Retail<strong>Tech</strong>",
    desc: "A mobile application that enables farmers to find, rent and pay for specialised equipment with a payment solution to ease cash flow challenges.",
    buttonText: "Case Study",
    buttonLinkUrl: "/fintech",
  },
];

const faqsData = [
  {
    id: 1,
    title: "What is your software developement process ?",
    desc: "The timeline for developing web applications can vary depending on the complexity of the project, the size of the development team, and other factors. We work closely with our clients to establish realistic timelines and milestones, and we communicate regularly throughout the development process to ensure that the project stays on track.",
  },
  {
    id: 2,
    title: "What Is Custom Software Development?",
    desc: "The timeline for developing web applications can vary depending on the complexity of the project, the size of the development team, and other factors. We work closely with our clients to establish realistic timelines and milestones, and we communicate regularly throughout the development process to ensure that the project stays on track.",
  },
  {
    id: 3,
    title: "What Is Custom Software Development?",
    desc: "The timeline for developing web applications can vary depending on the complexity of the project, the size of the development team, and other factors. We work closely with our clients to establish realistic timelines and milestones, and we communicate regularly throughout the development process to ensure that the project stays on track.",
  },
  {
    id: 4,
    title: "What Is Custom Software Development?",
    desc: "The timeline for developing web applications can vary depending on the complexity of the project, the size of the development team, and other factors. We work closely with our clients to establish realistic timelines and milestones, and we communicate regularly throughout the development process to ensure that the project stays on track.",
  },
];

const Home = () => {
  return (
    <div>
      <HeroBanner
        heroType="half"
        heroTitle={"Software.<br/>Design.<br/>Teams."}
        heroDesc={
          "We co-create custom digital experiences that drive real, measurable business value. We put you confidently on the road to what comes next in your business growth while helping you build your team's talent and capability."
        }
        buttonText={"Find out more"}
        buttonLinkUrl={"/find-talent"}
        heroImage={"home-banner.jpg"}
        heroImageAlt={"Software Design Teams"}
      />

      <section className="page-section">
        <div className="container text-center">
          <Headings
            headType="h2"
            titleText={"Trusted by over 300 clients"}
            className="xl:text-5xl xl:mb-20 mb-14 text-4xl"
          />
          <div className="md:grid-cols-3 xl:grid-cols-4 xl:gap-y-20 xl:gap-x-36 grid items-center justify-between w-full grid-cols-2 gap-12">
            <Img imgSrc="logo-ey.png" imgAlt="EY Logo" cssClass="w-[230px] h-[110px]" />
            <Img imgSrc="logo-flash.png" imgAlt="Flash Logo" cssClass="w-[230px] h-[110px]" />
            <Img imgSrc="logo-kia.png" imgAlt="Kia Logo" cssClass="w-[230px] h-[110px]" />
            <Img imgSrc="logo-absa.png" imgAlt="absa Logo" cssClass="w-[230px] h-[110px]" />
            <Img
              imgSrc="logo-motorhappy.png"
              imgAlt="MotorHappy Logo"
              cssClass="w-[230px] h-[110px]"
            />
            <Img imgSrc="logo-pep.png" imgAlt="PEP Logo" cssClass="w-[230px] h-[110px]" />
            <Img imgSrc="logo-nedbank.png" imgAlt="NedBank Logo" cssClass="w-[230px] h-[110px]" />
            <Img imgSrc="logo-mtn.png" imgAlt="MTN Logo" cssClass="w-[230px] h-[110px]" />
            <Img imgSrc="logo-fnb.png" imgAlt="FNB Logo" cssClass="w-[230px] h-[110px]" />
            <Img
              imgSrc="logo-coricraft.png"
              imgAlt="Coricraft Logo"
              cssClass="w-[230px] h-[110px]"
            />
            <Img
              imgSrc="logo-ackermans.png"
              imgAlt="Ackermans Logo"
              cssClass="w-[230px] h-[110px]"
            />
            <Img imgSrc="logo-investec.png" imgAlt="Investec Logo" cssClass="w-[230px] h-[110px]" />
          </div>
        </div>
      </section>
      <ImageContentBlock
        blockImage="software-dev.jpg"
        blockImageAlt="Software development with +OneX"
        imgLeft={true}
      >
        <div className="xl:px-32 px-8">
          <Headings
            headType={"h2"}
            titleText={"Software development with +OneX"}
            className={"xl:text-6xl text-5xl font-medium mb-10"}
          />

          <div>
            <p>
              We specialize in tailoring software to fuel your business growth. Our custom software
              development involves creating, refining, and deploying software applications that
              precisely align with your business needs.
            </p>
            <p>
              Backed by over a decade of engineering expertise, a team of 300+ skilled tech
              professionals, and a proven software development process, we're equipped to deliver
              custom software that caters to both your business goals and user expectations.
            </p>
            <p>
              As pioneers in software development, we understand that exceptional software emerges
              from a blend of creativity, technical proficiency, and user-centric design. Our
              mission is to empower clients to build enduring and scalable businesses. Let's shape
              your success story together.
            </p>
          </div>

          <a href="/find-talent">
            <Btn>Find out more</Btn>
          </a>
        </div>
      </ImageContentBlock>
      <FeaturesBlock
        featureTitle={"Our expert approach to software development"}
        buttonText={"Let’s Get Started"}
        buttonLinkUrl="/bespoke-software-development"
      >
        <ServiceRepeater dataList={featureData} />
      </FeaturesBlock>
      <section className="page-section bg-light">
        <div className="container">
          <Headings
            headType={"h2"}
            titleText={"Expert software across any industry"}
            className={"xl:text-6xl text-5xl font-medium mb-5"}
          />
          <p>
            Our team possesses a wealth of expertise in developing software across diverse
            industries.
          </p>
          <div className="xl:grid-cols-3 xl:mt-16 grid gap-20 mt-8">
            {softwareData.map((ele, i) => {
              return (
                <CardBox
                  key={i}
                  imgSrc={ele.imgSrc}
                  imgAlt={ele.title}
                  cardTitle={ele.title}
                  cardDesc={ele.desc}
                  buttonText={ele.buttonText}
                  buttonLinkUrl={ele.buttonLinkUrl}
                />
              );
            })}
          </div>
        </div>
      </section>
      <section className="page-section">
        <div className="container">
          <div className="xl:w-8/12">
            <Headings
              headType={"h2"}
              titleText={"Frequently asked questions "}
              className={"xl:text-6xl text-5xl font-medium mb-5"}
            />
            <p className="xl:w-11/12">
              We specialize in crafting personalized digital experiences that tangibly enhance your
              business's value proposition. With our guidance, you'll confidently navigate the path
              to your next phase of growth while simultaneously nurturing your team's skills and
              potential.
            </p>
          </div>
          <div className="mt-7 xl:gap-28 xl:flex-nowrap flex flex-wrap gap-8">
            <div className="xl:order-2 xl:w-1/2 xl:text-right xl:pl-8 w-full text-center">
              <Img imgSrc="faq-home.png" imgAlt="faq" />
            </div>
            <div className="xl:w-1/2 mt-7 w-full">
              <AccordionBox dataList={faqsData} />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Home;
