import { Accordion, AccordionHeader, AccordionBody } from "@material-tailwind/react";
import { useState } from "react";
import cx from "classnames";
import { Faqs } from "../../helpers/TypeDefinitions";
import IconPlus from "../Icons/Plus";
import IconMinus from "../Icons/Minus";
interface AccordionProps {
  dataList?: Faqs[];
}

function Icon({ id, open }) {
  return (
    <>
      {id === open ? (
        <div className="h-9 w-9 flex items-center justify-center text-white transition-all bg-black rounded-full">
          <IconMinus />
        </div>
      ) : (
        <div className="h-9 w-9 flex items-center justify-center text-white transition-all bg-black rounded-full">
          <IconPlus />
        </div>
      )}
    </>
  );
}
const AccordionBox = ({ dataList }: AccordionProps) => {
  const [open, setOpen] = useState(0);

  const handleOpen = (value) => setOpen(open === value ? 0 : value);

  return (
    <>
      {dataList && (
        <>
          {dataList.map((ele, i) => {
            return (
              <Accordion open={open === ele.id} icon={<Icon id={ele.id} open={open} />} key={i}>
                <AccordionHeader
                  onClick={() => handleOpen(ele.id)}
                  className={cx(open === ele.id && "font-bold border-b-white", "transition-all")}
                >
                  {ele.title}
                </AccordionHeader>
                <AccordionBody>
                  <div className="mb-11 text-base">{ele.desc}</div>
                </AccordionBody>
              </Accordion>
            );
          })}
        </>
      )}
    </>
  );
};

export default AccordionBox;
