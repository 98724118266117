import cx from "classnames";
import Img from "../Image";
import Headings from "../Headings";
import { Services } from "../../helpers/TypeDefinitions";

interface ServiceRepeaterProps {
  itemPerRow?: number;
  dataList?: Services[];
  iconTop?: boolean;
}

const ServiceRepeater = ({ itemPerRow = 2, dataList, iconTop = false }: ServiceRepeaterProps) => {
  return (
    <div className={cx("grid gap-x-10 gap-y-16", "xl:grid-cols-" + itemPerRow)}>
      {dataList && (
        <>
          {dataList.map((ele, i) => {
            return (
              <div key={i}>
                <div className={cx("flex gap-8", iconTop && "flex-col ")}>
                  {ele.imgSrc && (
                    <div
                      className={cx(
                        "flex-none  text-center",
                        iconTop ? "w-44 h-44 mx-auto mb-10" : "w-20 h-20"
                      )}
                    >
                      <Img imgSrc={ele.imgSrc} imgAlt={ele.title} />
                    </div>
                  )}
                  <div>
                    {ele.title && (
                      <Headings
                        headType="h3"
                        titleText={ele.title}
                        className="mb-4 text-xl font-bold"
                      />
                    )}

                    {ele.desc && <div dangerouslySetInnerHTML={{ __html: ele.desc }}></div>}
                  </div>
                </div>
              </div>
            );
          })}
        </>
      )}
    </div>
  );
};

export default ServiceRepeater;
