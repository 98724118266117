import axios from "axios";
import CONSTANT from "./constant";
export const handler = {
  GetNew,
};

function GetNew(URL) {
  const config = {
    headers: {
      Authorization: "Bearer " + CONSTANT.API_TOKEN,
    },
  };
  return axios
    .get(URL, config)
    .then((response) => {
      if (!response) {
        const error = response.statusText;
        return error;
      }
      return response;
    })
    .catch((error) => {
      if (error.message.indexOf("403") !== -1 || error.message.indexOf("401") !== -1) {
        return error;
      }
      return error;
    });
}
