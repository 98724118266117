import Headings from "../../components/Headings";
import HeroBanner from "../../components/HeroBanner";
import Img from "../../components/Image";
import FeaturesBlock from "../../components/FeaturesBlock";
import ImageContentBlock from "../../components/ImageContentBlock";
import ServiceRepeater from "../../components/ServiceRepeater";
import AccordionBox from "../../components/AccordionBox";
import Btn from "../../components/Btn";
const featureData = [
  {
    id: 1,
    imgSrc: "icon-design.svg",

    desc: "With +OneX Staff Augmentation service, you can scale your team according to your business needs.",
  },
  {
    id: 2,
    imgSrc: "icon-webapp.svg",

    desc: "You can test out the candidate before hiring them permanently - assess their skills, expertise and cultural fit within your team.",
  },
  {
    id: 3,
    imgSrc: "icon-mobileapp.svg",

    desc: "Our extensive network of leading African talent ensures that you have access to the best candidates in the market.",
  },
  {
    id: 4,
    imgSrc: "icon-ecommerce.svg",

    desc: "Ensure that you get the right candidate for the long-term, saving costs on the wrong hire.",
  },
  {
    id: 5,
    imgSrc: "icon-qa.svg",

    desc: "We also ensure that our candidates have a high NPS score, which guarantees their expertise and proficiency in their respective fields.",
  },
  {
    id: 6,
    imgSrc: "icon-devops.svg",

    desc: "Our on-demand service ensures your candidate will kick off 3x faster than hiring anywhere else.",
  },
];
const faqsData = [
  {
    id: 1,
    title: "Find talent fast ",
    desc: "We already have the perfect person in our pool of proven techies, saving you time and effort.",
  },
  {
    id: 2,
    title: "No recruitment fees ",
    desc: "The timeline for developing web applications can vary depending on the complexity of the project, the size of the development team, and other factors. We work closely with our clients to establish realistic timelines and milestones, and we communicate regularly throughout the development process to ensure that the project stays on track.",
  },
  {
    id: 3,
    title: "Flexible contracts ",
    desc: "The timeline for developing web applications can vary depending on the complexity of the project, the size of the development team, and other factors. We work closely with our clients to establish realistic timelines and milestones, and we communicate regularly throughout the development process to ensure that the project stays on track.",
  },
  {
    id: 4,
    title: "Vetted and tested ",
    desc: "The timeline for developing web applications can vary depending on the complexity of the project, the size of the development team, and other factors. We work closely with our clients to establish realistic timelines and milestones, and we communicate regularly throughout the development process to ensure that the project stays on track.",
  },
];
const ScaleYourTeam = () => {
  return (
    <div>
      <HeroBanner
        heroType="half"
        heroTitle={"Scale your team with none of the hassle"}
        heroDesc={
          "+OneX leases top-notch talent to companies, and takes care of everything else you don’t want to do - from contracts and onboarding, to tech roadmaps and project management."
        }
        buttonText={"Find out more"}
        buttonLinkUrl={"/find-talent"}
        heroImage={"scale-your-team-banner.jpg"}
        heroImageAlt={"Scale your team with none of the hassle"}
      />
      <section className="page-section">
        <div className="container text-center">
          <Headings
            headType="h2"
            titleText={"Onboard Talent in <br/> 3 Easy Steps."}
            className="xl:text-5xl xl:mb-20 mb-14 text-4xl"
          />
          <div className="xl:grid-cols-3 gap-28 grid">
            <div className="flex flex-col text-left">
              <Img
                imgSrc="icon-brief-us.svg"
                cssClass="h-44 w-44 mx-auto  mb-8"
                imgAlt="Brief Us"
              />
              <div className="mb-4 text-xl font-medium">
                <span className="text-red-600">01.</span> Brief Us
              </div>
              <p>Give us a tight brief, a bit of time and we’ll find matches in our vetted base.</p>
            </div>
            <div className="flex flex-col text-left">
              <Img
                imgSrc="icon-meet-talent.svg"
                cssClass="h-44 w-44 mx-auto  mb-8"
                imgAlt="Brief Us"
              />
              <div className="mb-4 text-xl font-medium">
                <span className="text-red-600">02.</span> Meet the talent
              </div>
              <p>Interview the candidates and let us know which one clicks.</p>
            </div>
            <div className="flex flex-col text-left">
              <Img
                imgSrc="icon-seal-deal.svg"
                cssClass="h-44 w-44 mx-auto  mb-8"
                imgAlt="Brief Us"
              />
              <div className="mb-4 text-xl font-medium">
                <span className="text-red-600">03.</span> Seal the Deal
              </div>
              <p>We finalise the contract, and your new team member is ready to get started.</p>
            </div>
          </div>
        </div>
      </section>
      <ImageContentBlock blockImage="why-us.jpg" imgLeft={true}>
        <div className="xl:px-32 px-8">
          <Headings
            headType={"h2"}
            titleText={"Why us ?"}
            className={"xl:text-6xl text-5xl font-medium mb-10"}
          />

          <div>
            <p>
              <strong>Our Commitment -</strong> At +OneX, we grasp the pivotal significance of
              selecting the perfect candidate for your business triumph. Hence, we present an
              unparalleled value proposition that guarantees the acquisition of prime talent for
              your team.
            </p>
            <p>
              <strong>Expertise in African Tech Talent -</strong> We extend to you the privilege of
              accessing the finest talent pool in Africa, streamlining your efforts, conserving your
              resources, and mitigating challenges.
            </p>
            <p>
              <strong>Assured Replacement -</strong> Should a candidate not align with your
              contentment, we pledge a seamless replacement process at zero cost. Your tranquility
              is ensured, freeing you from the clutches of an unsuitable hire.
            </p>
          </div>

          <a href="/find-talent">
            <Btn>Find talent</Btn>
          </a>
        </div>
      </ImageContentBlock>
      <FeaturesBlock
        featureTitle={"Hire tech consultants with +OneX"}
        buttonText={"Hire Talent"}
        buttonLinkUrl="/hire-developers"
      >
        <ServiceRepeater dataList={featureData} />
      </FeaturesBlock>
      <ImageContentBlock blockImage="trusted-client.jpg" imgLeft={true}>
        <div className="xl:px-32 px-8 text-center">
          <Headings
            headType="h2"
            titleText={"Trusted by over 300 clients"}
            className="xl:text-5xl xl:mb-20 mb-14 text-5xl"
          />
          <div className="md:grid-cols-3 xl:grid-cols-3 xl:gap-20 grid items-center justify-between w-full grid-cols-2 gap-12">
            <Img imgSrc="logo-ey.png" imgAlt="EY Logo" cssClass="w-[230px] h-[110px]" />
            <Img imgSrc="logo-flash.png" imgAlt="Flash Logo" cssClass="w-[230px] h-[110px]" />
            <Img imgSrc="logo-kia.png" imgAlt="Kia Logo" cssClass="w-[230px] h-[110px]" />
            <Img imgSrc="logo-absa.png" imgAlt="absa Logo" cssClass="w-[230px] h-[110px]" />
            <Img
              imgSrc="logo-motorhappy.png"
              imgAlt="MotorHappy Logo"
              cssClass="w-[230px] h-[110px]"
            />
            <Img imgSrc="logo-pep.png" imgAlt="PEP Logo" cssClass="w-[230px] h-[110px]" />
            <Img imgSrc="logo-nedbank.png" imgAlt="NedBank Logo" cssClass="w-[230px] h-[110px]" />
            <Img imgSrc="logo-mtn.png" imgAlt="MTN Logo" cssClass="w-[230px] h-[110px]" />
            <Img imgSrc="logo-fnb.png" imgAlt="FNB Logo" cssClass="w-[230px] h-[110px]" />
            <Img
              imgSrc="logo-coricraft.png"
              imgAlt="Coricraft Logo"
              cssClass="w-[230px] h-[110px]"
            />
            <Img
              imgSrc="logo-ackermans.png"
              imgAlt="Ackermans Logo"
              cssClass="w-[230px] h-[110px]"
            />
            <Img imgSrc="logo-investec.png" imgAlt="Investec Logo" cssClass="w-[230px] h-[110px]" />
          </div>
        </div>
      </ImageContentBlock>
      <section className="page-section">
        <div className="container">
          <div className="xl:w-8/12">
            <Headings
              headType={"h2"}
              titleText={"How it works"}
              className={"xl:text-6xl text-5xl font-medium mb-5"}
            />
            <p className="xl:w-11/12">
              We specialize in crafting personalized digital experiences that tangibly enhance your
              business's value proposition. With our guidance, you'll confidently navigate the path
              to your next phase of growth while simultaneously nurturing your team's skills and
              potential.
            </p>
          </div>
          <div className="mt-7 xl:gap-28 xl:flex-nowrap flex flex-wrap gap-8">
            <div className="xl:order-2 xl:w-1/2 xl:text-right xl:pl-8 w-full text-center">
              <Img imgSrc="how-it-works.png" imgAlt="faq" />
            </div>
            <div className="xl:w-1/2 mt-7 w-full">
              <AccordionBox dataList={faqsData} />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ScaleYourTeam;
