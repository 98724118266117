import { useEffect, useState } from "react";
import CONSTANT from "../../services/constant";
import { handler } from "../../services";
import { useParams } from "react-router";
import HeroBanner from "../../components/HeroBanner";
import "./caseStudyDetails.css";

const CaseStudyDetails = () => {
  const [caseStudyDetail, setCaseStudyDetail] = useState(null);
  const params = useParams();
  useEffect(() => {
    async function getStaticPaths() {
      const url = CONSTANT.BASE_URL + CONSTANT.GET_CASE_STUDY + "/?filter[slug]=" + params.id;
      const result = await handler.GetNew(url);
      console.log(result);
      let data = null;
      let error = "";
      if (result.message && (result.message.indexOf("403") || result.message.indexOf("401"))) {
        error = result.message;
        console.log(error);
        return {
          paths: [],
          fallback: true,
        };
      } else {
        data = JSON.parse(JSON.stringify(result.data.data));
        setCaseStudyDetail(data);
      }
    }
    getStaticPaths();
  }, [params.id]);
  // console.log(caseStudyDetail);
  return (
    <>
      {caseStudyDetail && caseStudyDetail.length > 0 && (
        <>
          {caseStudyDetail.map((ele, i) => {
            return (
              <div key={i}>
                <HeroBanner
                  heroType="casestudy"
                  heroTitle={ele.Banner_Title}
                  heroDesc={ele.Banner_Description}
                  heroImage={CONSTANT.IMAGE_BASE_PATH + ele.Banner_Image}
                  heroImageAlt={ele.Banner_Title}
                  heroImageBGColor={ele.Primary_Color}
                  textColor={ele.Font_Color}
                />
                {ele.Content_Area_Top && (
                  <section className="page-sub-section">
                    <div className="container">
                      <div
                        className="watermark-block watermarkBlock"
                        dangerouslySetInnerHTML={{
                          __html: ele.Content_Area_Top,
                        }}
                      ></div>
                    </div>
                  </section>
                )}
                <section className="page-section relative pt-0">
                  <div className="container">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: ele.Content_Area_Middle,
                      }}
                    ></div>
                    <div className="lg:my-12 clearfix my-4">&nbsp;</div>
                    <div
                      className="watermarkBlock watermark-block"
                      dangerouslySetInnerHTML={{
                        __html: ele.Content_Area_Bottom,
                      }}
                      style={{ color: "#ffffff" }}
                    ></div>
                  </div>
                  <div className="overlayWrapper">
                    <div className="row h-full gap-0">
                      <div
                        className="w-1/2"
                        style={{
                          backgroundColor: `${ele.Primary_Color ? ele.Primary_Color : "#e72662"}`,
                        }}
                      ></div>
                      <div
                        className="w-1/2"
                        style={{
                          backgroundColor: `${
                            ele.Secondary_Color ? ele.Secondary_Color : "#e72662"
                          }`,
                        }}
                      ></div>
                    </div>
                  </div>
                </section>
              </div>
            );
          })}
        </>
      )}
    </>
  );
};

export default CaseStudyDetails;
