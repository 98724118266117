const CONSTANT = {
  API_DATA_ALIVE_TIME_IN_SECONDS: 900,
  //BASE_URL: "https://c1b2pj35.directus.app",
  //IMAGE_BASE_PATH: "https://c1b2pj35.directus.app/assets/",
  BASE_URL: import.meta.env.VITE_API_BASE_URL,
  IMAGE_BASE_PATH: import.meta.env.VITE_IMAGE_BASE_PATH,
  FORM_SUBMITED_SUCCESS_MSG: "Thank you for your message, we will be in touch soon!",
  SITE_EMAIL_ADDRESS: import.meta.env.VITE_SITE_ADMIN_EMAIL,
  API_TOKEN: import.meta.env.VITE_DIRECTUS_TOKEN,
  ////////////////////////////////////////////////////////////////////////////////////

  /* Who We Are API*/
  GET_ALL_WHO_WE_ARE:
    "/items/CMS_Who_We_Are?fields[]=*&fields[]=Banner.*&fields[]=SEO_BLOCK.*&fields[]=Team_Members.Team_Member_id.*&fields[]=Team_Members.Team_Member_id.Skills.Team_Member_Skills_id.Skill_Name",
  /* Our Story API*/
  GET_OUR_STORY:
    "/items/CMS_Plusonex_Story?fields[]=*&fields[]=Banner.*&fields[]=SEO_BLOCK.*&fields[]=FORM.*",
  /* Our Global Services API*/
  GET_OUR_GLOBAL_SERVICES:
    "/items/CMS_Our_Global_Services?fields[]=*&fields[]=Banner.*&fields[]=SEO_BLOCK.*&fields[]=FORM.*",
  /* Home API*/
  GET_HOME_URL: "/items/CMS_HOME_Page?fields[]=*&fields[]=Banner.*&fields[]=SEO_BLOCK.*",
  /* Contact Us */
  GET_CONTACT_US:
    "/items/CMS_Contact_Us?fields[]=*&fields[]=Banner.*&fields[]=SEO_BLOCK.*&fields[]=FORM.*",
  /* Find Talent */
  GET_FIND_TALENT:
    "/items/CMS_Find_Talent?fields[]=*&fields[]=Banner.*&fields[]=SEO_BLOCK.*&fields[]=Team_Members.Team_Member_id.*&fields[]=Team_Members.Team_Member_id.Skills.Team_Member_Skills_id.Skill_Name&fields[]=FORM.*",
  /*Leasing Data API*/
  GET_ALL_LEASING:
    "/items/CMS_Leasing?fields[]=*&fields[]=Banner.*&fields[]=SEO_BLOCK.*&fields[]=FORM.*",
  /*Career Data API*/
  GET_ALL_CAREER:
    "/items/CMS_Careers?fields[]=*&fields[]=Banner.*&fields[]=SEO_BLOCK.*&fields[]=FORM.*",
  /*Blog Data API*/
  //GET_ALL_BLOG: "https://plusonex.com/wp-json/wp/v2/posts/?_embed",
  GET_ALL_BLOG: "https://blog.plusonex.com/wp-json/wp/v2/posts/?_embed",
  /*Blog Data API*/

  //GET_BLOG_ALL_LIST: "https://plusonex.com/wp-json/wp/v2/posts/?per_page=500",
  GET_BLOG_ALL_LIST: "https://blog.plusonex.com/wp-json/wp/v2/posts/?per_page=500",
  /*Blog Detail Data API*/
  //GET_BLOG_DETAIL: "https://plusonex.com/wp-json/wp/v2/posts/?_embed",
  GET_BLOG_DETAIL: "https://blog.plusonex.com/wp-json/wp/v2/posts/?_embed",
  /*Blog Detail Data API*/
  //GET_BLOG_CATEGORY: "https://plusonex.com/wp-json/wp/v2/categories/",
  GET_BLOG_CATEGORY: "https://blog.plusonex.com/wp-json/wp/v2/categories/",
  /*Service cloud Data API*/
  GET_SERVICE_CLOUD:
    "/items/Service_Cloud?fields[]=*&fields[]=Banner.*&fields[]=Client_List.Our_Clients_id.*&fields[]=Case_Study_Link.Case_Study_id.*&fields[]=SEO_BLOCK.*&fields[]=FORM.*",
  /*Service cloud Data API*/
  GET_SERVICE_EUC:
    "/items/Service_EUC?fields[]=*&fields[]=Banner.*&fields[]=EUC_Partner_List.Partner_EUC_id.*&fields[]=Partner_List.Partner_EUC_id.*&fields[]=SEO_BLOCK.*",
  /*Service Software Data API*/
  GET_SERVICE_SOFTWARE:
    "/items/Service_Software?fields[]=*&fields[]=Banner.*&fields[]=Team_Members.Team_Member_id.*&fields[]=Team_Members.Team_Member_id.Skills.Team_Member_Skills_id.Skill_Name&fields[]=Case_Study_Links.Case_Study_id.*&fields[]=Client_List.Our_Clients_id.*&fields[]=SEO_BLOCK.*&fields[]=FORM.*",
  /*Service Data AI API*/
  GET_SERVICE_DATA_AI:
    "/items/Service_Data_AI?fields[]=*&fields[]=Banner.*&fields[]=Client_List.Our_Clients_id.*&fields[]=SEO_BLOCK.*&fields[]=FORM.*",
  /*Security Services Data AI API*/
  GET_SECURITY_SERVICES:
    "/items/Service_Security?fields[]=*&fields[]=Banner.*&fields[]=List.Technology_Vendor_id.*&fields[]=Partner_List.Fulfilment_Partner_id.*&fields[]=SEO_BLOCK.*&fields[]=FORM.*",
  /*Unified Communications Data API*/
  GET_UNIFIED_COMMUNICATIONS:
    "/items/Service_Communication?fields[]=*&fields[]=Banner.*&fields[]=SEO_BLOCK.*&fields[]=FORM.*",
  /*Mangaged Private Cloud Data API*/
  MANAGED_PRIVATE_CLOUD:
    "/items/Service_Private_Cloud?fields[]=*&fields[]=Banner.*&fields[]=SEO_BLOCK.*&fields[]=FORM.*",
  /*Digital Media Services Data API*/
  GET_DIGITAL_MEDIA_SERVICES:
    "/items/Service_Digital_Media?fields[]=*&fields[]=Banner.*&fields[]=SEO_BLOCK.*&fields[]=FORM.*",
  /*Case Study Data API*/
  GET_CASE_STUDY: "/items/Case_Study",
  /*PPC Data API*/
  GET_PPC_PAGES: "/items/PPC_Pages?fields[]=*&fields[]=SEO_BLOCK.*&fields[]=FORM.*",
  /*Services PPC Data API*/
  GET_SERVICES_PPC_PAGES: "/items/Services_PPC?fields[]=*&fields[]=SEO_BLOCK.*&fields[]=FORM.*",
  /*Team member skills API*/
  GET_TEAM_MEMBER_SKILLS: "/items/Team_Member_Skills?sort=Skill_Name",
  /*Team Members List Data API*/
  //GET_TEAM_MEMBERS: "/items/Team_Member?fields[]=*&fields[]=Skills.Team_Member_Skills_id.Skill_Name&filter[Profile_Type][_eq]=Management",
  GET_TEAM_MEMBERS:
    "/items/Team_Member?fields[]=*&fields[]=Skills.Team_Member_Skills_id.Skill_Name",
  /*Mentorship Program Data API*/
  GET_MENTORSHIP_PROGRAM:
    "/items/CMS_Mentorship_Programme?fields[]=*&fields[]=Banner.*&fields[]=SEO_BLOCK.*&fields[]=FORM.*",
  /*Optimisation Data API*/
  GET_OPTIMISATION:
    "/items/Service_Optimisation?fields[]=*&fields[]=Banner.*&fields[]=Our_Client.Our_Clients_id.*&fields[]=Optimisation_Partner.Partners_Optimization_id.*&fields[]=SEO_BLOCK.*&fields[]=FORM.*",
  /*Optimisation Data API*/
  GET_COMMON_PAGES: "/items/Common_Pages",
  /*Contact Form API*/
  POST_FORM_CONTACT: "/items/Contact_Form",
  /*Services Form API*/
  POST_FORM_SERVICES: "/items/Services_Form",
  /*Apply Now Form API*/
  POST_FORM_APPLY_NOW: "/items/Apply_Now_Form",
  /*Private Cloud Services Form API*/
  POST_FORM_PRIVATE_CLOUD: "/items/Private_Cloud_Form",
  /*Footer menu API*/
  GET_FOOTER_MAIN_MENU: "/items/Footer_Menu/?fields[]=*&sort=Display_Order",
  /*Header menu API*/
  GET_HEADER_MAIN_MENU: "/items/Header_Menu/?fields[]=*&sort=Display_Order",
  /*Footer API*/
  GET_FOOTER_AREA: "/items/Bottom_Menu",
  /*MANAGED SERVICE PAGE*/
  GET_MANAGED_SERVICE:
    "/items/Service_Managed_Services?fields[]=*&fields[]=Banner.*&fields[]=SEO_BLOCK.*",
};

export default CONSTANT;
