import {
  Navbar,
  Collapse,
  Typography,
  IconButton,
  List,
  ListItem,
  Menu,
  MenuHandler,
  MenuList,
  Dialog,
  DialogBody,
} from "@material-tailwind/react";
import { ChevronDownIcon, Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import Img from "../Image";
import { Fragment, useEffect, useState } from "react";
import IconAgileTeams from "../Icons/AgileTeams";
import IconSolutions from "../Icons/Solutions";
import useResponsive from "../../helpers/useResponsive";
import CONSTANT from "../../services/constant";
import { handler } from "../../services";
import Btn from "../Btn";

import ContactModal from "../../pages/ContactModal";

interface HeaderProps {
  landingHeader?: boolean;
}

const headerServicesLinks = [
  {
    id: 1,
    heading: "Agile teams",
    slug: null,
    children: [
      {
        id: 1,
        title: "Agile teams for hire",
        slug: "/scale-your-team",
      },
      {
        id: 2,
        title: "Find talent",
        slug: "/find-talent",
      },
    ],
  },

  {
    id: 2,
    heading: "Solutions",
    slug: "/build",
    children: [
      {
        id: 1,
        title: "Mobile Apps",
        slug: "/mobile-app-development",
      },
      {
        id: 2,
        title: "Web Apps",
        slug: "/web-app-development",
      },
      {
        id: 3,
        title: "Product Design (Ux, Ui)",
        slug: "/product-design",
      },
      {
        id: 4,
        title: "eCommerce",
        slug: "/e-commerce",
      },
      {
        id: 5,
        title: "Bespoke Software development",
        slug: "/bespoke-software-development",
      },
      {
        id: 6,
        title: "Software support",
        slug: "/software-support",
      },
      {
        id: 7,
        title: "Qaulity Assurance",
        slug: "/quality-assurance",
      },
      {
        id: 8,
        title: "Cloud Development",
        slug: "/cloud-software-development",
      },
    ],
  },
];

const NavServiceListMenu = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  return (
    <>
      <Menu
        open={isMenuOpen}
        handler={setIsMenuOpen}
        offset={{ mainAxis: 40 }}
        placement="bottom"
        allowHover={false}
      >
        <MenuHandler>
          <Typography as="div" variant="small" className="font-normal text-black">
            <ListItem
              className="flex items-center gap-2 py-2 pr-4"
              selected={isMenuOpen || isMobileMenuOpen}
              onClick={() => setIsMobileMenuOpen((cur) => !cur)}
            >
              Services
              <ChevronDownIcon
                strokeWidth={2.5}
                className={`hidden h-3 w-3 transition-transform xl:block ${
                  isMenuOpen ? "rotate-180" : ""
                }`}
              />
              <ChevronDownIcon
                strokeWidth={2.5}
                className={`block h-3 w-3 transition-transform xl:hidden ${
                  isMobileMenuOpen ? "rotate-180" : ""
                }`}
              />
            </ListItem>
          </Typography>
        </MenuHandler>
        <MenuList className="rounded-xl xl:flex xl:gap-28 py-14 hidden w-full max-w-screen-xl gap-3 px-24 overflow-visible">
          <div className="hover:ring-0 focus:ring-0 active:ring-0 hover:outline-0 focus:outline-0 active:outline-0 flex gap-4">
            <IconAgileTeams className="h-7 w-7 flex-none" />
            <div>
              {headerServicesLinks[0].slug ? (
                <a href={headerServicesLinks[0].slug}>
                  <Typography
                    variant="h2"
                    color="black"
                    className="mb-7 whitespace-nowrap leading-none"
                  >
                    {headerServicesLinks[0].heading}
                  </Typography>
                </a>
              ) : (
                <Typography
                  variant="h2"
                  color="black"
                  className="mb-7 whitespace-nowrap leading-none"
                >
                  {headerServicesLinks[0].heading}
                </Typography>
              )}

              {headerServicesLinks[0].children.length > 0 && (
                <ul className="mb-6">
                  {headerServicesLinks[0].children.map((child, i) => {
                    return (
                      <li key={i}>
                        <Typography
                          as="a"
                          href={child.slug}
                          color="black"
                          className="font-normal leading-8"
                        >
                          {child.title}
                        </Typography>
                      </li>
                    );
                  })}
                </ul>
              )}
            </div>
          </div>
          <div className="hover:ring-0 focus:ring-0 active:ring-0 hover:outline-0 focus:outline-0 flex gap-4">
            <IconSolutions className="h-7 w-7 flex-none" />
            <div>
              {headerServicesLinks[1].slug ? (
                <a href={headerServicesLinks[1].slug}>
                  <Typography
                    variant="h2"
                    color="black"
                    className="mb-7 whitespace-nowrap leading-none"
                  >
                    {headerServicesLinks[1].heading}
                  </Typography>
                </a>
              ) : (
                <Typography
                  variant="h2"
                  color="black"
                  className="mb-7 whitespace-nowrap leading-none"
                >
                  {headerServicesLinks[1].heading}
                </Typography>
              )}

              {headerServicesLinks[1].children.length > 0 && (
                <ul className="xl:columns-2 mb-6">
                  {headerServicesLinks[1].children.map((child, i) => {
                    return (
                      <li key={i}>
                        <Typography
                          as="a"
                          href={child.slug}
                          color="black"
                          className="font-normal leading-8"
                        >
                          {child.title}
                        </Typography>
                      </li>
                    );
                  })}
                </ul>
              )}
            </div>
          </div>
        </MenuList>
      </Menu>
      <div className="xl:hidden block">
        <Collapse open={isMobileMenuOpen}>
          <div className="hover:ring-0 focus:ring-0 xl:flex-row flex gap-4 ml-4">
            <IconAgileTeams className="h-7 w-7 flex-none" />
            <div>
              <Typography
                variant="h2"
                color="black"
                className="mb-7 whitespace-nowrap leading-none"
              >
                {headerServicesLinks[0].heading}
              </Typography>
              {headerServicesLinks[0].children.length > 0 && (
                <ul className="mb-6">
                  {headerServicesLinks[0].children.map((child, i) => {
                    return (
                      <li key={i}>
                        <Typography
                          as="a"
                          href={child.slug}
                          color="black"
                          className="font-normal leading-8"
                        >
                          {child.title}
                        </Typography>
                      </li>
                    );
                  })}
                </ul>
              )}
            </div>
          </div>
          <div className="hover:ring-0 focus:ring-0 flex gap-4 ml-4">
            <IconSolutions className="h-7 w-7 flex-none" />
            <div>
              <Typography
                variant="h2"
                color="black"
                className="mb-7 whitespace-nowrap leading-none"
              >
                {headerServicesLinks[1].heading}
              </Typography>
              {headerServicesLinks[1].children.length > 0 && (
                <ul className="xl:columns-2 mb-6">
                  {headerServicesLinks[1].children.map((child, i) => {
                    return (
                      <li key={i}>
                        <Typography
                          as="a"
                          href={child.slug}
                          color="black"
                          className="font-normal leading-8"
                        >
                          {child.title}
                        </Typography>
                      </li>
                    );
                  })}
                </ul>
              )}
            </div>
          </div>
        </Collapse>
      </div>
    </>
  );
};

const NavCaseStudyListMenu = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [caseStudyData, setCaseStudyData] = useState(null);
  useEffect(() => {
    async function getCaseStudy() {
      const url = CONSTANT.BASE_URL + CONSTANT.GET_CASE_STUDY;
      const result = await handler.GetNew(url);
      let data = null;
      let error = "";
      if (result.message && (result.message.indexOf("403") || result.message.indexOf("401"))) {
        error = result.message;
        console.log(error);
      } else {
        data = JSON.parse(JSON.stringify(result.data.data));
        setCaseStudyData(data);
      }
      return {
        props: {
          error: error || null,
          data: data || null,
        },
      };
    }
    getCaseStudy();
  }, []);
  // console.log(caseStudyData);
  return (
    <>
      <Menu
        open={isMenuOpen}
        handler={setIsMenuOpen}
        offset={{ mainAxis: 40 }}
        placement="bottom"
        allowHover={false}
      >
        <MenuHandler>
          <Typography as="div" variant="small" className="font-normal text-black">
            <ListItem
              className="flex items-center gap-2 py-2 pr-4"
              selected={isMenuOpen || isMobileMenuOpen}
              onClick={() => setIsMobileMenuOpen((cur) => !cur)}
            >
              Case studies
              <ChevronDownIcon
                strokeWidth={2.5}
                className={`hidden h-3 w-3 transition-transform xl:block ${
                  isMenuOpen ? "rotate-180" : ""
                }`}
              />
              <ChevronDownIcon
                strokeWidth={2.5}
                className={`block h-3 w-3 transition-transform xl:hidden ${
                  isMobileMenuOpen ? "rotate-180" : ""
                }`}
              />
            </ListItem>
          </Typography>
        </MenuHandler>
        <MenuList className="hidden w-[36rem]  xl:flex  overflow-visible p-14">
          <div className="hover:ring-0 focus:ring-0 active:ring-0 hover:outline-0 focus:outline-0 xl:flex w-full gap-3">
            {caseStudyData && (
              <ul className="hover:ring-0 focus:ring-0 active:ring-0 hover:outline-0 focus:outline-0 xl:grid-cols-2 grid justify-between w-full grid-cols-1 gap-4">
                {caseStudyData.map((child, i) => {
                  return (
                    <Fragment key={i}>
                      {child.Microsite_Visible == true && (
                        <li key={i} className="flex items-center gap-2">
                          <div className="h-7 w-7 flex items-center justify-center p-1 bg-gray-100 rounded-full">
                            <Img
                              imgSrc={CONSTANT.IMAGE_BASE_PATH + child.Icon}
                              imgAlt={child.Case_Study_Of + "icon"}
                            />
                          </div>
                          <Typography
                            as="a"
                            href={child.URL}
                            color="black"
                            className="font-normal leading-8"
                          >
                            {child.Case_Study_Of}
                          </Typography>
                        </li>
                      )}
                    </Fragment>
                  );
                })}
                <li>
                  <Typography
                    as="a"
                    href={"/case-studies/"}
                    color="black"
                    className="font-normal leading-8"
                  >
                    view all ...
                  </Typography>
                </li>
              </ul>
            )}
          </div>
        </MenuList>
      </Menu>
      <div className="xl:hidden block">
        <Collapse open={isMobileMenuOpen}>
          <div className="hover:ring-0 focus:ring-0 flex gap-4">
            <div>
              {caseStudyData && (
                <ul className="grid justify-between w-full grid-cols-1 gap-4 ml-4">
                  {caseStudyData.map((child, i) => {
                    return (
                      <Fragment key={i}>
                        {child.Microsite_Visible == true && (
                          <li key={i} className="flex items-center gap-2">
                            <div className="h-7 w-7 flex items-center justify-center p-1 bg-gray-100 rounded-full">
                              <Img
                                imgSrc={CONSTANT.IMAGE_BASE_PATH + child.Icon}
                                imgAlt={child.Case_Study_Of + "icon"}
                              />
                            </div>
                            <Typography
                              as="a"
                              href={child.URL}
                              color="black"
                              className="font-normal leading-8"
                            >
                              {child.Case_Study_Of}
                            </Typography>
                          </li>
                        )}
                      </Fragment>
                    );
                  })}
                  <li>
                    <Typography
                      as="a"
                      href={"/case-studies/"}
                      color="black"
                      className="font-normal leading-8"
                    >
                      view all ...
                    </Typography>
                  </li>
                </ul>
              )}
            </div>
          </div>
        </Collapse>
      </div>
    </>
  );
};
const NavList = () => {
  return (
    <List className="xl:mt-0 xl:mb-0 xl:flex-row xl:p-1 p-0 mt-12 mb-6">
      <Typography as="a" href="/" variant="small" color="black" className="font-normal">
        <ListItem className="flex py-2 pr-4">Home</ListItem>
      </Typography>
      <NavServiceListMenu />
      <NavCaseStudyListMenu />
      <Typography as="a" href="/company" variant="small" color="black" className="font-normal">
        <ListItem className="flex py-2 pr-4">Company</ListItem>
      </Typography>
      <Typography as="a" href="/join-us" variant="small" color="black" className="font-normal">
        <ListItem className="flex py-2 pr-4">Careers</ListItem>
      </Typography>
    </List>
  );
};

const Header = ({ landingHeader }: HeaderProps) => {
  const [openNav, setOpenNav] = useState(false);
  const { isTablet } = useResponsive();
  const landingHeaderState = landingHeader;
  const [openModal, setOpenModal] = useState(false);
  const handleOpenModal = () => {
    setOpenModal(!openModal);
  };
  useEffect(() => {
    window.addEventListener("resize", () => isTablet && setOpenNav(false));
  }, [isTablet]);
  // console.log(landingHeaderState);
  return (
    <>
      <Navbar className="h-max sticky top-0 z-10 max-w-full rounded-none shadow-none">
        {landingHeaderState == false ? (
          <div className="container mx-auto">
            <div className="text-blue-gray-900 flex items-center justify-between">
              <a href="/">
                <Img
                  cssClass="mr-3 h-[88px] w-[88px] -mb-10 relative z-30 rounded-full"
                  imgSrc="logo.svg"
                  imgAlt="+OneX"
                />
              </a>
              <div className="xl:block xl:ml-auto hidden">
                <NavList />
              </div>
              <div className="xl:flex hidden gap-2">
                <Btn onClick={handleOpenModal} className="min-w-fit py-3 mt-0" size="sm">
                  Contact us
                </Btn>
              </div>
              <IconButton
                variant="filled"
                className="xl:hidden"
                onClick={() => setOpenNav(!openNav)}
              >
                <span className="sr-only">Menu</span>
                {openNav ? (
                  <XMarkIcon className="w-6 h-6" strokeWidth={2} />
                ) : (
                  <Bars3Icon className="w-6 h-6" strokeWidth={2} />
                )}
              </IconButton>
            </div>
            <Collapse open={openNav} className="overflow-y-auto max-h-[calc(100vh-92px)] ">
              <NavList />
              <div className="flex-nowrap xl:hidden flex items-center w-full gap-2">
                <Btn
                  onClick={handleOpenModal}
                  className="min-w-fit py-3 mx-2 mt-0"
                  size="sm"
                  fullWidth
                >
                  Contact us
                </Btn>
              </div>
            </Collapse>
          </div>
        ) : (
          <div className="container mx-auto">
            <div className="text-blue-gray-900 flex items-center justify-between">
              <a href="/">
                <Img
                  cssClass="mr-3 h-[88px]  w-[88px] -mb-10 relative z-30 rounded-full"
                  imgSrc="logo.svg"
                  imgAlt="+OneX"
                />
              </a>

              <div className="flex gap-2">
                <a href="/join-us">
                  <Btn className=" py-3 mt-0" size="sm">
                    Contact us
                  </Btn>
                </a>
              </div>
            </div>
          </div>
        )}
      </Navbar>
      <Dialog open={openModal} handler={handleOpenModal} size="xl">
        <DialogBody className="max-h-[94vh] p-0 overflow-y-auto">
          <ContactModal />
        </DialogBody>
      </Dialog>
    </>
  );
};

export default Header;
