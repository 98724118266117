import cx from "classnames";

interface IconMinusProps {
  className?: string;
}

export default function IconMinus({ className = "w-6 h-6" }: IconMinusProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth="1.5"
      stroke="currentColor"
      className={cx("fill-inherit transition-all", className)}
    >
      <path strokeLinecap="round" strokeLinejoin="round" d="M18 12H6" />
    </svg>
  );
}
