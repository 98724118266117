import { useLayoutEffect, useRef, useState } from "react";

interface ImageProps {
  cssClass?: string;
  imgSrc?: string;
  imgAlt?: string;
  height?: string;
  width?: string;
}

const Img = ({ imgSrc, imgAlt, cssClass }: ImageProps) => {
  const [imgWidth, setImgWidth] = useState(0);
  const [imgHeight, setImgHeight] = useState(0);
  const elementRef = useRef(null);

  let theSrc = imgSrc;
  useLayoutEffect(() => {
    setImgWidth(elementRef.current.offsetWidth);
    setImgHeight(elementRef.current.offsetHeight);
  }, []);
  if (imgSrc && imgSrc.indexOf(import.meta.env.VITE_API_BASE_DOMAIN) != -1) {
    if (imgSrc.indexOf("?") == -1) {
      theSrc = theSrc + "?quality=90&format=webp&q=100";
    } else {
      theSrc = theSrc + "&quality=90&format=webp&q=100";
    }
  } else if (imgSrc) {
    const firstChar = imgSrc.charAt(0);
    if (firstChar != "/") {
      theSrc =
        "https://ik.imagekit.io/0msnzkm3a/microsite/tr:pr-true,f-webp,w-" +
        imgWidth +
        ",h-" +
        imgHeight +
        "/" +
        imgSrc;
    }
  }

  return (
    <img
      ref={elementRef}
      src={theSrc}
      alt={imgAlt}
      className={cssClass}
      height={imgHeight ? imgHeight : 1024}
      width={imgWidth ? imgWidth : 1024}
    />
  );
};

export default Img;
