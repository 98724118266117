import cx from "classnames";

interface IconSolutionsProps {
  className?: string;
}

export default function IconSolutions({ className = "w-6 h-6" }: IconSolutionsProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      className={cx("fill-inherit transition-all", className)}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.4,19.6l-1.5-2l4.2-5.5l5.7,7.4H12.4z M3.2,19.6l3.4-4.4l3.4,4.4H3.2z M17.8,6.1l0.5,0.7H16V4.3h2.3L17.8,5
        C17.6,5.4,17.6,5.8,17.8,6.1L17.8,6.1z M23.9,20.4L16,10.1V8.6h4c0.5,0,0.9-0.4,0.9-0.9c0-0.2-0.1-0.4-0.2-0.5l-1.1-1.7l1.1-1.7
        c0.3-0.4,0.2-1-0.3-1.3c-0.1-0.1-0.3-0.2-0.5-0.2h-4.9c-0.5,0-0.9,0.4-0.9,0.9v6.8L9.7,16l-2.6-3.3c-0.3-0.3-0.8-0.3-1.1,0l-5.9,7.7
        c-0.3,0.4,0,1.1,0.5,1.1h11.5h1.7h9.5C23.9,21.5,24.2,20.9,23.9,20.4L23.9,20.4z"
      />
    </svg>
  );
}
