import cx from "classnames";
import Img from "../Image";
import Headings from "../Headings";

import Btn from "../Btn";
interface HeroBannerCaseStudyProps {
  cssClass?: string;
  heroTitle?: string;
  heroDesc?: string;
  buttonText?: string;
  buttonLinkUrl?: string;
  heroImage?: string;
  heroImageAlt?: string;
  heroImageBGColor?: string;
  textColor?: string;
}

const HeroBannerCaseStudy = ({
  cssClass,
  heroTitle,
  heroDesc,
  buttonText,
  buttonLinkUrl,
  heroImage,
  heroImageAlt,
  heroImageBGColor,
  textColor,
}: HeroBannerCaseStudyProps) => {
  return (
    <div
      className={cx("flex xl:flex-row flex-col relative", cssClass && cssClass)}
      style={{
        backgroundColor: heroImageBGColor,
      }}
    >
      <div className="bg-white absolute w-full h-40 bottom-0 left-0"></div>
      <div
        className="xl:w-1/2 xl:pt-40 py-14 xl:pb-80 flex items-center my-auto"
        style={{ color: textColor }}
      >
        <div className="max-w-2xl xl:ml-auto mx-10">
          {heroTitle && (
            <Headings
              headType={"h1"}
              titleText={heroTitle}
              className={"xl:text-6xl text-5xl font-medium mb-10"}
            />
          )}
          {heroDesc && <div>{heroDesc}</div>}
          {buttonText && (
            <a href={buttonLinkUrl}>
              <Btn>{buttonText}</Btn>
            </a>
          )}
        </div>
      </div>
      <div className="xl:w-1/2">
        {heroImage && (
          <Img imgSrc={heroImage} imgAlt={heroImageAlt} cssClass="h-full w-full relative z-1" />
        )}
      </div>
    </div>
  );
};

export default HeroBannerCaseStudy;
