import Headings from "../../components/Headings";
import HeroBanner from "../../components/HeroBanner";
import ImageContentBlock from "../../components/ImageContentBlock";
import Btn from "../../components/Btn";

const Company = () => {
  return (
    <div>
      <HeroBanner
        heroType="card"
        heroTitle={"Connecting people and technology to achieve"}
        heroDesc={
          "We help companies tackle their most ambitious projects and build new capabilities."
        }
        buttonText={"Visit Group website"}
        buttonLinkUrl={"https://www.plusonex.com/"}
        heroImage={"company-banner.jpg"}
        heroImageAlt={"Connecting people and technology to achieve"}
        heroImageBGColor={"bg-[#FFF4DF]"}
      >
        <div className="text-center">
          <Headings
            headType="h2"
            titleText="Our Story"
            className={"xl:text-6xl text-5xl font-medium mb-7"}
          />
          <div className="xl:text-3xl mb-12">
            We offer end-to-end custom development, uniquely tailored to your industry and growing
            business
          </div>
          <div>
            We started as two guys in an apartment back in 2005, since then we’ve grown to a team of
            100 Code Mavens. Our people are all over the world, building quality products for great
            brands. We pride ourselves on being some of the OGs in the tech hiring space. Experience
            goes a long way in our industry.
          </div>
        </div>
      </HeroBanner>
      <ImageContentBlock cssClass="bg-[#FFF4DF]" blockImage="clients.jpg" imgLeft={true}>
        <div className="xl:px-32 px-8">
          <Headings
            headType="h3"
            titleText="300+"
            className={"xl:text-6xl text-5xl font-bold mb-0"}
          />
          <div className="xl:text-3xl mb-28">Clients around the world</div>
          <Headings
            headType="h3"
            titleText="2,500+"
            className={"xl:text-6xl text-5xl font-bold mb-0"}
          />
          <div className="xl:text-3xl mb-28">Projects delivered so far</div>
          <Headings
            headType="h3"
            titleText="200+"
            className={"xl:text-6xl text-5xl font-bold mb-0"}
          />
          <div className="xl:text-3xl mb-28">Tech Experts</div>
        </div>
      </ImageContentBlock>
      <section className="page-section">
        <div className="container">
          <Headings
            headType={"h2"}
            titleText={"Core elements"}
            className={"xl:text-6xl text-5xl font-medium mb-5"}
          />
          <div className="xl:text-3xl xl:mb-16 mb-12">
            Our core values are an active part of everything we do. Each one reminds us to stay true
            to ourselves while driving amazing outcomes for our clients.
          </div>
          <div className="xl:grid-cols-2 xl:gap-x-28 grid grid-cols-1">
            <div>
              <Headings
                headType={"h3"}
                titleText={"Leading without titles"}
                className={"xl:text-3xl text-2xl font-medium mb-4"}
              />
              <div className="mb-8">
                Leadership throughout the organisation. We challenge the status quo and speak up for
                what we care about and believe in. We constantly invest in our people, empowering
                them to be at the forefront of decision making.
              </div>
              <Headings
                headType={"h3"}
                titleText={"Client Obsession"}
                className={"xl:text-3xl text-2xl font-medium mb-4"}
              />
              <div className="mb-8">
                Everything we do is centered around delivering an unbelievable experience for our
                clients. We actively listen to clients' needs and collaborate to enhance their
                experience and exceed expectations.
              </div>
            </div>
            <div>
              <Headings
                headType={"h3"}
                titleText={"Trust"}
                className={"xl:text-3xl text-2xl font-medium mb-4"}
              />
              <div className="mb-8">
                Trust is the foundation on which we build long-term relationships with people. To be
                trusted is the greatest compliment that one can receive.
              </div>
              <Headings
                headType={"h3"}
                titleText={"Humility"}
                className={"xl:text-3xl text-2xl font-medium mb-4"}
              />
              <div className="mb-8">
                We love what we do and are purpose driven. In our organization there is no room for
                ego's or arrogance. Instead of who is right, we focus on what is right.
              </div>
              <Headings
                headType={"h3"}
                titleText={"Discovering"}
                className={"xl:text-3xl text-2xl font-medium mb-4"}
              />
              <div className="mb-8">
                We embrace disruption and learn fast to deliver positive change. Our people remain
                curious and explore new opportunities to grow with our clients.
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container">
          <iframe
            className="aspect-video w-full"
            src="https://www.youtube.com/embed/ZEfRKJYozxA?si=YLXf1eBnRj1mfSKY"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          ></iframe>
        </div>
      </section>
      <section className="page-section">
        <div className="container">
          <div className="xl:gap-16 xl:flex-nowrap flex flex-wrap gap-12">
            <div className="xl:w-6/12 w-full">
              <Headings
                headType={"h2"}
                titleText={"The X-Factor"}
                className={"xl:text-6xl text-4xl font-bold mb-4"}
              />

              <div className="xl:text-2xl text-xl text-gray-500">
                +OneX teams are driven to do what's right and help you realize your vision. We
                believe in generating success collaboratively, leaving your organization stronger
                after every engagement, and building trust for the next big challenge.
              </div>

              <a href="https://www.plusonex.com/">
                <Btn>Visit group website</Btn>
              </a>
            </div>
            <div className="xl:w-6/12 w-full">
              <Headings
                headType={"h3"}
                titleText={"End-to-end business transformation partner"}
                className={"xl:text-3xl text-2xl mb-7"}
              />
              <div className="mb-8">
                We design with you from start to end. Tell us what your vision is and we will work
                out the finer details with you. As projects progress we are there to offer technical
                expertise and strategy every step of the way.
              </div>
              <Headings
                headType={"h3"}
                titleText={"Agility of a start-up with the scale of an enterprise"}
                className={"xl:text-3xl text-2xl mb-7"}
              />
              <div className="mb-8">
                We know things need to move quickly so we move fast, with agile teams that respond
                to technical and business challenges as they arise and can pivot easily with your
                changing business demands.
              </div>
              <Headings
                headType={"h3"}
                titleText={"Built on the Cloud"}
                className={"xl:text-3xl text-2xl mb-7"}
              />
              <div className="mb-8">
                We leverage the exceptional capabilities of the cloud to the benefit of our clients.
                We don't see cloud as a product, but as a business-model. One that you can use to
                give your company the competitive edge.
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Company;
