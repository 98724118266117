import cx from "classnames";

import Img from "../Image";
interface ImageContentBlockProps {
  cssClass?: string;
  children?: React.ReactNode;
  blockImage?: string;
  blockImageAlt?: string;
  imgLeft?: boolean;
}

const ImageContentBlock = ({
  cssClass,
  children,
  blockImage,
  blockImageAlt,
  imgLeft = true,
}: ImageContentBlockProps) => {
  return (
    <div className={cx("bg-light flex xl:flex-row flex-col", cssClass && cssClass)}>
      <div className={cx("xl:w-1/2 ", imgLeft ? "xl:order-first" : "xl:order-2")}>
        {blockImage && (
          <Img
            imgSrc={blockImage}
            imgAlt={blockImageAlt}
            cssClass="object-cover object-center h-full w-full"
          />
        )}
      </div>

      <div className="xl:w-1/2 xl:py-40 py-14 flex items-center my-auto">
        <div>{children && children}</div>
      </div>
    </div>
  );
};

export default ImageContentBlock;
